/**
 * Created by matheusmorett on 23/05/18.
 */
import {observable, action} from 'mobx';
import Api from './../utils/Api';

class HighlightStore {

    @observable loading = false;
    @observable highlight = [];

    @action
    getHighlight(params) {
        this.loading = true;
        return Api.call('/highlight/get-highlight', 'get', params).then((response) => {
            this.loading = false;
            this.highlight = response.data.data;
        });
    }

}

export default new HighlightStore();