import React, { Component } from 'react'

import facebookLogo from './../../assets/img/icons/facebook.svg';
import instagramLogo from './../../assets/img/icons/instagram.svg';
import linkedinLogo from './../../assets/img/icons/linkedin.svg';

import "./ContactLinks.scss";

export default class ContactLinks extends Component {
  render() {
    return (
        <div className="ContactLinks">
            {/* <div>
                <a href="https://web.whatsapp.com/send?phone=5541995778886" target="_blank">
                    <i className="fa fa-whatsapp"/>
                </a>
            </div> */}
            <div>
                <a href="https://www.facebook.com/adrena.me/" target="_blank">
                    <img className="facebook" src={facebookLogo} alt="Facebook"/>
                </a>
            </div>
            <div>
                <a href="https://www.instagram.com/adrena.me/" target="_blank">
                    <img className="instagram" src={instagramLogo} alt="Instagram"/>
                </a>
            </div>
            <div>
                <a href="https://www.linkedin.com/company/adrenalyze/" target="_blank">
                    <img className="linkedin" src={linkedinLogo} alt="Linkedin"/>
                </a>
            </div>
        </div>
    )
  }
}
