/**
 * Created by matheusmorett on 16/08/17.
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { slide as SideMenu } from 'react-burger-menu'
import { inject, observer } from 'mobx-react';
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';
import Utils from './../../utils/Utils';

import './Menu.scss';

import logo from './../../assets/img/adrenalyze/adrena-me.svg';
import logo2 from './../../assets/img/adrenalyze/adrena-me.svg';
// import adrena from './../../assets/img/adrenalyze/adrena.png';
import imgTeste from './../../assets/img/Icon.jpg';
import ContactLinks from '../ContactLinks';
import ApplicationStore from '../../stores/ApplicationStore';
import MainSearch from '../MainSearch/MainSearch';

@inject('AuthStore', 'ApplicationStore', 'CompanysStore', 'AdventuresStore')
@observer
class Menu extends Component {

    static defaultProps = {
        openModal: () => {}

    };

    constructor(props) {
        super(props);

        this.state = {
            menuOpen: false,
            menuOpenMobile: false,
            company_logo: '',
            company_url: '',
            company_name: '',
        };

        this.handleLinkClick = this.handleLinkClick.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({...props});
    }

    handleLinkClick() {
        const { ApplicationStore } = this.props;
        ApplicationStore.closeModalLogin();
        this.closeMenu();
    }

    openModalLogin() {
        const { ApplicationStore } = this.props;
        ApplicationStore.openModalLogin();
    }
    
    openModalRegister() {
        const { ApplicationStore } = this.props;
        ApplicationStore.openModalRegister();
    }

    openMenuResponsive() {
        if(this.state.newMenuState) {
            this.setState({
                menuMobileOpen: this.state.newMenuState,
            });
            Utils.disableBrowserScroll();
        }
        if(this.state.menuMobileOpen) {
            Utils.enableBrowserScroll();
        } else {
            Utils.disableBrowserScroll();
        }
        ApplicationStore.setMenuMobileOpen(!ApplicationStore.menuMobileOpen)
        this.setState({
            menuMobileOpen: !this.state.menuMobileOpen,
        });
    }

    logOut() {
        this.props.AuthStore.logOut();
        window.location.reload();
    }

    handleStateChange (state) {
        ApplicationStore.setMenuMobileOpen(state.isOpen)
        this.setState({menuOpenMobile: state.isOpen})
    }

    closeMenu () {
        ApplicationStore.setMenuMobileOpen(false)
        this.setState({menuOpenMobile: false})
    }

    toggleMenu () {
        this.setState({menuOpenMobile: !this.state.menuOpenMobile})
    }

    handlePage(to) {
        const { ApplicationStore } = this.props
        ApplicationStore.history.push(to)
        this.closeMenu()
     }

    render() {

        const { ApplicationStore, AuthStore, CompanysStore, AdventuresStore } = this.props;

        let finalImg;
        if(AuthStore.authUser){
            finalImg = (AuthStore.authUser.photo === null ? imgTeste : AuthStore.authUser.photo);
        }else{
            finalImg = imgTeste;
        }

        if (this.props.minimal) {

            if (!!CompanysStore.Company && this.state.company_url !== CompanysStore.Company.url) {
                this.setState({ 
                    company_logo: !!CompanysStore.Company.image && CompanysStore.Company.image.url, 
                    company_url: CompanysStore.Company.url,
                    company_name: CompanysStore.Company.fantasy_name
                })
            } else if (!!AdventuresStore.Adventure && this.state.company_url !== AdventuresStore.Adventure.company_url) {
                this.setState({
                    company_logo: AdventuresStore.Adventure.company_logo,
                    company_url: AdventuresStore.Adventure.company_url,
                    company_name: AdventuresStore.Adventure.company,
                })
            }

            return (
                <nav className={"nav tiny"} id="nav">
                    <div className={"wrapper " + (ApplicationStore.menuBorder ? "nav-border" : "")}>
                        <div id="menuDiv" className={`nav-items minimal-menu Grid Grid--center ${AuthStore.isLogged === true ? "logged" : ""} ${!!this.state.company_logo && !!this.state.company_url && !!this.state.company_name ? "cols-2" : "cols-1"}`}>
                            {!!this.state.company_logo && !!this.state.company_url && !!this.state.company_name &&
                            <Link to={'/site/' + this.state.company_url}><img src={this.state.company_logo} alt={this.state.company_name}/></Link>
                            }
                            <ul id="menuUl" className={(AuthStore.isLogged ? " top-10" : "")}>
                                <li>
                                    {AuthStore.isLogged === true &&
                                        <Dropdown className="account-dropdown" ref="dropdown">
                                            <DropdownTrigger>
                                                <img src={finalImg} className="user-logged-image" alt="User"/>{!!AuthStore.authUser ? (<span className="user-label">{AuthStore.authUser.name}</span>) : "" }
                                            </DropdownTrigger>
                                            <DropdownContent>
                                                <ul className="account-dropdown__quick-links account-dropdown__segment">
                                                    <li className="links account-dropdown__link">
                                                        <a onClick={this.logOut.bind(this)} className="account-dropdown__link__anchor text-red" href="#">
                                                            Sair
                                                        </a>
                                                    </li>
                                                </ul>
                                            </DropdownContent>
                                        </Dropdown>
                                    }
                                </li>
                                {(AuthStore.isLogged === false) &&
                                    <li className="login-button">
                                        <a className="btn btn-purple btn-call-action" onClick={this.openModalLogin.bind(this)}>Login</a>
                                    </li>
                                }
                            </ul>
                        </div>
                    </div>
                </nav>
            )
        } else {
            return (
                <nav className={"nav tiny"} id="nav">
                    <div className={"wrapper " + (ApplicationStore.menuBorder ? "nav-border" : "")}>
                        <div className="Grid">
                            <div className="navbar-brand">
                                <Link to="/">
                                    <img src={logo} alt="Adrena.me"/>
                                </Link>
                            </div>
                            <MainSearch history={this.props.history} />
                            <SideMenu
                                isOpen={ApplicationStore.menuMobileOpen}
                                onStateChange={(state) => this.handleStateChange(state)}
                                right
                                className="nav-icons"
                                width={ '100%' }
                                customBurgerIcon={ <div className="nav-icons Grid Grid--center">
                                    <div id="nav-icon4" onClick={this.openMenuResponsive.bind(this)}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div> }
                            >
                                <div className="Grid Grid-column Grid--center side-custom-menu">
                                    {AuthStore.isLogged === true ?
                                        <div className="responsive-menu">
                                            <div className="section-logo">
                                                <div className="Grid Grid--center logo">
                                                    <img src={logo2} alt="Adrena.me"/>
                                                </div>
                                                {/* <div className="Grid Grid--center adrena">
                                                    <img src={adrena} alt="Adrena.me"/>
                                                </div> */}
                                            </div>
                                            <div className="section-other">
                                                <Link  to="/perfil" className="links account-dropdown__link__anchor" href="#" onClick={this.handleLinkClick}>
                                                    Perfil
                                                </Link>
                                                <Link onClick={() => this.closeMenu()} to="/minhas-aventuras" className="links account-dropdown__link__anchor" href="#">
                                                    Minhas Aventuras
                                                </Link>
                                                <Link onClick={() => this.closeMenu()} to="/contato" className="links account-dropdown__link__anchor" href="#">
                                                    Contato
                                                </Link>
                                                <a onClick={this.logOut.bind(this)} className="links account-dropdown__link__anchor text-red" href="#">
                                                    Sair
                                                </a>
                                            </div>
                                            <ContactLinks />
                                        </div>
                                        :
                                        <div className="responsive-menu">
                                            <div className="section-logo">
                                                <div className="Grid Grid--center logo">
                                                    <img src={logo2} alt="Adrena.me"/>
                                                </div>
                                                {/* <div className="Grid Grid--center adrena">
                                                    <img src={adrena} alt="Adrena.me"/>
                                                </div> */}
                                            </div>
                                            <div className="Grid section-login">
                                                <a className="links btn btn-secondary login" onClick={this.openModalLogin.bind(this)}>Login</a>
                                                <a className="links partner" href="https://www.offstation.com.br/#demonstracao" target="_blank">Seja parceiro</a>
                                            </div>
                                            <div className="section-other">
                                                <div className="links" onClick={() => this.handlePage('/sobre')}>Sobre</div>
                                                <div className="links" onClick={() => this.handlePage('/faq')}>FAQ</div>
                                                <div className="links" onClick={() => this.handlePage('/contato')}>Contato</div>
                                            </div>
                                            <ContactLinks />
                                        </div>
                                    }
                                </div>
                            </SideMenu>
    
                            <div id="menuDiv" className={"nav-items Grid Grid--center " + (this.state.menuOpen === true ? " display-block Grid-md-12" : " ") + (AuthStore.isLogged === true ? " logged" : " ")}>
                                <ul id="menuUl" className={"float-right " + (this.state.menuOpen === true ?  "float-left" : "") + (AuthStore.isLogged ? " top-10" : "")}>
                                    <li>
                                        {AuthStore.isLogged === true ?
                                            <Dropdown className="account-dropdown" ref="dropdown">
                                                <DropdownTrigger>
                                                    <img src={finalImg} className="user-logged-image" alt="User"/>{!!AuthStore.authUser ? (<span className="user-label">{AuthStore.authUser.name}</span>) : "" }
                                                </DropdownTrigger>
                                                <DropdownContent>
                                                    <ul className="account-dropdown__quick-links account-dropdown__segment">
                                                        <li className="links account-dropdown__link">
                                                            <Link  to="/perfil" className="account-dropdown__link__anchor" href="#" onClick={this.handleLinkClick}>
                                                                Perfil
                                                            </Link>
                                                        </li>
                                                        <li className="links account-dropdown__link">
                                                            <Link to="/minhas-aventuras" className="account-dropdown__link__anchor" href="#" onClick={this.handleLinkClick}>
                                                                Minhas Aventuras
                                                            </Link>
                                                        </li>
                                                        <li className="links account-dropdown__link">
                                                            <Link to="/contato" className="account-dropdown__link__anchor" href="#" onClick={this.handleLinkClick}>
                                                                Contato
                                                            </Link>
                                                        </li>
                                                        <li className="links account-dropdown__link">
                                                            <a onClick={this.logOut.bind(this)} className="account-dropdown__link__anchor text-red" href="#">
                                                                Sair
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </DropdownContent>
                                            </Dropdown>
                                            :
                                            <a href="https://www.offstation.com.br/#demonstracao" target="_blank">Seja parceiro</a>
                                        }
                                    </li>
                                    {(AuthStore.isLogged === false) ?
                                        <li className="login-button">
                                            <a className="btn btn-purple btn-call-action" onClick={this.openModalLogin.bind(this)}>Login</a>
                                        </li> : ""}
                                </ul>
                            </div>
    
                        </div>
                    </div>
                </nav>
            )
        }
    }
}


export default Menu;