/**
 * Created by matheusmorett on 28/03/18.
 */
import {observable, action} from 'mobx';
import Utils from 'utils/Utils';
import Api from 'utils/Api';

class LocationStore {

  @observable city = "";
  @observable city_url = "";
  @observable lat;
  @observable lng;
  @observable region;
  @observable regions = [];

  constructor() {
    try {
      if (localStorage.getItem("region")) {
        this.region = JSON.parse(localStorage.getItem("region"));
        this.setCity(this.region.name);
      }
    } catch (error) {
      // pass
    }
  }

  @action
  clearRegion() {
    this.city = "";
    this.city_url = "";
    this.region = undefined;
  }

  @action
  clearRegions() {
    this.regions = [];
  }

  @action
  setCity(city = "") {
    this.city = city;
    this.city_url = Utils.formatUrl(city);
  }

  @action
  setLatLng(lat, lng) {
    this.lat = lat;
    this.lng = lng;
  }

  @action
  setRegion(region) {
    // DESATIVADO TEMPORARIAMENTE
    // this.region = region;
    // this.setCity(region.name);
    // localStorage.setItem("region", JSON.stringify(region));
  }

  @action
  getRegionByUrl(urlName) {
    return Api.call('/region/get-by-url', 'get', { urlName }).then(res => {
      if (res && res.data.data) {
        return res.data.data;
      }
      return null;
    })
  }

  @action
  getRegionByLatLng(params) {
    return Api.call('/region/get-by-lat-lng', 'get', params).then(res => {
      if (res && res.data.data) {
        return res.data.data;
      }
      return null;
    })
  }

  @action
  getRegions() {
    return Api.call('/region/get-regions', 'get').then(res => {
      if (res && res.data.data) {
        this.regions = res.data.data;
        return res.data.data;
      }
      return null;
    })
  }

}

export default new LocationStore();


