/**
 * Created by matheusmorett on 16/08/17.
 */
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Helmet } from "react-helmet";


import 'normalize.css';
import "react-placeholder/lib/reactPlaceholder.css";
import 'assets/stylesheets/grid.scss';
import 'assets/stylesheets/Linearicons.scss';
import 'assets/stylesheets/fonts.scss';
import 'assets/stylesheets/layout.scss';
import { ToastContainer } from 'react-toastify';
import 'assets/stylesheets/site-theme.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import Script from 'react-load-script'
/* Menu */
import Menu from "components/Menu";
import AsyncComponent from 'components/Hocs/AsyncComponent';

/* Login */
import Modal from 'react-modal';
import { MODAL_STYLES } from 'utils/Consts';
import Register from "components/Register";
import ForgotPassword from "components/ForgotPassword";
import Login from "components/Login";

/* Footer */
import Footer from "components/Footer";
import CookieAcceptance from '../../components/CookieAcceptance';

/* Container */
const Adventure = AsyncComponent(() =>
    import
        ("modules/Adventure/containers/Adventure").then(module => module.default)
);
const Invite = AsyncComponent(() =>
    import
        ("modules/Invite/containers/Invite").then(module => module.default)
);
const Category = AsyncComponent(() =>
    import
        ("modules/Category/containers/Category").then(module => module.default)
);
const Company = AsyncComponent(() =>
    import
        ("modules/Company/containers/Company").then(module => module.default)
);
const Region = AsyncComponent(() =>
    import
        ("modules/Region/containers/Region").then(module => module.default)
);
const AllCategory = AsyncComponent(() => 
    import
        ("modules/Category/containers/AllCategory").then(module => module.default)
);
const Checkout = AsyncComponent(() =>
    import
        ("modules/Checkout/containers/Checkout").then(module => module.default)
);
const Home = AsyncComponent(() =>
    import
        ("modules/Home/containers/Home").then(module => module.default)
);
const Profile = AsyncComponent(() =>
    import
        ("modules/Profile/containers/Profile").then(module => module.default)
);
const Search = AsyncComponent(() =>
    import
        ("modules/Search/containers/Search").then(module => module.default)
);
const Tag = AsyncComponent(() => 
    import 
        ("modules/Tag/containers/Tag").then(module => module.default)
);
const About = AsyncComponent(() =>
    import
        ("modules/About/containers/About").then(module => module.default)
);
const Security = AsyncComponent(() =>
    import
        ("modules/Security/containers/Security").then(module => module.default)
);
const Terms = AsyncComponent(() =>
    import
        ("modules/Terms/containers/Terms").then(module => module.default)
);
const Cancelment = AsyncComponent(() =>
    import
        ("modules/Cancelment/containers/Cancelment").then(module => module.default)
);
const Faq = AsyncComponent(() =>
    import
        ("modules/Faq/containers/Faq").then(module => module.default)
);
const Contact = AsyncComponent(() =>
    import
        ("modules/Contact/containers/Contact").then(module => module.default)
);
const PostEvent = AsyncComponent(() =>
    import
        ("modules/PostEvent/containers/PostEvent").then(module => module.default)
);
const ReserveDetail = AsyncComponent(() =>
    import
        ("modules/Reserve/containers/ReserveDetail").then(module => module.default)
);
const VoucherSuccess = AsyncComponent(() =>
    import
        ("modules/Checkout/containers/VoucherSuccess").then(module => module.default)
);
const ReserveList = AsyncComponent(() =>
    import
        ("modules/Reserve/containers/ReserveList").then(module => module.default)
);
const NotFound404 = AsyncComponent(() =>
    import ("modules/404/containers/404").then(module => module.default)
);
const OtherPayment = AsyncComponent(() =>
    import
        ("modules/Checkout/containers/OtherPayment").then(module => module.default)
);


@inject("AuthStore", "ApplicationStore")
@observer
class SiteTemplate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: false
        };
    }

    componentDidMount() {
        const { history, ApplicationStore } = this.props;
        ApplicationStore.setHistory(history);
        ApplicationStore.unsetMinimalTemplate();
    }

    handleCloseModal() {
        const { ApplicationStore } = this.props;
        ApplicationStore.closeModal();
    }

    handleGoToPage(page) {
        const { history } = this.props;
        history.push(page);
    }

    getBasename() {
        if (window.location.href.includes('letsrock')) {
            return "clientes/adrenalyze-web/";
        } else if (window.location.href.includes('adrena.me')) {
            return "";
        } else if (window.location.href.includes('localhost')) {
            return "";
        } else {
            return "clientes/adrenalyze-web/";
        }
    }

    render() {
        const { ApplicationStore } = this.props;
        
        return (
            <div style={{
                background: ApplicationStore.pageBackground,
                overflow: ApplicationStore.menuMobileOpen ? "hidden" : undefined,
                height: ApplicationStore.menuMobileOpen ? "100vh" : undefined
            }}>
                <Helmet>
                    <title>Adrena.me | Saia da rotina. Viva a adrenalina!</title>
                    <meta property="fb:app_id" content="342755846164051" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Adrena.me | Saia da rotina. Viva a adrenalina!" />
                    <meta property="og:description" content="O Adrena.me traz os esportes de aventura e atividades radicais disponíveis na sua região de forma agradável e descomplicada." />
                    <meta name="robots" content="index, follow" />
                    <meta name="description"
                        content="O Adrena.me traz os esportes de aventura e atividades radicais disponíveis na sua região de forma agradável e descomplicada. Agendamento e pagamento* online, rápido e prático. Aventure-se conosco" />
                    <meta property="og:image"
                        content="https://s3-sa-east-1.amazonaws.com/adrenalyze-app/adrenalyze/meta-tag-image-1200x630.jpg" />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <Modal
                    isOpen={ApplicationStore.modal}
                    onRequestClose={() => ApplicationStore.closeModal()}
                    style={MODAL_STYLES}
                    contentLabel="Cadastre-se"
                >
                    <div className="box-modal">
                        <div className="Grid Grid--gutter Grid-column">
                            <div className="Grid-cell">
                                <div className="close-modal">
                                    <p className="float-right" onClick={() => ApplicationStore.closeModal()}>
                                        {(ApplicationStore.modalNotClosable === false) && "Fechar X"}
                                    </p>
                                </div>
                            </div>
                            <div className="Grid-cell">
                                {(ApplicationStore.modalLogin && <Login closeModal={this.handleCloseModal.bind(this)}
                                                                        goToPage={(page) => this.handleGoToPage(page)} /> )}
                                {(ApplicationStore.modalRegister && <Register closeModal={this.handleCloseModal.bind(this)}
                                                                        goToPage={(page) => this.handleGoToPage(page)} /> )}
                                {(ApplicationStore.modalForgotPassword && <ForgotPassword closeModal={this.handleCloseModal.bind(this)}
                                                                        goToPage={(page) => this.handleGoToPage(page)} /> )}
                            </div>
                        </div>
                    </div>
                </Modal>
                <Menu goTo={(to) => this.handleGoToPage(to)} history={this.props.history} />
                <Script onCreate={() => (function (w, d, s, l, i) {
                            w[l] = w[l] || [];
                            w[l].push({
                                'gtm.start': new Date().getTime(), event: 'gtm.js'
                            });
                            var f = d.getElementsByTagName(s)[0],
                                j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
                            j.async = true;
                            j.src =
                                'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                            f.parentNode.insertBefore(j, f);
                        })(window, document, 'script', 'dataLayer', 'GTM-NR7GQK8')} />
                <ToastContainer
                    position="top-right"
                    type="default"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    pauseOnHover
                />
                <Switch>
                    {(this.props.AuthStore.isLogged) ?
                        <Route
                            exact={true}
                            path="/pedido/:pk_request/:pk_payment/detalhes/reserva"
                            component={ReserveDetail}
                        />
                        : ""}
                    {(this.props.AuthStore.isLogged) ?
                        <Route
                            exact={true}
                            path="/other-payment/checkout"
                            component={OtherPayment}
                        />
                        : ""}
                    {/* {(this.props.AuthStore.isLogged) ?
                        <Route
                            exact={true}
                            path="/voucher/sucesso"
                            component={VoucherSuccess}
                        />
                        : ""} */}
                    {(this.props.AuthStore.isLogged) ?
                        <Route
                            exact={true}
                            path="/pedido/:pk_request/:pk_payment/detalhes/reserva/sucesso"
                            component={ReserveDetail}
                        />
                        : ""}
                    {(this.props.AuthStore.isLogged) ?
                        <Route
                            exact={true}
                            path="/minhas-aventuras"
                            component={ReserveList}
                        />
                        : ""}
                    <Route
                        exact={true}
                        path="/perfil"
                        component={Profile}
                    />
                    <Route
                        path="/404"
                        component={NotFound404}
                    />
                    <Route
                        exact={true}
                        path="/pesquisar"
                        component={Search}
                    />
                    <Route
                        exact={true}
                        path="/sobre"
                        component={About}
                    />
                    <Route
                        exact={true}
                        path="/seguranca"
                        component={Security}
                    />
                    <Route
                        exact={true}
                        path="/cancelamento"
                        component={Cancelment}
                    />
                    <Route
                        exact={true}
                        path="/termo-de-uso"
                        component={Terms}
                    />
                    <Route
                        exact={true}
                        path="/faq"
                        component={Faq}
                    />
                    <Route
                        exact={true}
                        path="/contato"
                        component={Contact}
                    />
                    <Route
                        exact={true}
                        path="/pos-evento/:id"
                        component={PostEvent}
                    />
                    <Route
                        exact={true}
                        path="/tags/:id"
                        component={Tag}
                    />
                    <Route
                        exact={true}
                        path="/:region/:category"
                        component={Category}
                    />
                    <Route
                        exact={true}
                        path="/categorias"
                        component={AllCategory}
                    />
                    <Route
                        exact={true}
                        path="/:region/realizadores/:id"
                        component={Company}
                    />
                    <Route
                        exact={true}
                        path="/:region"
                        component={Region}
                    />
                    <Route
                        exact={true}
                        path="/tags/:id"
                        component={Tag}
                    />
                    <Route
                        exact={true}
                        path="/aventura/:id"
                        component={Adventure}
                    />
                    <Route
                        exact={true}
                        path="/aventura/:id/checkout"
                        component={Checkout}
                    />
                    <Route
                        exact={true}
                        path="/aventura/:id/checkout/:token"
                        component={Checkout}
                    />
                    <Route
                        exact={true}
                        path="/aventura/:id/:token"
                        component={Invite}
                    />
                    <Route
                        exact={true}
                        path="/:region/:category/:id"
                        component={Adventure}
                    />
                    {/* <Route
                        exact={true}
                        path="/:region/:category/:id/checkout"
                        component={Checkout}
                    /> */}
                    {/* <Route
                        exact={true}
                        path="/:region/:category/:id/checkout/:token"
                        component={Checkout}
                    /> */}
                    {/* <Route
                        exact={true}
                        path="/:region/:category/:id/:token"
                        component={Invite}
                    /> */}
                    <Route
                        exact={true}
                        path="/:region/:category/:id"
                        component={Adventure}
                    />
                    <Route
                        exact={true}
                        path="/"
                        component={Home}
                    />
                    <Route
                        path="/"
                        component={NotFound404}
                    />
                </Switch>
                <Footer
                    withoutNewsletter={true}
                />
                <CookieAcceptance />
            </div>
        )
    }
}

export default SiteTemplate;