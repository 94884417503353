/**
 * Created by matheusmorett on 8/29/17.
 */
import {observable, action} from 'mobx';
import Api from './../utils/Api';

class SearchStore {

  @observable loading = false;
  @observable loadingCity = false;
  @observable SearchResult = [];
  @observable SearchResultCity = [];
  @observable filter = "";
  @observable coords = {
    lat: "",
    lng: ""
  };

  @action
  getItems(params) {
    this.loading = true;
    if (params.lat && params.lng) {
      this.coords = {
        lat: params.lat,
        lng: params.lng
      }
    }
    return Api.call('/search/get-adventures-by-location', 'get', params).then((response) => {
      this.loading = false;
      this.SearchResult = response ? response.data.data : null;
    });
  }

  @action
  getFirstCategories(params) {
    // this.loading = true;
    // return Api.call('/category', 'get', params).then((response) => {
    //     this.loading = false;
    //     this.SearchResult = response.data.data;
    // });
  }

  @action
  getCity(params) {
    this.loadingCity = true;
    return Api.call('/cities/get-cities', 'get', params).then((response) => {
      this.loadingCity = false;
      this.SearchResultCity = response.data.data;
    });
  }
}

export default new SearchStore();
