/**
 * Created by matheusmorett on 22/08/17.
 */
import React, {Component} from 'react';

import "./Alert.scss";

export default class Alert extends Component {

    constructor(props){
        super(props);
        
        this.state = {
            alert: true
        }
        
    }

    closeAlert() {
        this.props.closeAlert();
    }

    render() {

        if(this.props.formError.message !== "" && this.props.formError.message !== undefined){
            return (
                <div className="Grid-cell">
                    <div className={"alert " + (this.props.formError.status === false ? " alert-success " : " alert-error") + (this.state.alert === false ? " display-none" : "")}>
                        <span onClick={this.closeAlert.bind(this)} className={"closebtn"}>&times;</span>
                        {this.props.formError.message}
                    </div>
                </div>
            )
        }
        return <div></div>
    }

}


