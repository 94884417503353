/**
 * Created by matheusmorett on 23/08/17.
 */
import {observable, action} from 'mobx';
import {Adventure} from 'models/Adventure';
import Api from './../utils/Api';

class CompanysStore {

  @observable loading = false;
  @observable Companys = [];
  @observable HighlightedCompany = [];
  @observable Company;

  @action
  async getCompanys(params) {
    this.loading = true;
    this.Companys = [];
    return Api.call('/company/get-companys', 'get', params).then((response) => {
      this.loading = false;
      this.Companys = response ? response.data.data : []
    });
  }

  @action
  getHighlighted() {
    return Api.call('/company/get-highlighted', 'get', {}).then((response) => {
      this.HighlightedCompany = response.data.data;
    });
  }

  @action
  getByUrl(params) {
    this.loading = true;
    this.Company = undefined;
    return Api.call('/company/get-by-fantasy-name', 'get', params).then((response) => {
      this.loading = false;
      this.Company = response.data.data
    })
  }

}

export default new CompanysStore();