/**
 * Created by matheusmorett on 23/08/17.
 */
import {observable, action} from "mobx";
import Api from "utils/Api";
import moment from "moment";
import {Adventure} from 'models/Adventure';
import Utils from 'utils/Utils';
import LocationStore from 'stores/LocationStore'

export const PerPage = 12;
export const PerPageCategory = 8;
export const PerPageRegion = 8;
export const PerPageTag = 8;


class AdventuresStore {

  @observable searchParams = {};
  @observable searchFilters = {};
  @observable loading = false;
  @observable page = 1;
  @observable pageCategory = 1;
  @observable pageTag = 1;
  @observable pageRegion = 1;
  @observable Adventures = [];
  @observable noAdventures = false;
  @observable SearchAdventures = [];
  @observable FavoritesAdventures = [];
  @observable AdventuresByCategory = [];
  @observable AdventuresByRegion = [];
  @observable AdventuresByTag = [];
  @observable AdventuresForYou = [];
  @observable AdventuresMoreRecommended = [];
  @observable SuggestedAdventures = [];
  @observable MostSearchAdventures = [];
  @observable Adventure;
  @observable adventuresAlreadyGetIt = [];
  @observable HighlightedTags = [];
  @observable CompanyAdventures = [];

  @action
  getAll(params = {}) {
    this.loading = true;
    params["per-page"] = PerPage;
    // params.page = this.page;
    params.adventuresAlreadyGetIt = this.adventuresAlreadyGetIt;

    return Api.call('/adventure/get-all', 'get', params).then((response) => {
      if (response.data.data.length === 0) {
        this.loading = false;
        this.noAdventures = true;
        return false;
      }

      const adventures = response.data.data.map((item) => {
        this.adventuresAlreadyGetIt.push(parseInt(item.pk_adventure));
        return new Adventure(item);
      });

      this.page++;
      this.loading = false;
      const verifyPage = this.Adventures.find(a => a.pkAdventure === response.data.data[0].pk_adventure);
      if (verifyPage === undefined) { // Ainda existem aventuras
        this.Adventures = this.Adventures.concat(adventures);
        this.noAdventures = false;
        return true;
      } // Nao existem mais aventuras
      this.noAdventures = true;
      return false;
    });

  }

  @action
  reset() {
    this.adventuresAlreadyGetIt = [];
    this.Adventures = [];
    this.noAdventures = false;
  }

  @action
  setLoading() {
    this.loading = true;
  }

  @action
  setLoaded() {
    this.loading = false;
  }

  @action
  applyFiltersSearch(filters) {
    this.searchFilters = {...this.searchFilters, ...filters}
    this.page = 1;
    this.SearchAdventures = [];
    this.MostSearchAdventures = [];
    this.getBySearch(this.searchParams);
    this.getMostSearch(this.searchParams);
  }

  @action
  getBySearch(params) {
    this.searchParams = {...this.searchParams, ...params}
    params = {...this.searchParams, ...this.searchFilters}
    this.loading = true;
    params["per-page"] = PerPage;
    params.page = this.page;

    return Api.call('/adventure/get-by-search', 'get', params).then((response) => {
      if (response.data.data.length === 0) {
        this.loading = false;
        this.noAdventures = true;
        return false;
      }
      const adventures = response.data.data.map((item) => {
        return new Adventure(item);
      });
      this.page++;
      this.loading = false;
      if (response.data.data.length > 0) {
        const verifyPage = this.SearchAdventures.find(a => a.pkAdventure === response.data.data[0].pk_adventure);
        if (verifyPage === undefined) { // Ainda existem aventuras
          this.SearchAdventures = this.SearchAdventures.concat(adventures);
          return true;
        } // Nao existem mais aventuras
        return false;
      }
      return false;
    });
  }

  @action
  getForYou(params) {
    return Api.call('/adventure/get-adventures-for-you', 'get', params).then((response) => {

      const adventures = response.data.data.map((item) => {
        return new Adventure(item);
      });
      this.AdventuresForYou = (adventures);
    });
  }

  @action
  getMoreRecommended(params) {
    return Api.call('/adventure/get-adventures-more-recommended', 'get', params).then((response) => {
      if (response.data.success === true) {
        const adventures = response.data.data.map((item) => {
          return new Adventure(item);
        });
        this.AdventuresMoreRecommended = (adventures);
      }
    });
  }

  @action
  getAdventureByTags(params) {
    this.SuggestedAdventures = [];
    return Api.call('/adventure/get-adventures-by-tags', 'get', params).then((response) => {
      if (response.data.success === true) {
        const adventures = response.data.data.map((item) => {
          return new Adventure(item);
        });
        this.SuggestedAdventures = (adventures);
      }
    });
  }

  @action
  getAdventuresByCompany(params, enable) {
    if(enable)
      params.enable = enable
    return Api.call('/company/get-company-adventures', 'get', params).then(res => {
      if (res.data.success === true) {
        let filteredAdventures = []

        // Filtra aventuras
        if (enable !== undefined && enable.length > 0) {
          filteredAdventures = enable
                                .filter((val, i, arr) => arr.indexOf(val) === i)
                                .reduce((prev, curItem) => {
                                  const adv = res.data.data.find(adv => Number(adv.pk_adventure) === Number(curItem))
                                  if (adv)
                                    return [ ...prev, adv ]
                                  else 
                                    return prev
                                }, [])
          
        } else {
          filteredAdventures = res.data.data
        }

        const adventures = filteredAdventures.map(item => new Adventure(item))
        this.CompanyAdventures = adventures;
      }
    })
  }

  @action
  setCompanyAdventureRequests(requests, pkAdventure) {
    const adventure = this.CompanyAdventures.find(adv => adv.pkAdventure === pkAdventure)
    const index = this.CompanyAdventures.findIndex(adv => adv.pkAdventure === pkAdventure)

    if(adventure && index !== -1){
      adventure.requests = requests
      const advs = [...this.CompanyAdventures]
      advs[index] = adventure
      this.CompanyAdventures = advs
    }

  }

  @action
  getByUrlName(params) {
    params.date = moment().format("YYYY-MM-DD") + " 23:59:59";
    this.Adventure = undefined;
    this.SuggestedAdventures = [];
    this.loading = true;
    return Api.call('/adventure/get-by-url', 'get', params).then((response) => {
      if (response) {
        this.Adventure = new Adventure(response.data.data);
        this.loading = false;
        return response.data.data;
      }
    });
  }

  @action
  getResumedAdventureByUrlName(params) {
    params.date = moment().format("YYYY-MM-DD") + " 23:59:59";
    this.Adventure = undefined;
    this.SuggestedAdventures = [];
    this.loading = true;
    return Api.call('/adventure/get-resumed-by-url', 'get', params).then((response) => {
      if (response) {
        this.Adventure = new Adventure(response.data.data);
        this.loading = false;
        return response.data.data;
      }
    }).catch(() => {
      this.loading = false;
      return null
    });
  }


  @action
  getByCheckout(params) {
    params.date = moment().format("YYYY-MM-DD") + " 23:59:59";

    this.loading = true;
    return Api.call('/adventure/get-by-checkout', 'get', params).then((response) => {
      this.loading = false;
      this.Adventure = new Adventure(response.data.data);
      return response.data.data;
    });
  }

  @action
  resetByCategory() {
    this.adventuresByCategory = []
  }

  @action
  getByCategory(params) {

    params["per-page"] = PerPageCategory;
    params.page = this.pageCategory;
    this.loading = true;
    return Api.call('/adventure/get-by-category', 'get', params, false).then((response) => {
      this.pageCategory++;
      const adventures = response.data.data.map((item) => {
        return new Adventure(item);
      });
      const verifyPage = this.AdventuresByCategory.find(a => a.pkAdventure === response.data.data[0].pk_adventure);
      if (verifyPage === undefined) {
        this.AdventuresByCategory = this.AdventuresByCategory.concat(adventures);
        this.loading = false;
        return true;
      }
      this.loading = false;
      return false;
    });
  }

  @action
  resetAdventureByRegion() {
    this.AdventuresByRegion = [];
  }

  @action
  getByRegion(params) {
    params["per-page"] = PerPageRegion;
    params.page = this.pageRegion;
    this.loading = true;
    return Api.call('/adventure/get-by-region', 'get', params, false).then((response) => {
      this.pageRegion++;
      this.loading = false;
      const adventures = response.data.data.map((item) => {
        return new Adventure(item);
      });
      const verifyPage = this.AdventuresByRegion.find(a => a.pkAdventure === response.data.data[0].pk_adventure);
      if (verifyPage === undefined) {
        this.AdventuresByRegion = this.AdventuresByRegion.concat(adventures);
        return true;
      }
      return false;
    });
  }

  @action
  getHighlightedTags() {
    return Api.call('tag/get-highlighted', 'get', {}, false).then(res => {
      if (res && res.data && res.data.data) {
        this.HighlightedTags = res.data.data.map(tag => {
          const region_name = LocationStore.region ? `${LocationStore.region.name_append} ${LocationStore.region.name}` : ""

          if (tag.page_title.includes('{CIDADE}')) {
            tag.page_title = Utils.injectCidade(region_name, tag.page_title)
          }

          if (tag.description.includes('{CIDADE}')) {
            tag.description = Utils.injectCidade(region_name, tag.description)
          }

          if (tag.additional_description.includes('{CIDADE}')) {
            tag.additional_description = Utils.injectCidade(region_name, tag.additional_description)
          }
          return {...tag, adventures: [] }
        })

      }
    })
  }

  @action
  getHighlightedTagAdventures(params, i) {
    const {tag, region} = params
    return Api.call('adventure/get-by-tag', 'get', {limit: 4, url_name: tag.url_name, region}, false).then(res => {
      if (res && res.data && res.data.data) {
        this.HighlightedTags[i] = {...tag, adventures: res.data.data.map(json => new Adventure(json))};
      }
    })
  }

  @action
  resetByTag() {
    this.AdventuresByTag = [];
  }

  @action
  getByTag(params) {

    params["per-page"] = PerPageTag;
    params.page = this.pageTag;
    this.loading = true;
    return Api.call('/adventure/get-by-tag', 'get', params, false).then((response) => {
      this.pageTag++;
      const adventures = response.data.data.map((item) => {
        return new Adventure(item);
      });
      const verifyPage = this.AdventuresByTag.find(a => a.pkAdventure === response.data.data[0].pk_adventure);
      if (verifyPage === undefined) {
        this.AdventuresByTag = this.AdventuresByTag.concat(adventures);
        this.loading = false;
        return true;
      }
      this.loading = false;
      return false;
    });
  }

  @action
  getMostSearch(params) {
    this.searchParams = {...this.searchParams, ...params}
    params = {...this.searchParams, ...this.searchFilters}
    return Api.call('/adventure/get-most-search', 'get', params).then((response) => {
      if (response) {
        const adventures = response.data.data.map((item) => {
          return new Adventure(item);
        });
        if (response.data.data.length > 0) {
          const verifyPage = this.MostSearchAdventures.find(a => a.pkAdventure === response.data.data[0].pk_adventure);
          if (verifyPage === undefined) { // Ainda existem aventuras
            this.MostSearchAdventures = this.MostSearchAdventures.concat(adventures);
            return true;
          } // Nao existem mais aventuras
          return false;
        }
      }
      return false;
    });
  }

  @action
  getUserFavoritesAdventures() {
    this.loading = true;
    return Api.call('/adventure/get-user-favorites-adventures', 'get').then((response) => {
      this.loading = false;
      this.FavoritesAdventures = response.data.data;
    });
  }

  @action
  cancelRequestAdventure(params) {

    this.loading = true;

    return Api.call('/request/remove-reserve', 'post', params).then(() => {

      const futureAdventuresIndex = this.FutureAdventures.findIndex(a => a.pk_request === params.pk_request);
      this.FutureAdventures.splice(futureAdventuresIndex, 1);
      this.loading = false;
      return true;

    });

  }

  @action
  likeAdventure(params) {

    this.loading = true;

    return Api.call('/feedback/like', 'post', params).then(() => {
      this.loading = false;
      return true;
    });

  }

  @action
  dislikeAdventure(params) {

    this.loading = true;

    return Api.call('/feedback/dislike', 'post', params).then(() => {
      this.loading = false;
      return true;
    });

  }

  @action
  deleteFeedback(params) {

    this.loading = true;

    return Api.call('/feedback/delete-feedback', 'post', params).then(() => {
      this.loading = false;
      return true;
    });

  }

  @action
  setFavorite(params) {
    return Api.call('/favorite/create', 'post', params).then(() => {
      const adventure = this.Adventures.find(a => a.pkAdventure === params.fk_adventure);
      if (adventure !== undefined) {
        adventure.favorite = true;
      }
      const adventureForYou = this.AdventuresForYou.find(a => a.pkAdventure === params.fk_adventure);
      if (adventureForYou !== undefined) {
        adventureForYou.favorite = true;
      }
      const adventureMoreRecommended = this.AdventuresMoreRecommended.find(a => a.pkAdventure === params.fk_adventure);
      if (adventureMoreRecommended !== undefined) {
        adventureMoreRecommended.favorite = true;
      }
      const suggestedAdventure = this.SuggestedAdventures.find(a => a.pkAdventure === params.fk_adventure);
      if (suggestedAdventure !== undefined) {
        suggestedAdventure.favorite = true;
      }
      const adventuresByCategory = this.AdventuresByCategory.find(a => a.pkAdventure === params.fk_adventure);
      if (adventuresByCategory !== undefined) {
        adventuresByCategory.favorite = true;
      }
      const SearchAdventures = this.SearchAdventures.find(a => a.pkAdventure === params.fk_adventure);
      if (SearchAdventures !== undefined) {
        SearchAdventures.favorite = true;
      }
      const MostSearchAdventures = this.MostSearchAdventures.find(a => a.pkAdventure === params.fk_adventure);
      if (MostSearchAdventures !== undefined) {
        MostSearchAdventures.favorite = true;
      }

      const AdventuresByType = this.AdventuresByType.find(a => a.pkAdventure === params.fk_adventure);
      if (AdventuresByType !== undefined) {
        AdventuresByType.favorite = true;
      }

      this.getUserFavoritesAdventures();
    });
  }

  @action
  unsetFavorite(params) {

    return Api.call('/favorite/deleted', 'post', params).then(() => {
      const adventure = this.Adventures.find(a => a.pkAdventure === params.fk_adventure);
      if (adventure !== undefined) {
        adventure.favorite = false;
      }
      const adventureForYou = this.AdventuresForYou.find(a => a.pkAdventure === params.fk_adventure);
      if (adventureForYou !== undefined) {
        adventureForYou.favorite = false;
      }
      const adventureMoreRecommended = this.AdventuresMoreRecommended.find(a => a.pkAdventure === params.fk_adventure);
      if (adventureMoreRecommended !== undefined) {
        adventureMoreRecommended.favorite = false;
      }
      const suggestedAdventure = this.SuggestedAdventures.find(a => a.pkAdventure === params.fk_adventure);
      if (suggestedAdventure !== undefined) {
        suggestedAdventure.favorite = false;
      }
      const adventuresByCategory = this.AdventuresByCategory.find(a => a.pkAdventure === params.fk_adventure);
      if (adventuresByCategory !== undefined) {
        adventuresByCategory.favorite = false;
      }
      const SearchAdventures = this.SearchAdventures.find(a => a.pkAdventure === params.fk_adventure);
      if (SearchAdventures !== undefined) {
        SearchAdventures.favorite = false;
      }
      const MostSearchAdventures = this.MostSearchAdventures.find(a => a.pkAdventure === params.fk_adventure);
      if (MostSearchAdventures !== undefined) {
        MostSearchAdventures.favorite = false;
      }
      const AdventuresByType = this.AdventuresByType.find(a => a.pkAdventure === params.fk_adventure);
      if (AdventuresByType !== undefined) {
        AdventuresByType.favorite = false;
      }
      if (this.FavoritesAdventures.length > 0) {
        const favoriteIndex = this.FavoritesAdventures.findIndex(a => a.pk_adventure === params.fk_adventure);
        this.FavoritesAdventures.splice(favoriteIndex, 1);
      }

    });


  }

  clearAdventures() {
    this.page = 1;
    this.Adventures = [];
    this.Adventure = undefined;
    this.SearchAdventures = [];
    return true;
  }

  @action
  contactAdventure(params) {
    return Api.call('/adventure/contact', 'post', params).then((response) => {
      return response;
    });
  }

  @action
  getByType(params) {
    this.loading = true;
    return Api.call('/adventure/get-by-type', 'get', params).then((response) => {
      this.loading = false;
      if (response.data.data.length === this.AdventuresByType.length) return false

      if (response.data.data.length > 0) this.AdventuresByType = response.data.data.map((item) => {
        return new Adventure(item);
      });

      return response;
    })
  }

  @action
  saveAdventureEntrance(params) {
    return Api.call('/adventure/save-entrance', 'post', params);
  }
}

export default new AdventuresStore();