import React, {Component} from 'react';
import FacebookLogin from 'react-facebook-login';
import {inject, observer} from 'mobx-react';
import Utils from './../../utils/Utils';
import Alert from './../Alert';
import Button from 'components/Button';

import "./Register.scss";

@inject("AuthStore", "AdventuresStore", "ApplicationStore")
@observer
class Register extends Component {

    static defaultProps = {
        openModal: () => {
        },
        closeModal: () => {
        },
        goToPage: () => {
        }
    };

    constructor(props) {
        super(props);

        this.state = {
            menuOpen: false,
            name: '',
            email: '',
            password: '',
            alert: false,
            emailRight: false,
            formError: {
                status: false,
                message: ""
            }
        };

    }


    componentWillReceiveProps(props) {
        this.setState({...props});
    }

    validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    closeModal() {
        this.props.closeModal();
    }

    handleInputChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        if (name === "email") {
            if (Utils.validateEmail(value) === false) {
                this.setState({
                    emailRight: false
                });
            } else {
                this.setState({
                    emailRight: true
                });
            }
        }

        this.setState({
            [name]: value
        });
        this._checkFields(name);

    }

    _checkFields(name) {
        if (this.state[name] === "" || this.state[name] === false || this.state[name] === undefined) {
            this.setState({
                [name + "Error"]: true
            });
        } else {
            this.setState({
                [name + "Error"]: false
            });
        }
    }

    handleInputBlur(e) {
        const name = e.target.name;
        this._checkFields(name);
    }

    handleSubmitForm(e) {
        if (e) {
            e.preventDefault();
        }

        const {AuthStore, goToPage, ApplicationStore} = this.props;

        if (this.state.name !== "" && this.state.email !== "" && this.state.senha !== "" && this.state.emailRight === true) {
            const params = {
                "name": this.state.name,
                "email": this.state.email,
                "password": this.state.password
            };

            AuthStore.register(params).then((response) => {
                if (response.data.success === true) {
                    AuthStore.login(params).then((response) => {
                        if (response.success === true) {
                            if (AuthStore.reloadPageAfterLogin) {
                                window.location.reload();
                            } else if (AuthStore.pageAfterLogin !== "") {
                                goToPage(AuthStore.pageAfterLogin);
                                ApplicationStore.closeModal();
                            }
                        }
                    });
                } else {

                    this.setState({
                        formError: {
                            status: true,
                            message: response.data.data.message
                        }
                    });
                }
            }).catch(err => {
                this.setState({
                    formError: {
                        status: true,
                        message: err.data.message
                    }
                });
            });
        } else {
            this.checkFields("name");
            this.checkFields("email");
            this.checkFields("password");
        }

    }

    responseFacebook(response) {

        const {AuthStore, AdventuresStore, goToPage, ApplicationStore} = this.props;
        const params = {
            name: response.name,
            email: response.email,
            token: response.accessToken,
            photo: response.picture.data.url
        };

        AuthStore.loginSocial(params).then((response) => {

            if (response.success) {
                this.setState({
                    formError: {
                        status: false,
                        message: "Logado com sucesso"
                    }
                });
                ApplicationStore.closeModal();
                AdventuresStore.page = 1;
                if (AuthStore.reloadPageAfterLogin) {
                    window.location.reload();
                } else if (AuthStore.pageAfterLogin !== "") {
                    goToPage(AuthStore.pageAfterLogin);
                }
            } else {
                this.setState({
                    formError: {
                        status: true,
                        message: "Falha ao tentar acessar via Facebook."
                    }
                });
            }

        });


    }

    closeAlert() {
        this.setState({
            formError: {}
        });
    }

    handleGoToLogin() {
        const {ApplicationStore} = this.props;
        ApplicationStore.openModalLogin();
    }

    handleForgotPassword() {
        const {ApplicationStore} = this.props;
        ApplicationStore.openModalForgotPassword();
    }

    render() {

        const {AuthStore} = this.props;

        return (
            <div className="Register">
                <div className="modal-content">
                    <div className="facebook-login">
                        <FacebookLogin
                            appId="342755846164051"
                            autoLoad={false}
                            fields="name,email,picture.width(500).height(500)"
                            callback={this.responseFacebook.bind(this)}
                            isMobile={Utils.mobileCheck()}
                            disableMobileRedirect={true}
                            cssClass="btn btn-social btn-facebook btn-inner-card"
                            icon="fa-facebook"
                            textButton={(AuthStore.loading ? <div><i className='fa fa-spinner fa-spin'></i></div> : <div>LOGAR COM FACEBOOK</div>)}
                        />
                    </div>
                    <div className="Grid Grid-cell separator-or">
                        <div className="Grid-cell">
                            <hr />
                        </div>
                        <div className="Grid-cell text-quicksand-muted text-center">
                            ou
                        </div>
                        <div className="Grid-cell">
                            <hr />
                        </div>
                    </div>
                    <form onSubmit={this.handleSubmitForm.bind(this)}>
                        <div className="  input-cell">
                            <div className="full-width group-input">
                                <input className="full-width"
                                       type="text"
                                       required
                                       value={this.state.name}
                                       onChange={this.handleInputChange.bind(this)}
                                       onBlur={this.handleInputBlur.bind(this)}
                                       name="name"
                                       autoComplete="off"
                                />
                                <span className="bar full-width"></span>
                                <span
                                    className="help-hasError">{(this.state.nameError === true ? ' "Nome" não pode ficar em branco.' : '')}</span>
                                <label>Nome</label>
                            </div>
                        </div>

                        <div className="  input-cell">
                            <div className="full-width group-input">
                                <input className="full-width"
                                       type="text"
                                       required
                                       value={this.state.email}
                                       onChange={this.handleInputChange.bind(this)}
                                       onBlur={this.handleInputBlur.bind(this)}
                                       name="email"
                                       autoComplete="off"
                                />
                                <span className="bar full-width"></span>
                                <span
                                    className="help-hasError">{(this.state.emailRight === false && this.state.emailError === false ? ' "Email" inválido.' : '')}</span>
                                <span
                                    className="help-hasError">{(this.state.emailError === true ? ' "Email" não pode ficar em branco.' : '')}</span>
                                <label>Email</label>
                            </div>
                        </div>

                        <div className="  input-cell">
                            <div className="full-width group-input">
                                <input
                                    className="full-width"
                                    type="password"
                                    required
                                    value={this.state.password}
                                    onChange={this.handleInputChange.bind(this)}
                                    onBlur={this.handleInputBlur.bind(this)}
                                    name="password"
                                    autoComplete="off"
                                />
                                <span className="bar full-width"></span>
                                <span
                                    className="help-hasError">{(this.state.passwordError === true ? ' "Senha" não pode ficar em branco.' : '')}</span>
                                <label>Senha</label>
                            </div>
                        </div>
                        <Alert formError={this.state.formError} closeAlert={this.closeAlert.bind(this)}/>
                        <div className=" ">
                            <Button loading={AuthStore.loading} type="primary" size="full">
                                {(AuthStore.loading ? <div><i className='fa fa-spinner fa-spin'></i></div> :
                                    <div>CADASTRE-SE</div>)}
                            </Button>
                        </div>
                        <div className="other-links">
                            <div className="enter-link" onClick={this.handleGoToLogin.bind(this)}>
                                Entrar
                            </div>
                            <div onClick={this.handleForgotPassword.bind(this)}>
                                Esqueci minha senha
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}


export default Register;