/**
 * Created by matheusmorett on 22/08/17.
 */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Modal from 'react-modal';
import Utils from './../../utils/Utils';

import Register from './../Register';
import Login from './../Login';
import Alert from './../Alert';

import Button from 'components/Button';

import './Newsletter.scss';
import imgRegister from './../../assets/img/img-registre.png';

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        display               : 'flex',
        background            : 'none',
        boxShadow             : 'none',
        zIndex                : '999'
    }
};

export const openLogin = 1;

@inject("AuthStore", "LocationStore")
@observer
class Newsletter extends Component {

    constructor(props){
        super(props);

        this.state = {
            email : '',
            emailRight: false,
            modalIsOpen: false,
            formOpen: null,
            formError: {
                status: false,
                message: ""
            }
        }
    }

    openModal(formOpen) {

        this.setState({
            formOpen: formOpen,
            modalIsOpen: true
        });

    }

    closeModal() {

        this.setState({modalIsOpen: false});

    }

    handleInputChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        if(name === "email"){
            if(Utils.validateEmail(value) === false){
                this.setState({
                    emailRight: false
                });
            }else{
                this.setState({
                    emailRight: true
                });
            }
        }

        this.setState({
            [name]: value
        });
        this.checkFields(name);
    }


    handleInputBlur(e) {
        const name = e.target.name;
        this.checkFields(name);
    }

    checkFields(name) {
        if(this.state[name] === "" || this.state[name] === false || this.state[name] === undefined){
            this.setState({
                [name  + "Error"]: true
            });
        }else{
            this.setState({
                [name  + "Error"]: false
            });
        }
    }

    handleSubmitEmail(e) {

        e.preventDefault();
        const { LocationStore } = this.props;

       if(this.state.email !== "" && this.state.emailRight === true) {

           const params = {
               email: this.state.email,
               city: LocationStore.city
           };

           this.props.AuthStore.registerLead(params).then((response => {
               if(response.data.success === true){
                   this.setState({
                       formError: {
                           status: false,
                           message: "Email cadastrado com sucesso"
                       }
                   });
               }else{
                   this.setState({
                       formError: {
                           status: true,
                           message: response.data.data.message
                       }
                   });
               }
           })).catch(error =>{
               console.log(error);
           });
       }
    }

    closeAlert() {
        this.setState({
            formError: {
                status: false,
                message: ""
            }
        });
    }

    render() {

        const { AuthStore } = this.props;

        return (
            <section className="Grid text-center">
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal.bind(this)}
                    style={customStyles}
                    contentLabel="Cadastre-se"
                >
                    <div className="box-modal">
                        <div className="Grid Grid--gutter Grid-column">
                            <div className="Grid-cell">
                                <div className="close-modal">
                                    <p className="float-right" onClick={this.closeModal.bind(this)}>
                                        Fechar X
                                    </p>
                                </div>
                            </div>
                            <div className="Grid-cell">
                                {(this.state.formOpen === openLogin) ? <Login closeModal={this.closeModal.bind(this)} /> : <Register closeModal={this.closeModal.bind(this)} />}
                            </div>
                        </div>
                    </div>
                </Modal>
                <div className="Grid--center Grid Grid-cell">
                    <div className="newsletter">
                        <div className="wrapper">
                        <h2 className="title"> Cadastre-se e receba novidades em seu email </h2>
                        {/*<p className="description"> Cadastre-se e receba em seu e-mail as melhores aventuras para curtir com sozinho, com a família ou com a galera. </p>*/}
                        <div className="Grid  news-cad">
                            <div className="Grid Grid-cell">
                                <div className="Grid Grid-cell flex-grow-7">
                                    <div className="full-width group-input">
                                        <input className="full-width"
                                               type="text"
                                               value={this.state.email}
                                               onChange={this.handleInputChange.bind(this)}
                                               onBlur={this.handleInputBlur.bind(this)}
                                               name="email"
                                               autoComplete="off"
                                        />
                                        <span className="bar full-width"></span>
                                        <span className="help-hasError">{(this.state.emailError === true ? ' "Email" não pode ficar em branco.' : '')}</span>
                                        <span className="help-hasError">{(this.state.emailRight === false && this.state.emailError === false? ' "Email" inválido.' : '')}</span>
                                        <label style={{left: 0}}>Email</label>
                                    </div>
                                </div>
                                <div className="Grid Grid-cell">
                                    <Button
                                      onClick={this.handleSubmitEmail.bind(this)}
                                      className="btn btn-purple btn-call-action btn-inner-card"
                                      loading={AuthStore.loading}
                                    >
                                        {(AuthStore.loading ? <div><i className='fa fa-spinner fa-spin'></i></div> :
                                          <div>ENVIAR</div>)}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {(this.state.formError.message !== ""
                        ? <div className="Grid Grid-cell news-cad">
                                    <Alert formError={this.state.formError} closeAlert={this.closeAlert.bind(this)}/>
                                </div> : ""
                        )}
                        <div className="Grid Grid-cell text-center">
                            <img alt="Registrar" src={imgRegister} className="register-image"/>
                            <a className="register-message" onClick={this.openModal.bind(this)}><b className="green">Registre-se </b> agora e faça parte dessa aventura</a>
                        </div>
                     </div>
                    </div>
                </div>

            </section>
        )
    }
}
export default Newsletter;