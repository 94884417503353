/**
 * Created by matheusmorett on 22/08/17.
 */
import React, { Component } from 'react';
import Newsletter from './../Newsletter';
import {inject, observer} from 'mobx-react';
import './Footer.scss';
import FooterEnd from "../FooterEnd/FooterEnd";
import WhatsApp from 'components/WhatsApp';
import { withRouter } from 'react-router-dom';

@inject('ApplicationStore', 'CompanysStore', 'AdventuresStore')
@observer
class Footer extends Component {

    render() {
        const { ApplicationStore, CompanysStore, AdventuresStore, location } = this.props;
        let companyPhone, companyWhatsapp, document, name, cnpj, cpf;
        
        if (!!CompanysStore.Company) {
            companyWhatsapp = CompanysStore.Company.business_phone_whatsapp
            companyPhone = CompanysStore.Company.my_site_address_contact_phone.replace(/\D/g, '')

            if (CompanysStore.Company.type === 'cnpj') {
                cnpj = CompanysStore.Company.cnpj.padStart(14, '0');
                cnpj = cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');

                name = CompanysStore.Company.social_reason;
                document = 'CNPJ ' + cnpj;
            } else if (CompanysStore.Company.type === 'cpf') {
                cpf = CompanysStore.Company.cpf.padStart(11, '0');
                cpf = cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

                name = CompanysStore.Company.name;
                document = 'CPF ' + cpf;
            }
        } else if (!!AdventuresStore.Adventure) {
            companyWhatsapp = AdventuresStore.Adventure.company_whatsapp
            companyPhone = AdventuresStore.Adventure.company_phone.replace(/\D/g, '')
        }

        return (
            <section id="footer" className={`footer ${ApplicationStore.newsletter ? "" : "footer-only"} ${this.props.minimal ? "minimal" : ""}`}>
                {(ApplicationStore.newsletter) ?
                    <Newsletter/>
                    :
                    ""
                }
                {
                    this.props.minimal ? 
                        <p className="p-minimal">
                            <div className="footer-box">
                                <div className="footer-item-doc">
                                    <span>{name}</span>
                                    <span>{document}</span>
                                </div>
                                <div className="footer-item-logo">
                                    <span>Powered by <img className="logo-adrena-minimal" src="https://adrenalyze-app.s3-sa-east-1.amazonaws.com/email/logo-adrena.png" alt="Adrena.me" /></span>
                                </div>
                            </div>
                            <WhatsApp phone={companyWhatsapp && !location.pathname.includes("checkout") ? companyPhone : undefined} />
                        </p>
                    :
                    <React.Fragment>
                        <WhatsApp />
                        <FooterEnd/>
                    </React.Fragment>
                }
            </section>
        )
    }

}




export default withRouter(Footer);