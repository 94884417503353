/**
 * Created by matheusmorett on 9/26/17.
 */
import { observable, action, computed, toJS } from 'mobx';
import moment from 'moment';
import keyBy from 'lodash/keyBy';
import flattenDeep from 'lodash/flattenDeep';
import findIndex from 'lodash/findIndex';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import { PUBLIC_BUTTON, PRIVATE_BUTTON, ADVENTURE, EVENT } from 'utils/Consts';
import Api from 'utils/Api';
import { Package } from 'models/Adventure';
import Utils from 'utils/Utils';
import isEmpty from 'lodash/isEmpty';
import maxBy from 'lodash/maxBy'

const ValidateName = name => !name || name.length < 3

const ValidateEmpty = text => !text

const ValidateNumber = number => Number.isNaN(number)

const ValidatePhone = phone => !phone || phone.length !== 12

const ValidateDate = date => !date || !moment(date, 'DD/MM/YYYY').isValid() || date.length !== 10

const ValidateCPF = (value) => {
  let soma = 0, resto;
  if (value === "00000000000") return true;

  for (let i = 1; i <= 9; i++) {
    soma = soma + parseInt(value.substring(i - 1, i)) * (11 - i);
  }

  resto = (soma * 10) % 11;
  if ((resto === 10) || (resto === 11))
    resto = 0;

  if (resto !== parseInt(value.substring(9, 10)))
    return true;

  soma = 0;
  for (let i = 1; i <= 10; i++) {
    soma = soma + parseInt(value.substring(i - 1, i)) * (12 - i);
  }

  resto = (soma * 10) % 11;

  if ((resto === 10) || (resto === 11))
    resto = 0;
  if (resto !== parseInt(value.substring(10, 11)))
    return true;

  return false;
}

const ValidateEmail = email => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !re.test(String(email).toLowerCase());
}

const validatorForTypes = {
  email: ValidateEmail,
  phone: ValidatePhone,
  name: ValidateName,
  date: ValidateDate,
  text: ValidateEmpty,
  select: ValidateEmpty,
  number: ValidateNumber
}

class Reserve {
  @observable pos = 0
  @observable name = ''
  @observable nameError = ''
  @observable email = ''
  @observable emailError = ''
  @observable phone = ''
  @observable phoneError = ''
  @observable observation = ''
  @observable selected = false
  @observable pkPackage = undefined
  @observable package = undefined
  @observable fields = []

  constructor(pckg, pos = 0) {
    this.pkPackage = pckg.pkPackage
    this.package = pckg
    this.pos = pos
  }

  @action
  setName = name => {
    this.nameError = ValidateName(name)
    this.name = name
  }

  @action
  setEmail = email => {
    this.emailError = ValidateEmail(email)
    this.email = email
  }

  @action
  setObservation = observation => this.observation = observation

  @action
  setField = (v, i) => {
    const field = this.fields[i]
    const fields = [...this.fields]
    field.value = v
    fields[i] = field
    this.fields = fields
  }

  @action
  setPhone = phone => {
    this.phoneError = ValidatePhone(phone)
    this.phone = phone
  }

  isCompleted() {
    let bool = !isEmpty(this.name) && !isEmpty(this.phone) &&
      !!this.email && !this.emailError &&
      !!this.name && this.phone.length === 12 &&
      !this.fields.reduce((fieldEmpty, field) => {
        let required, validator
        required = field.required ? !field.value : false
        validator = field.validator !== undefined ? field.validator(field.value) : false
        return fieldEmpty ||
          required ||
          validator
      }, false)
    return bool
  }
}

export class CustomField {
  @observable title = ''
  @observable name = ''
  @observable type = ''
  @observable options = ''
  @observable icon = ''
  @observable value = ''
  @observable is_visible = ''
  @observable required = true
  @observable validator = undefined
  @observable pk_request_custom_fields = undefined
  @observable pk_reserve_custom_fields = undefined

  constructor(json = {}) {
    this.title = json.title ? json.title : ''
    this.name = Utils.formatUrl(this.title)
    this.type = json.type ? json.type : ''
    this.is_visible = json.is_visible ? json.is_visible : ''
    this.fk_adventure = json.fk_adventure || undefined
    this.options = json.options ? json.options : ''
    this.icon = json.icon ? json.icon : ''
    this.required = json.required !== undefined ? json.required : true
    this.validator = json.validator || validatorForTypes[json.type]
    this.pk_request_custom_fields = json.pk_request_custom_fields ? json.pk_request_custom_fields : undefined
    this.pk_reserve_custom_fields = json.pk_reserve_custom_fields ? json.pk_reserve_custom_fields : undefined
  }
}

class RequestStore {

  @observable loading = false;
  @observable isLoadingFields = false;
  @observable request = null;

  @observable schedule = {};
  @observable loadedMonths = [];
  @observable avaiableHoursCount = 0;
  @observable loadingGetSchedule = false;
  @observable loadingPackages = false;
  @observable firstLoadingGetSchedule = true;

  @observable date = null;
  @observable hour = {};
  @observable voucherSelected = false;
  @observable adventure;
  @observable hasAvailableDates = false;
  @observable availableHours;
  @observable availableVacancies = 0;
  @observable avaiablePackages = [];
  @observable token = null;
  @observable publicReserves = [];
  @observable reserves = [];
  @observable fields = [];
  @observable vouchers = [];
  @observable selectedVoucher = null;
  @observable minimalReserves = 1;
  @observable requestPackages = [];

  // Events
  @observable eventOperatings = [];
  @observable actualDateInEventCalendar;
  @observable noRequestsParams = false;

  reserveCustomFields;

  @action
  reset() {
    this.date = null
    this.hour = {}
    this.voucherSelected = false
    this.widget = false
    this.adventure = undefined
    this.hasAvailableDates = false
    this.availableHours = undefined
    this.availableVacancies = 0
    this.token = null
    this.publicReserves = []
    this.eventOperatings = []
    this.actualDateInEventCalendar = moment()
    this.request = null
    this.fields = []
    this.minimalReserves = 1;
  }

  @action
  setWidget() {
    this.widget = true
  }

  @action
  setLoading(loading) {
    this.loading = loading
  }

  @action
  resetRequest() {
    this.request = null
  }

  @action
  setNoRequestParams(val) {
    this.noRequestsParams = !!val
  }

  @action
  setFirstLoadingGetSchedule(val) {
    this.firstLoadingGetSchedule = val
  }

  @action
  getAvaiableHoursOfAdventureByDate(date, pk_adventure = undefined) {
    // if (!this.adventure) return

    const pkAdventure = pk_adventure ? pk_adventure : this.adventure.pkAdventure

    const params = {
      month: moment(date).format('MM'),
      year: moment(date).format('YYYY'),
      pkAdventure
    }

    this.loadingGetSchedule = true;
    return Api.call('/schedule-rule/get-avaiable-schedule', 'get', params)
      .then(res => {
        this.loadingGetSchedule = false
        this.firstLoadingGetSchedule = false;

        const data = res.data.data.filter(scheduleDates => {
          if (scheduleDates.availableHours.length > 0)
            this.hasAvailableDates = true
          this.avaiableHoursCount += scheduleDates.availableHours.length;
          return scheduleDates.availableHours.length > 0;
        })

        const mergeSchedule = {
          ...this.schedule,
          [pkAdventure]: {
            ...this.schedule[pkAdventure],
            ...keyBy(data, 'date')
          }
        }

        this.schedule = mergeSchedule
        this.loadedMonths = [...this.loadedMonths, `${params.year}-${params.month}`]
      })
      .catch(err => {
        this.firstLoadingGetSchedule = false;
        this.loadingGetSchedule = false;
        console.log(err)
      })
  }

  @action
  getAllHoursOfAdventureByDate(date, pk_adventure = undefined) {
    // if (!this.adventure) return

    const pkAdventure = pk_adventure ? pk_adventure : this.adventure.pkAdventure

    const params = {
      month: moment(date).format('MM'),
      year: moment(date).format('YYYY'),
      pkAdventure
    }

    this.loadingGetSchedule = true;
    return Api.call('/schedule-rule/get-all-schedule', 'get', params)
      .then(res => {
        this.loadingGetSchedule = false
        this.firstLoadingGetSchedule = false;

        const data = res.data.data.filter(scheduleDates => {
          scheduleDates.availableHours = scheduleDates.availableHours.filter(hour =>
            !hour.hasRequest && ((this.adventure && moment(`${scheduleDates.date} ${hour.start}`).diff(moment(), 'hour') >= this.adventure.block_request_hours_before) || pk_adventure)
          )
          this.avaiableHoursCount += scheduleDates.availableHours.length;
          return scheduleDates.availableHours.length > 0;
        })

        const mergeSchedule = {
          ...this.schedule,
          [pkAdventure]: {
            ...this.schedule[pkAdventure],
            ...keyBy(data, 'date')
          }
        }

        this.schedule = mergeSchedule
      })
      .catch(err => {
        this.firstLoadingGetSchedule = false;
        console.log(err)
      })
  }

  @action
  setAdventure(adventure) {
    this.adventure = adventure;
    if (this.adventure.requests.length === 0)
      this.getAdventureRequests();
    //this.selectFirstPackage();
    this.setPublicReserves();
    this.avaiablePackages = adventure.packages
  }

  @action
  getAdventureRequests(pk_adventure) {
    if (this.adventure) {
      Api.call('/request/get-adventure-requests', 'get', { pk_adventure: this.adventure.pkAdventure })
        .then(res => {
          if (res.data) {
            this.adventure.requests = res.data.data
          }
        })
        .catch(err => {
          console.log(err)
        })
    } else if (pk_adventure) {
      return Api.call('/request/get-adventure-requests', 'get', { pk_adventure })
        .then(res => {
          if (res.data)
            return res.data.data

          return [];
        })
        .catch(err => {
          console.log(err)
          return [];
        })
    }
  }

  @action
  getCustomFields(noDefaults = false, onCheckout = false) {
    if (!this.adventure) {
      return new Promise((_res, rej) => rej(false))
    }

    this.isLoadingFields = true;
    if (onCheckout) {
      this.reserveCustomFields = []
    } else {
      this.fields = []
    }
    return Api.call('/adventure/get-request-custom-fields', 'get', { pkAdventure: this.adventure.pkAdventure })
      .then(res => {
        if (res.data) {
          const fields = res.data.data.map(item => new CustomField(item))
          if (!noDefaults) {
            fields.unshift(new CustomField({
              title: 'Celular',
              type: 'phone',
              icon: 'icon-phone-handset'
            }))
            fields.unshift(new CustomField({
              title: 'Email',
              type: 'email',
              icon: 'icon-envelope'
            }))
            fields.unshift(new CustomField({
              title: 'Nome completo',
              validator: ValidateName,
              type: 'text',
              icon: ''
            }))

            if (this.adventure.request_observation_enabled) {
              fields.push(new CustomField({
                title: 'Observação',
                type: 'textarea',
                validator: () => false,
                icon: ''
              }))
            }
          }

          if (this.adventure.reserve_roca_enabled) {
            fields.push(new CustomField({
              title: 'CPF',
              type: 'cpf',
              fk_adventure: this.adventure.pkAdventure || this.adventure.pk_adventure,
              validator: (value) => value.length !== 11 || ValidateCPF(value),
              icon: ''
            }))

            fields.push(new CustomField({
              title: 'Data de Nascimento',
              type: 'birthday',
              fk_adventure: this.adventure.pkAdventure || this.adventure.pk_adventure,
              validator: ValidateDate,
              icon: ''
            }))

            fields.push(new CustomField({
              title: 'Estrangeiro',
              type: 'estrangeiro',
              fk_adventure: this.adventure.pkAdventure || this.adventure.pk_adventure,
              validator: (value) => !value,
              icon: ''
            }))

            fields.push(new CustomField({
              title: 'Nacionalidade',
              type: 'nacionalidade',
              fk_adventure: this.adventure.pkAdventure || this.adventure.pk_adventure,
              validator: (value) => !value,
              icon: ''
            }))

            fields.push(new CustomField({
              title: 'Passaporte (Apenas estrangeiros)',
              type: 'passaporte',
              required: false,
              fk_adventure: this.adventure.pkAdventure || this.adventure.pk_adventure,
              validator: () => false,
              icon: ''
            }))
          }

          if (onCheckout) {
            this.reserveCustomFields = fields;
          } else {
            this.fields = fields;
          }
          this.isLoadingFields = false;
          return fields
        }
      })
  }

  @action
  setReserveCustomField(v, i) {
    this.selectedReserve.reserve.fields[i].value = v
  }

  @action
  getReserveCustomFields() {
    this.isLoadingFields = true;

    if (!this.adventure) {
      this.isLoadingFields = false;
      return new Promise((_res, rej) => rej(false))
    }

    this.reserveCustomFields = []
    return Api.call('/adventure/get-reserve-custom-fields', 'get', { pkAdventure: this.adventure.pkAdventure })
      .then(res => {
        let fields = []
        if (res.data) {
          fields = res.data.data.map(item => ({ ...item, name: Utils.formatUrl(item.title), value: '' }))
        }

        if (this.adventure.reserve_roca_enabled) {
          fields.push(new CustomField({
            title: 'CPF',
            type: 'cpf',
            fk_adventure: this.adventure.pkAdventure || this.adventure.pk_adventure,
            validator: (value) => value.length !== 11 || ValidateCPF(value),
            icon: ''
          }))

          fields.push(new CustomField({
            title: 'Data de Nascimento',
            type: 'birthday',
            fk_adventure: this.adventure.pkAdventure || this.adventure.pk_adventure,
            validator: ValidateDate,
            icon: ''
          }))

          fields.push(new CustomField({
            title: 'Estrangeiro',
            type: 'estrangeiro',
            fk_adventure: this.adventure.pkAdventure || this.adventure.pk_adventure,
            validator: (value) => !value,
            icon: ''
          }))

          fields.push(new CustomField({
            title: 'Nacionalidade',
            type: 'nacionalidade',
            fk_adventure: this.adventure.pkAdventure || this.adventure.pk_adventure,
            validator: (value) => !value,
            icon: ''
          }))

          fields.push(new CustomField({
            title: 'Passaporte (Apenas estrangeiros)',
            type: 'passaporte',
            required: false,
            fk_adventure: this.adventure.pkAdventure || this.adventure.pk_adventure,
            validator: () => false,
            icon: ''
          }))
        }

        this.reserveCustomFields = fields;
        this.isLoadingFields = false;
        return fields
      })
      .catch(err => {
        console.log(err)
        this.isLoadingFields = false;
      })
  }

  @action
  handleChangeField(value, i) {
    const field = this.fields[i]
    field.value = value
    this.fields[i] = field
  }

  @action
  setPublicReserves() {
    this.publicReserves = this.adventure.requests.filter((r) => parseFloat(r.type) === PUBLIC_BUTTON && r.person < this.adventure.max);
    this.publicReserves = this.publicReserves.map((pr) => {
      const max = (pr.max) ? pr.max : this.adventure.max;
      pr.vancancies = max - pr.person;
      return pr;
    });
    this.publicReserves = sortBy(this.publicReserves, (pr) => {
      return pr.date;
    });
  }

  @action
  setDate(date) {
    this.date = date;
  }

  @action
  setVoucherSelected(checked) {
    this.voucherSelected = checked
  }

  @action
  buildVouchers(data) {
    this.vouchers = data
    this.selectedVoucher = this.vouchers[0]
    this.adventure = this.selectedVoucher.adventure
  }

  @action
  selectVoucher(voucher) {
    this.selectedVoucher = voucher
  }


  @action
  removeDate() {
    this.date = null;
  }

  @action
  setHour(hour, date = null, setPackages = true) {
    this.hour = hour;

    if (date === null)
      date = this.date;

    const allRequestsInHour = this.getAllRequestsInHour(hour, date);
    const allPeople = allRequestsInHour.reduce((prevVal, r) => prevVal + r.person, 0);

    if (this.adventure) {
      if (setPackages)
        this.setHourPackages(hour);

      this.availableVacancies = this.adventure.max - allPeople;
      if (this.availableVacancies === this.adventure.max) {
        this.availableVacancies = 0;
      }
    }
  }

  @action
  setHourPackages(hour) {
    const { pk_schedule_rule } = hour
    this.loadingPackages = true;
    const request = this.getCurrentRequest();
    const data = !!request && request.pk_request ? { pk_request: request.pk_request } : { pk_schedule_rule }
    return this.getRequestPackages(data, true, this.isPaymentForRequest)
  }

  @computed
  get isPaymentForRequest() {
    const request = this.getCurrentRequest();
    return !!this.adventure.payment_on_request && !Object.keys(request).length
  }

  @action
  getRequestPackages(data, selectFirst = true, isForRequest = false) {
    this.loadingPackages = true;
    const { pkAdventure } = this.adventure

    if ((!this.isPaymentForRequest || !isForRequest) ||
      (this.isPaymentForRequest && (this.adventure.packageForRequest === undefined || this.adventure.packageForRequest === '') && this.adventure.useScheduleRulePackages)) {
      return Api.call('/schedule-rule/avaiable-packages-for-hour', 'get', { ...data, voucherSelected: this.voucherSelected ? this.voucherSelected : undefined, pkAdventure })
        .then(res => {
          if (res.data) {
            //this.adventure.packages = res.data.data.map(pck => new Package(pck))
            this.avaiablePackages = res.data.data.map(pck => new Package(pck))
            if (selectFirst)
              this.selectFirstPackage(this.adventure.request_payment_min);
          }
          this.loadingPackages = false;
        })
    } else {
      return new Promise((resolve, reject) => {
        if (this.adventure.packageForRequest !== undefined) {
          this.avaiablePackages = [this.adventure.packageForRequest]
        } else {
          this.avaiablePackages = []
        }

        if (selectFirst) {
          this.selectFirstPackage(this.adventure.request_payment_min);
        }

        this.loadingPackages = false;
        resolve()
      })
    }
  }

  @action
  setRequestDateHour() {
    const { date } = this.request

    if (!date) return

    this.setDate(moment(date))

    this.setHour({ start: moment(this.date).format('HH:mm') })
  }

  @action
  removeHour() {
    this.hour = {};
  }

  @action
  getRequestPeople(adventure, hour) {
    const allRequestsInHour = this.getAllRequestsInHour(hour, Utils.getOnlyDate(this.date), adventure);
    return allRequestsInHour.reduce((prevVal, r) => prevVal + r.person, 0);
  }

  @action
  getAvailableVacancies() {

    if (this.adventure) {
      let allRequestsInHour = this.getAllRequestsInHour(this.hour, Utils.getOnlyDate(this.date), this.adventure);

      if (allRequestsInHour.length === 0 && this.request) {
        allRequestsInHour = [this.request]
      }

      const max = (!!allRequestsInHour[0] && !!allRequestsInHour[0].max) ? allRequestsInHour[0].max : this.adventure.max;
      const allPeople = allRequestsInHour.reduce((prevVal, r) => prevVal + r.person, 0);

      this.availableVacancies = max - allPeople;

      return this.availableVacancies;
    }

    return 0;
  }

  @action
  getVacanciesInHour(adventure, hour) {
    const allRequestsInHour = this.getAllRequestsInHour(hour, Utils.getOnlyDate(this.date), adventure);
    const max = (!!allRequestsInHour[0] && !!allRequestsInHour[0].max) ? allRequestsInHour[0].max : adventure.max;
    const allPeople = allRequestsInHour.reduce((prevVal, r) => prevVal + r.person, 0);

    return max - allPeople;
  }

  @action
  getVacanciesInDate(date, time) {
    const allRequestsInHour = this.getAllRequestsInHour(time, date, this.adventure);

    if (allRequestsInHour.length === 0) {
      return this.adventure.max;
    }
    const max = (!!allRequestsInHour[0].max) ? allRequestsInHour[0].max : this.adventure.max;

    const allPeople = allRequestsInHour.reduce((prevVal, r) => prevVal + r.person, 0);
    return max - allPeople;
  }

  @action
  getIsDatePrivate(date, time) {
    const allRequestsInHour = this.getAllRequestsInHour(time, date, this.adventure);
    if (allRequestsInHour.length === 0) {
      return !this.adventure.only_public;
    }

    return allRequestsInHour[0].type === PRIVATE_BUTTON
  }

  @action
  getAllRequestsInDate(date) {
    return this.adventure.requests.filter((r) => {
      return (moment(r.date).isSame(moment(date), 'day'))
    });
  }

  @action
  getAllPerclusionsInDate(date) {
    return this.adventure.preclusions.filter((p) => {
      return moment(p.start).format().split("T")[0] === date.format().split("T")[0];
    });
  }

  @action
  getAllHoursInDate(date) {

    //verificar quais horarios estão disponiveis em todas as operações de acordo com a aventura passada
    //console.log(this.adventure);
    const adventureOperating = this.adventure.scheduleRules
      .reduce((acumm, scheduleRule) => {
        if (
          // data selecioanda deve estar depois do start da operação
          (date.isAfter(moment(scheduleRule.startDate)) || moment(scheduleRule.startDate).format("YYYY-MM-DD") === date.format("YYYY-MM-DD"))
          &&
          (!scheduleRule.endDate ||
            (date.isBefore(moment(scheduleRule.endDate)) ||
              // data selecioanda deve estar antes do end da operação, se existir
              moment(scheduleRule.endDate).format("YYYY-MM-DD") === date.format("YYYY-MM-DD")
            )
          )
          &&
          // se a data passada é de um dia da semana que existe operação
          (!!scheduleRule.weekdays.find(day => day === date.day()))
        ) // se a data passada não está desabilitada
        {
          acumm.push({
            pk_schedule_rule: scheduleRule.pk_schedule_rule,
            start: scheduleRule.hour
          }
          )
        }

        return acumm
      }, [])


    const requestsOfAdventureAndDate = this.adventure.requests
      .filter(request => Utils.isSameDate(request.date, date) && parseInt(request.type) === parseInt(PRIVATE_BUTTON))
      .map(request => ({
        fk_rule_time: request.fk_rule_time,
        fk_rule_time_operation: request.fk_rule_time_operation,
        date: moment(request.date).format('LT')
      }))

    return flattenDeep(adventureOperating)
      .filter(availableHour => {
        return findIndex(requestsOfAdventureAndDate, request =>
          request.fk_rule_time_operation === availableHour.pk_rule_time_operation
        ) === -1
      })

  }

  getCurrentRequest() {
    if (this.request) {
      return this.request
    }
    const { hour } = this;

    const requests = this.getAllRequestsInHour(hour.value ? { start: hour.value } : { start: hour.start }, Utils.getOnlyDate(this.date), this.adventure)
    if (requests.length > 0) {
      return requests[0]
    } else {
      return {}
    }
  }

  getAllRequestsInHour(hour, date, adventure = undefined) {
    if (hour && adventure !== undefined) {
      return adventure.requests.filter(r => {
        return moment(`${date} ${hour.start}:00`).isSame(moment(r.date));
      })
    } else if ((hour && this.adventure)) {
      return this.adventure.requests.filter((r) => {
        return moment(`${date}`).isSame(moment(r.date), 'day');
      });
    } else {
      return [];
    }
  }

  @action
  handleSetHour(params) {
    if (!!params) {
      params = {
        start: params.name,
        value: params.value,
        pk_schedule_rule: params.pk_schedule_rule
      };
    }
    this.setHour(params);
  }

  @action
  handleVerifyIfDateIsAvailable(date) {
    const allHoursInDate = this.getAllHoursInDate(date);
    return !(allHoursInDate.length > 0);
  }

  getFirstOperatingDate() {
    const operatings = this.adventure.operatings;
    const earliest = operatings.reduce((pre, cur) => {
      if (pre === 0) {
        return cur
      }
      return Date.parse(pre.start_date) > Date.parse(cur.start_date) ? cur : pre;
    }, 0);
    if (moment(earliest.start_date) < moment()) {
      return moment();
    }
    return moment(earliest.start_date);
  }

  @action
  insertRequest(params) {
    this.loading = true;
    return Api.call('/request', 'post', params).then((response) => {
      this.request = response.data.data;
      this.loading = false;
      return response.data;
    });
  }

  @action
  getRequestByReserveToken(params) {
    this.loading = true;

    return Api.call('request/get-request-by-reserve-token', 'get', params)
      .then((response) => {
        if (response && response.data !== null && response.data !== "") {
          this.request = response.data.data;
        }
        this.loading = false;
        this.setRequestDateHour()
        return response.data;
      })
      .catch(() => {
        this.loading = false;
      });
  }

  @action
  submitRequest(params) {
    this.loading = true;

    return Api.call('request/create-request', 'post', params, false, true).then(res => {
      if (res.data !== null && res.data !== "") {
        this.request = res.data.data;
        this.loading = false;
      }

      return res.data;
    }).catch(err => {
      this.loading = false;
      throw err;
    })
  }

  //@computed
  //get canAddPackage(){
  @action
  canAddPackage(pkPackage) {
    let packageVacancies, requestVacancies;

    if (this.voucherSelected)
      return true

    const pck = this.avaiablePackages.find((p) => p.pkPackage === pkPackage);


    if (this.isPaymentForRequest) {
      requestVacancies = this.adventure.request_payment_max
    } else {
      requestVacancies = this.availableVacancies
    }

    if (pck.max) {
      packageVacancies = pck.max - pck.reserves.length
    }

    const addedPackages = this.avaiablePackages.reduce((acum, pack) => acum + Number(pack.quantity), 0)

    if (requestVacancies <= addedPackages)
      return false;

    if (packageVacancies <= pck.quantity)
      return false;

    return true
  }

  @action
  addPackage(pkPackage, quantity = 1) {
    if (!pkPackage || !this.canAddPackage(pkPackage))
      return false;

    const packages = this.avaiablePackages
    const p = packages.find((p) => p.pkPackage === pkPackage);
    const index = packages.findIndex((p) => p.pkPackage === pkPackage);

    if (p.quantity >= 1) {
      p.quantity += quantity;
    } else {
      p.quantity = quantity ?? 1;
    }
    packages.splice(index, 1, p);
    this.avaiablePackages = [...packages]
  }

  verifyIfCanAddMorePackage(pkPackage) {
    if (this.adventure.type === ADVENTURE) {
      return true;
    } else {
      const otherSelectedPackage = this.avaiablePackages.find((p) => (p.pkPackage !== pkPackage && p.quantity > 0));
      return !otherSelectedPackage;
    }
  }

  verifyIfPackageHasLimit(pkPackage) {
    if (this.adventure.type === ADVENTURE) {
      return true;
    } else {
      const packageSelected = this.avaiablePackages.find((p) => (p.pkPackage === pkPackage && p.quantity > 0));
      if (packageSelected)
        return (packageSelected.max > packageSelected.quantity);

      return true;
    }
  }

  verifyIfPackageIsSelected(pkPackage) {
    return !!this.avaiablePackages.find((p) => (p.pkPackage === pkPackage && p.quantity > 0 && parseInt(p.hasTeam) === 1));
  }

  getPackageSelected() {
    return this.avaiablePackages.find((p) => (p.quantity > 0));
  }

  @computed
  get canRemovePackage() {
    const packages = this.avaiablePackages
    const limit = this.isPaymentForRequest ? this.adventure.request_payment_min : 0
    const package_count = packages.reduce((sum, pack) => sum + Number(pack.quantity), 0)

    return limit < package_count
  }

  @computed
  get packagesCount() {
    return this.avaiablePackages.reduce((sum, pack) => sum + Number(!!pack && pack.quantity), 0)
  }

  @action
  removePackage(pkPackage) {
    if (!this.canRemovePackage) return false;

    const index = this.avaiablePackages.findIndex(item => item.pkPackage === pkPackage)

    if (index === -1) return false;

    const p = this.avaiablePackages[index]

    if (p.quantity) {
      p.quantity -= 1;
    } else {
      p.quantity = 0;
    }

    // if (p.quantity === 0) {
    //   this.selectedTeam = undefined;
    // }

    this.avaiablePackages.splice(index, 1, p);
    this.avaiablePackages = [...this.avaiablePackages]
  }

  @computed
  get sumedPrice() {
    if (this.adventure) {
      const price = this.avaiablePackages.reduce((prevVal, p) => {
        if (p.quantity) {
          let val;
          if (!!p.priceWithDiscount) {
            val = p.quantity * p.priceWithDiscount;
          } else {
            val = p.quantity * p.price;
          }
          return prevVal + val;
        }
        return prevVal;
      }, 0).toFixed(2);

      return price;
    } else {
      return 0;
    }
  }

  getPackageQuantity() {

    if (this.adventure) {
      const price = this.avaiablePackages.reduce((prevVal, p) => {
        if (p.quantity) {
          let val;
          if (!!p.priceWithDiscount) {
            val = p.quantity * p.priceWithDiscount;
          } else {
            val = p.quantity * p.price;
          }
          return prevVal + val;
        }
        return prevVal;
      }, 0).toFixed(2);

      return price;
    } else {
      return 0;
    }

  }

  getPersonQuantity() {
    return this.avaiablePackages.reduce((prevVal, p) => {
      if (p.quantity) {
        const val = p.quantity + prevVal;
        return val;
      }
      return prevVal;
    }, 0)

  }

  @action
  setToken(token) {
    this.token = token;
  }

  @action
  validateVancancies() {
    let totalNow = parseInt(this.selectedTeam.payed);
    const packageSelected = this.getPackageSelected();
    totalNow += packageSelected.quantity;
    if (this.selectedTeam.max < totalNow) {
      return false;
    }
    return true;
  }

  @action
  canAdvance() {
    if (this.adventure.type === ADVENTURE) {
    } else {
      const packageSelected = this.getPackageSelected();
      if (packageSelected.hasTeam === 0) {
        return true;
      } else if (this.selectedTeam && this.selectedTeam.validate === true) {
        return true;
      }
      return false;
    }
  }

  @action
  selectFirstPackage(min = 1) {
    const defaultPackage = this.avaiablePackages.find((p) => !!p && parseInt(p.default) === 1);
    const maxPackage = this.avaiablePackages.length > 0 && maxBy(this.avaiablePackages, 'price')
    if (defaultPackage && !defaultPackage.quantity) {
      this.addPackage(defaultPackage.pkPackage, min)
    } else if (maxPackage && !maxPackage.quantity) {
      this.addPackage(maxPackage.pkPackage, min)
    }
  }

  @action
  selectReserve(reserve = undefined) {
    if (!reserve) {
      reserve = this.reserves[0] ? this.reserves[0] : {}
    }
    this.reserves.forEach(reserve => reserve.selected = false)
    if (reserve.fields && reserve.fields.length === 0 && this.reserveCustomFields && this.reserveCustomFields.length > 0) {
      reserve.fields = cloneDeep(this.reserveCustomFields)
    }
    reserve.selected = true
  }

  @action
  selectNextReserve() {
    const reserve = this.reserves.find(resv => !resv.isCompleted())
    this.selectReserve(reserve)
  }

  @computed
  get selectedReserve() {
    const index = this.reserves.findIndex(r => r.selected)
    return index !== -1 ? { reserve: this.reserves[index], index } : { reserve: {}, index: undefined }
  }

  @computed
  get reservesCompleted() {
    return this.reserves &&
      this.reserves.reduce((completed, reserve) => reserve.isCompleted() ? completed + 1 : completed, 0) >= this.minimalReserves
  }

  @action
  resetReserves() {
    this.reserves = []
    this.minimalReserves = 1
  }

  @action
  handleSelectPackage(packs) {
    this.avaiablePackages = this.avaiablePackages.map((p) => {
      if (packs.length) {
        const pck = packs.find(item => item.pkPackage === p.pkPackage)
        if (pck) {
          p.quantity = pck.quantity;
        }
      } else {
        if (p.pkPackage === packs.pkPackage) {
          p.quantity = 1;
        } else {
          p.quantity = 0;
        }
      }

      for (let i = 0; i < p.quantity; i++) {
        let reserve = new Reserve(p, this.reserves.length);
        if (i === 0) reserve.selected = true;

        this.reserves = [...this.reserves, reserve]
        if (this.adventure.fill_all_reserves)
          this.minimalReserves = this.reserves.length
      }

      return p;
    });
  }

  @action
  toggleFillReservesLater(fill) {
    // if (fill) {
    //   this.minimalReserves = 1
    // } else {
    //   this.minimalReserves = this.reserves.length
    // }
  }

  addCountToRequestView(pk_request) {
    return Api.call('request/add-count-request-view', 'post', { pk_request })
      .then(res => true)
      .catch(err => console.log(err))
  }

  @computed
  get isDateSelected() {
    if (this.date instanceof Date) return true
    return !(this.date === "" || this.date === undefined || this.date === null || Object.keys(this.date).length === 0)
  }

  @computed
  get isHourSelected() {
    return !(this.hour === "" || this.hour === undefined || this.hour === null || Object.keys(this.hour).length === 0)
  }

  @computed
  get getSelectedPackage() {
    const selectedPackage = this.avaiablePackages.find((p) => p.quantity > 0);
    if (!selectedPackage)
      return this.avaiablePackages.find(p => p.default) || this.avaiablePackages[0];
    return selectedPackage;
  }

  @computed
  get adventureType() {
    if (this.avaiableHoursCount <= 5 && this.avaiableHoursCount > 0) {
      this.makeEventOperatings()
      return EVENT;
    } else {
      return ADVENTURE;
    }
  }

  getEventOperatingsInMonth(baseDate) {
    const params = {
      month: moment(baseDate).format('MM'),
      year: moment(baseDate).format('YYYY'),
      pkAdventure: this.adventure.pkAdventure,
    }
    return Api.call('/schedule-rule/get-avaiable-schedule', 'get', params)
      .then(res => {
        const schedules = res.data.data;
        // Para cada dia do mes
        const events = schedules.map(schedule => {
          const { date, availableHours } = schedule;
          // Se tem um horario disponivel
          if (availableHours.length > 0) {
            // E o horario de inicio esta no intervalo permitido da aventura
            if (moment(`${date} ${availableHours[0].start}`).diff(moment(), 'hour') >= this.adventure.block_request_hours_before) {
              // Mostra o horario
              return {
                startDate: date,
                scheduleRules: availableHours
              }
            }
          }
          // Apaga o horario
          return null;
        }).filter(item => item !== null);
        if (events.length > 0) this.concatEvents(events);

      })
      .catch(err => console.log(err))

    // times(date.daysInMonth(), (n) => {
    //   if (date.isAfter(moment())) {
    //     const hours = this.getAllHoursInDate(date);

    //     if (hours.length > 0) {
    //       if (moment(`${date.format("YYYY-MM-DD")} ${hours[0].start}`).diff(moment(), 'hour') >= this.adventure.block_request_hours_before) {
    //         newOperatings.push({
    //           startDate: date.format("YYYY-MM-DD"),
    //           scheduleRules: [hours]
    //         })
    //       }
    //     }
    //   }

    //   date.add(1, 'day');
    // });

    // return newOperatings;
  }

  // Funcao para concatenar os arrays de datas
  @action
  concatEvents(events) {
    const operatings = toJS(this.eventOperatings)
    sortBy(Utils.mergeObjectArrays(operatings, events, 'startDate'), o => o.startDate);
    this.eventOperatings = operatings
  }

  @action
  makeEventOperatings() {
    const date = moment().startOf('month');
    //this.getEventOperatingsInMonth(date)

    const nextDate = moment().startOf('month').add('1', 'month');
    //this.getEventOperatingsInMonth(nextDate)

    const nextNextDate = moment().startOf('month').add('2', 'months');
    //this.getEventOperatingsInMonth(nextNextDate)

    const nextNextNextDate = moment().startOf('month').add('3', 'months');
    //this.getEventOperatingsInMonth(nextNextNextDate)

    //this.actualDateInEventCalendar = nextDate;
  }

  @action
  handleGetMoreEventDates() {
    let newOperatings = [];

    if (!this.actualDateInEventCalendar) {
      this.actualDateInEventCalendar = moment()
    }

    const date = this.actualDateInEventCalendar.add('1', 'month');
    newOperatings = newOperatings.concat(this.getEventOperatingsInMonth(date))

    const nextDate = date.add('1', 'month');
    newOperatings = newOperatings.concat(this.getEventOperatingsInMonth(nextDate))
    this.actualDateInEventCalendar = nextDate;

    //this.eventOperatings = this.eventOperatings.concat(newOperatings);
  }

  @action
  resetEvent() {
    this.eventOperatings = []
  }

}

export default new RequestStore();
