/**
 * Created by matheusmorett on 27/03/18.
 */
import {observable, action} from 'mobx';
import Api from "utils/Api";

class ApplicationStore {

    // Footer
    @observable footer = true;
    @observable newsletter = false;

    // Menu
    @observable menu = true;
    @observable menuBorder = false;
    @observable menuBackgroundWhite = false;
    @observable menuSearchButton = false;
    @observable menuRegister = true;
    @observable menuStyle = {};
    @observable menuTiny = false;
    @observable menuMobileOpen = false;

    // Login - Register
    @observable modal = false;
    @observable modalRegister = false;
    @observable modalLogin = false;
    @observable modalForgotPassword = false;
    @observable modalNotClosable = false;
    @observable reloadPageAfterLogin = true;

    // Page
    @observable pageBackground;
    @observable isMobile;
    @observable minimalTemplate = false;
    @observable tag_code;


    // Navigation
    @observable history;
    @observable redirect = {
        status: false,
        page: ""
    };

    constructor() {
        this.isMobile = (window.innerWidth <= 479);
        window.addEventListener("resize", this.updateDimensions);
    }

    @action
    setRedirect(params) {
        this.redirect = params;
    }

    @action
    setTagCode(code) {
        this.tag_code = code
    }
    
    @action
    setHistory(history) {
        this.history = history;
    }

    @action
    setReloadPageAfterLogin() {
        this.reloadPageAfterLogin = true;
    }

    @action
    updateDimensions() {
        this.isMobile = (window.innerWidth <= 479);
    }

    @action
    setNewsletter() {
        this.newsletter = true;
    }

    @action
    setMinimalTemplate(withScroll = false) {
        this.minimalTemplate = true;
        if(!withScroll)
            document.body.classList.add('no-scrollbar')
    }

    @action
    unsetMinimalTemplate() {
        this.minimalTemplate = false;

        document.body.classList.remove('no-scrollbar')
    }

    @action
    unsetNewsletter() {
        this.newsletter = false;
    }

    @action
    setFooter() {
        this.footer = true;
    }

    @action
    unsetFooter() {
        this.footer = false;
    }


    @action
    setMenu() {
        this.menu = true;
    }
    
    unsetMenu() {
        this.menu = false;
    }

    @action
    setMenuBorder() {
        this.menuBorder = true;
    }

    @action
    unsetMenuBorder() {
        this.menuBorder = false;
    }

    @action
    setMenuBackgroundWhite() {
        this.menuBackgroundWhite = true;
    }

    @action
    unsetMenuBackgroundWhite() {
        this.menuBackgroundWhite = false;
    }

    @action
    setMenuSearchButton() {
        this.menuSearchButton = true;
    }

    @action
    unsetMenuSearchButton() {
        this.menuSearchButton = false;
    }

    @action
    setMenuRegister() {
        this.menuRegister = true;
    }

    @action
    unsetMenuRegister() {
        this.menuRegister = false;
    }

    @action
    setMenuStyle(params) {
        this.menuStyle = params;
    }

    @action
    resetMenuStyle() {
        this.menuStyle = {};
    }

    @action
    openModalLogin() {
        this.modal = true;
        this.modalLogin = true;
        this.modalRegister = false;
        this.modalForgotPassword = false;
    }

    @action
    closeModalLogin() {
        this.modal = false;
        this.modalLogin = false;
        this.modalRegister = false;
        this.modalForgotPassword = false;
    }

    @action
    openModalRegister() {
        this.modal = true;
        this.modalLogin = false;
        this.modalForgotPassword = false;
        this.modalRegister = true;
    }

    @action
    openModalForgotPassword() {
        this.modal = true;
        this.modalForgotPassword = true;
        this.modalLogin = false;
        this.modalRegister = false;
    }

    @action
    closeModal() {
        if(this.modalNotClosable === false) {
            this.modal = false;
        }
    }

    @action
    checkCookieAcceptance(ip) {
        return Api.call('/preference/cookies-acceptance', 'get', { ip })
        .then((res) => { 
            if(res.data)
                return res.data
            
            return false
        }).catch(err => {
            return false
        })
    }

    @action
    acceptCookies(ip) {
        return Api.call('/preference/accept-cookies', 'post', { ip })
        .then((res) => { 
            if(res.data)
                return res.data
            
            return false
        }).catch(err => {
            return false
        })
    }

    @action
    setPageBackground(background) {
        this.pageBackground = background;
    }

    @action
    unsetPageBackground() {
        this.pageBackground = null;
    }

    @action
    setMenuTiny() {
        this.menuTiny = true;
    }

    @action
    unsetMenuTiny() {
        this.menuTiny = false;
    }

    @action
    setMenuMobileOpen(menuMobileOpen) {
        this.menuMobileOpen = menuMobileOpen;
    }

    @action
    setModalNotClosable() {
        this.modalNotClosable = true;
    }

    @action
    unsetModalNotClosable() {
        this.modalNotClosable = false;
    }

}

export default new ApplicationStore();

