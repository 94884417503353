/**
 * Created by matheusmorett on 14/12/17.
 */
export function _env() {
  const location = window.location.href;
  if (location.includes('hero99')) {
    return 'homolog';
  }

  if (location.includes('dev')) {
    return 'local';
  }

  if (location.includes('localhost')) {
    return 'local';
  }

  if (location.includes('adrenalyze.com.br') || location.includes('adrena.me')) {
    return 'prod';
  }
  return process.env.NODE_ENV;
}

const apiUrl = () => {
  if (_env() === 'prod') {
    return 'https://api.adrenalyze.com.br/';
  } else if (_env() === 'homolog') {
    return 'https://api.hero99.adrenalyze.com.br/';
  } else if (_env() === 'local') {
    return 'http://localhost:8090/api/web/';
    // return 'http://0.0.0.0:8090/api/web/v1/';
    // return 'http://dev.back.adrenalyze.com.br/';
    // return 'https://api.hero99.adrenalyze.com.br/';
  }
  return 'https://letsrock.hero99.com.br/clientes/adrenalyze-back/api/web/';
};

export const API_URI = apiUrl();
