import React from 'react'
import Card from 'components/Card';
import Utils from 'utils/Utils'

const CookiesWarning = () => {
    const browser = Utils.getBrowser()
    const help = Utils.getCookiesHelp(browser)
    return (
        <div className="text-center">
            <h2>Cookies bloqueados!</h2>
            <p>Esta ferramenta usa cookies para funcionar, por favor permita o uso de cookies para este site em seu navegador.</p>
            {help ?
            <p>Veja como liberar cookies no <a style={{ textDecoration: "underline" }} href={help} target="_blank" rel="noopener noreferrer">{browser}</a></p>
            :
            <p>Não conseguimenos identificar seu navegador, procure nas suas preferencias de privacidade para liberar os cookies</p>
            }
        </div>
    )
}

export default CookiesWarning
