/**
 * Created by matheusmorett on 16/08/17.
 */
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Helmet } from "react-helmet";
import AsyncComponent from './../../components/Hocs/AsyncComponent';

import 'normalize.css';
import "react-placeholder/lib/reactPlaceholder.css";
import 'assets/stylesheets/grid.scss';
import 'assets/stylesheets/Linearicons.scss';
import 'assets/stylesheets/fonts.scss';
import 'assets/stylesheets/layout.scss';

import 'assets/stylesheets/site-theme.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import { ToastContainer } from 'react-toastify';
/* Login */
import Modal from 'react-modal';
import { MODAL_STYLES } from 'utils/Consts';
import Register from "components/Register";
import ForgotPassword from "components/ForgotPassword";
import Login from "components/Login";
import CookiesWarning from "components/CookiesWarning";
import Script from 'react-load-script'

const CalendarWidget = AsyncComponent(() =>
    import
        ("modules/Adventure/containers/CalendarWidget").then(module => module.default)
);

const RequestWidget = AsyncComponent(() =>
    import
        ("modules/Adventure/containers/RequestWidget").then(module => module.default)
);

const TableWidget = AsyncComponent(() =>
    import
        ("modules/Adventure/containers/TableWidget").then(module => module.default)
);

const SingleWidget = AsyncComponent(() =>
    import
        ("modules/Adventure/containers/SingleWidget").then(module => module.default)
);

const Checkout = AsyncComponent(() =>
    import
        ("modules/Checkout/containers/Checkout").then(module => module.default)
);

const OtherPayment = AsyncComponent(() =>
    import
        ("modules/Checkout/containers/OtherPayment").then(module => module.default)
);

@inject("AuthStore", "ApplicationStore")
@observer
class SiteTemplate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: false,
            cookieBlocked: false
        };
    }

    componentDidMount() {
        const { history, ApplicationStore } = this.props;
        ApplicationStore.setHistory(history);
        ApplicationStore.setMinimalTemplate();

        // Checa se cookies estao bloqueados
        try {
            localStorage.getItem("user");
        } catch (error) {
            this.setState({ cookieBlocked: true })
        }
    }

    handleCloseModal() {
        const { ApplicationStore } = this.props;
        ApplicationStore.closeModal();
    }

    handleGoToPage(page) {
        const { history } = this.props;
        history.push(page);
    }

    getBasename() {
        if (window.location.href.includes('letsrock')) {
            return "clientes/adrenalyze-web/";
        } else if (window.location.href.includes('adrena.me')) {
            return "";
        } else if (window.location.href.includes('localhost')) {
            return "";
        } else {
            return "clientes/adrenalyze-web/";
        }
    }

    render() {
        const { ApplicationStore } = this.props;

        return (
            <div>
             <Helmet>
                    <title>Adrena.me | Saia da rotina. Viva a adrenalina!</title>
                    <meta property="fb:app_id" content="342755846164051" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Adrena.me | Saia da rotina. Viva a adrenalina!" />
                    <meta property="og:description" content="O Adrena.me traz os esportes de aventura e atividades radicais disponíveis na sua região de forma agradável e descomplicada." />
                    <meta name="description"
                        content="O Adrena.me traz os esportes de aventura e atividades radicais disponíveis na sua região de forma agradável e descomplicada. Agendamento e pagamento* online, rápido e prático. Aventure-se conosco" />
                    <meta property="og:image"
                        content="https://s3-sa-east-1.amazonaws.com/adrenalyze-app/adrenalyze/meta-tag-image-1200x630.jpg" />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <Modal
                    isOpen={ApplicationStore.modal}
                    onRequestClose={() => ApplicationStore.closeModal()}
                    style={MODAL_STYLES}
                    contentLabel="Cadastre-se"
                >
                    <div className="box-modal">
                        <div className="Grid Grid--gutter Grid-column">
                            <div className="Grid-cell">
                                <div className="close-modal">
                                    <p className="float-right" onClick={() => ApplicationStore.closeModal()}>
                                        {(ApplicationStore.modalNotClosable === false) && "Fechar X"}
                                    </p>
                                </div>
                            </div>
                            <div className="Grid-cell">
                                {(ApplicationStore.modalLogin && <Login closeModal={this.handleCloseModal.bind(this)}
                                                                        goToPage={(page) => this.handleGoToPage(page)} /> )}
                                {(ApplicationStore.modalRegister && <Register closeModal={this.handleCloseModal.bind(this)}
                                                                        goToPage={(page) => this.handleGoToPage(page)} /> )}
                                {(ApplicationStore.modalForgotPassword && <ForgotPassword closeModal={this.handleCloseModal.bind(this)}
                                                                        goToPage={(page) => this.handleGoToPage(page)} /> )}
                            </div>
                        </div>
                    </div>
                </Modal>
                {!!ApplicationStore.tag_code && 
                <Script url={`https://www.googletagmanager.com/gtm.js?id=${ApplicationStore.tag_code.trim()}`} />
                }
                <ToastContainer
                    position="top-right"
                    type="default"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    pauseOnHover
                />
                {
                    this.state.cookieBlocked ?
                        <CookiesWarning />
                    :
                    <Switch>
                        <Route
                            path="/calendario/checkout"
                            component={Checkout}
                        />
                        <Route
                            exact={true}
                            path="/calendario/:id"
                            component={CalendarWidget}
                        />
                        <Route
                            exact={true}
                            path="/calendario/v2/:id"
                            component={RequestWidget}
                        />
                        <Route
                            exact={true}
                            path="/calendario/v3/:id"
                            component={TableWidget}
                        />
                        <Route
                            exact={true}
                            path="/calendario/single/:id"
                            component={SingleWidget}
                        />
                        <Route
                            exact={true}
                            path="/site/checkout/otherpayment"
                            component={OtherPayment}
                        />
                    </Switch>
                }
            </div>
        )
    }
}
export default SiteTemplate;