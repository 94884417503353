import Api from './Api';

class Metrics {
    triggerLoadSchedule(data){
        return Api.call('/metric/calendar-loaded', 'post', data, false, true, 'v2')
    }

    triggerHourSelected(data){
        return Api.call('/metric/hour-selected', 'post', data, false, true, 'v2')
    }

    triggerReserveFilled(data){
        return Api.call('/metric/reserve-filled', 'post', data, false, true, 'v2')
    }

    triggerReservationCompleted(data){
        return Api.call('/metric/reservation-completed', 'post', data, false, true, 'v2')
    }
}

export default new Metrics()