/**
 * Created by matheusmorett on 12/07/18.
 */
import { observable, action, computed } from 'mobx';
import JSEncrypt from 'jsencrypt'
// import Moip from 'Moip';
import { MoipCreditCard, MoipValidator } from 'moip-sdk-js'
import { MOIP_TOKEN } from './../../utils/MoipToken';
import Utils from './../../utils/Utils';
import moment from 'moment';
import MasterCard from './../../assets/img/icons/master-card.png';
import Visa from './../../assets/img/icons/visa.png';
import Elo from './../../assets/img/icons/elo.png';
import Amex from './../../assets/img/icons/amex.png';
import Hipercard from './../../assets/img/icons/hipercard.png';
import Diners from './../../assets/img/icons/diners.svg';
import DefaultCreditCard from './../../assets/img/icons/default-credit-card.png';
import GTM from 'utils/GTM';
import { CREDIT_CARD, BOLETO, LOCAL, MERCADO_PAGO, PAGSEGURO, PICPAY, GETNET, PIX } from './../../utils/Consts';
import Api from 'utils/Api';
import AuthStore from './../../stores/AuthStore';
import ApplicationStore from './../../stores/ApplicationStore';
import PaymentStore from './../../stores/PaymentStore';
import RequestStore from './../../stores/RequestStore';
import { getJunoToken } from '../../utils/JunoToken';
import Metrics from '../../utils/Metrics';
const DirectCheckout = window.DirectCheckout;

class Address {

    @observable cep
    @observable cepError = false

    @observable district
    @observable districtError = false

    @observable city
    @observable cityError = false

    @observable cep
    @observable cepError = false

    @observable uf
    @observable ufError = false

    @observable street
    @observable streetError = false

    @observable complement
    @observable complementError = false

    @observable number
    @observable numberError = false

    @observable phone
    @observable phoneError = false

    @observable addressAlreadyCompleted;

    constructor(user = {}) {
        this.cep = user.cep || '';
        this.district = user.district || '';
        this.city = user.city || '';
        this.uf = user.state || '';
        this.street = user.street || '';
        this.complement = user.complement || '';
        this.number = user.number || '';
        this.phone = user.ddd && user.phone ? user.ddd + " " + user.phone : '';

        this.addressAlreadyCompleted = !!(this.cep != '' && this.district != '' && this.city != '' && this.uf != '' && this.street != '' && this.number != '' && this.phone != '');
    }

    @computed
    get isAddressDisabled() {
        return !(this.cep && this.city && this.district && this.number && this.phone && this.street && this.uf)
    }

    _checkFields(name, value) {
        if (value === "") {
            this[name + "Error"] = true
        } else {
            this[name + "Error"] = false
        }
    }

    @action
    handleCep(e) {
        e.target.value = e.target.value.replace(/[^0-9]/g, "");
        this.cep = e.target.value

        this._checkFields(e.target.name, e.target.value);

        if (e.target.value.length === 8) {
            Utils.getCepByViaCep(e.target.value).then((response) => {
                if (!response.hasOwnProperty("erro")) {
                    this.city = response.localidade
                    this.uf = response.uf
                    this.street = response.logradouro
                    this.district = response.bairro

                    this._checkFields('city', response.localidade)
                    this._checkFields('uf', response.uf)
                    this._checkFields('street', response.logradouro)
                    this._checkFields('district', response.bairro)
                } else {
                    this.city = ''
                    this.uf = ''
                    this.street = ''
                    this.district = ''

                    this._checkFields('city', '')
                    this._checkFields('uf', '')
                    this._checkFields('street', '')
                    this._checkFields('district', '')
                }
            });
        }
    }

    @action
    handleInputChange(e) {
        if (e.target.name === "number") {
            e.target.value = e.target.value.replace(/[^0-9]/g, "");
        }

        if (e.target.name !== "complement")
            this._checkFields(e.target.name, e.target.value);

        this[e.target.name] = e.target.value
    }

    @action
    changeAddressAlreadyCompleted() {
        this.addressAlreadyCompleted = !this.changeAddressAlreadyCompleted
    }

    @action
    checkAllFields() {
        this._checkFields('cep', this.cep)
        this._checkFields('district', this.district)
        this._checkFields('city', this.city)
        this._checkFields('uf', this.uf)
        this._checkFields('street', this.street)
        this._checkFields('number', this.number)
        this._checkFields('phone', this.phone)
    }
}

class CheckoutStore {


    @observable paymentMethod;
    @observable creditCardIcon;
    @observable creditCardName;
    @observable creditCardNumber;
    @observable creditCardCvv;
    @observable creditCardValidity;
    @observable cvvErrorNumber;
    @observable method;
    @observable termsChecked = false;
    @observable privacyChecked = false;


    @observable address;
    @observable billingAttributes = {};

    @observable formError = {
        status: false,
        title: '',
        message: ''
    };

    @observable cpf;
    @observable phone;
    @observable email;
    @observable getnetFirstName;
    @observable getnetLastName;
    @observable birthday;

    @observable mpCheckoutToken = ''
    @observable payment_id
    @observable pix_code = ""
    @observable pix_image
    @observable advencePixModalEnabled
    @observable pixCompleteCallback = () => { }
    @observable pixModalOpen = false
    @observable didRegisterCheckoutLead = false
    @observable didCheckout = false
    @observable registeringCart = false
    @observable loadingCart = false
    @observable makingRequest = false
    @observable cart


    constructor() {
        this.paymentMethod = null;
        this.creditCardIcon = DefaultCreditCard;
        this.creditCardName = '';
        this.creditCardNumber = '';
        this.creditCardCvv = '';
        this.creditCardValidity = '';
        this.phone = '';
        if (!!AuthStore.authUser) {
            this.cpf = AuthStore.authUser.cpf;
            this.birthday = moment(AuthStore.authUser.birthday).format("DD/MM/Y");
        } else {
            this.cpf = '';
            this.birthday = '';
        }
        this.billingAttributes = new Address(AuthStore.authUser || {});
        this.mpCheckoutToken = '';
    }

    @computed
    get checkPhone() {
        return (this.billingAtributes && this.billingAtributes.phone && this.billingAtributes.phone.length === 12)
    }

    @computed
    get checkBillingAttributes() {
        return this.billingAttributes.cep &&
            this.billingAttributes.cepError === false &&
            this.billingAttributes.district &&
            this.billingAttributes.districtError === false &&
            this.billingAttributes.city &&
            this.billingAttributes.cityError === false &&
            this.billingAttributes.uf &&
            this.billingAttributes.ufError === false &&
            this.billingAttributes.street &&
            this.billingAttributes.streetError === false &&
            this.billingAttributes.complementError === false &&
            this.billingAttributes.number &&
            this.billingAttributes.numberError === false &&
            this.billingAttributes.phone &&
            this.billingAttributes.phoneError === false
    }

    @action
    resetBillingAttributes() {
        this.billingAttributes = new Address(AuthStore.authUser || {});
    }

    @action
    selectPaymentMethod(paymentMethod) {
        if (this.isMethodEnabled(paymentMethod)) {
            this.paymentMethod = paymentMethod
            PaymentStore.reBuildInstallment();
            // PaymentStore.finalPrice()
        }
    }

    @action
    isMethodEnabled(method) {
        switch (method) {
            case CREDIT_CARD:
                return true
            case BOLETO:
                return Math.ceil(moment(RequestStore.date).diff(moment(), 'days', true)) > 8 || RequestStore.voucherSelected
            case LOCAL:
                return true
            case PICPAY:
                return Math.ceil(moment(RequestStore.date).diff(moment(), 'days', true)) > 6 || RequestStore.voucherSelected
            case PIX:
                return true
            case MERCADO_PAGO:
                return (Math.ceil(moment(RequestStore.date).diff(moment(), 'days', true)) >= RequestStore.adventure.mercado_pago_block_days || RequestStore.voucherSelected) &&
                    !!RequestStore.adventure.company.mercado_pago_token
            case PAGSEGURO:
                return (Math.ceil(moment(RequestStore.date).diff(moment(), 'days', true)) >= RequestStore.adventure.pagseguro_block_days || RequestStore.voucherSelected) &&
                    !!RequestStore.adventure.company.pagseguro_token
            case GETNET:
                return (Math.ceil(moment(RequestStore.date).diff(moment(), 'days', true)) >= RequestStore.adventure.getnet_block_days || RequestStore.voucherSelected) &&
                    !!RequestStore.adventure.company.getnet_seller_id;
            default:
                return false;
        }
    }

    @action
    toggleTermsChecked() {
        this.termsChecked = !this.termsChecked
    }

    @action
    togglePrivacyChecked() {
        console.log("togglePrivacyChecked")
        this.privacyChecked = !this.privacyChecked
    }

    handleMpCheckoutToken(mpCheckoutToken) {
        this.mpCheckoutToken = mpCheckoutToken;
    }

    @action
    resetPaymentMethod() {
        this.paymentMethod = null
    }

    @action
    checkFields(name, value) {
        (value === "") ? this[name + "Error"] = true : this[name + "Error"] = false;
    }

    @action
    validaCvv() {
        (this.creditCardNumber.length === 19 && this.creditCardCvv.length > 3) ?
            (MoipValidator.isSecurityCodeValid(this.creditCardNumber, this.creditCardCvv) === true) ? this.cvvErrorNumber = false : this.cvvErrorNumber = true
            :
            this.cvvErrorNumber = false
    }

    @action
    handleValidity(e) {
        this.checkFields(e.target.name, e.target.value);
        this.creditCardValidity = e.target.value;
        const month = e.target.value.split("/")[0];
        const year = e.target.value.split("/")[1];
        (MoipValidator.isExpiryDateValid(month, year) === false) ? this.validityErrorDate = true : this.validityErrorDate = false;
    }

    @action
    handleCreditCardNumber(e) {
        this.validaCvv();
        this.checkFields(e.target.name, e.target.value);
        this.creditCardNumber = e.target.value;
        const brand = MoipValidator.cardType(e.target.value);
        let brandIcon = DefaultCreditCard;
        if (brand !== null) {
            switch (brand.brand) {
                case "MASTERCARD":
                    brandIcon = MasterCard;
                    break;
                case "VISA":
                    brandIcon = Visa;
                    break;
                case "AMEX":
                    brandIcon = Amex;
                    break;
                case "DINERS":
                    brandIcon = Diners;
                    break;
                case "HIPERCARD":
                    brandIcon = Hipercard;
                    break;
                case "ELO":
                    brandIcon = Elo;
                    break;
                default:
                    brandIcon = DefaultCreditCard
            }
        }

        this.creditCardIcon = brandIcon;
    }

    @action
    handleInputChange(e) {
        if (e.target.name === "CPF" && e.target.value.length === 11) {
            if (!Utils.validaCPF(e.target.value)) {
                this.cpfErrorValidate = true;
                this.cpfError = false;
            } else {
                this.cpfErrorValidate = false;
                this.cpfError = false;
            }
        }

        if (e.target.name === "creditCardName" && !/^[a-zA-Z -]*$/.test(e.target.value)) {
            return false;
        }

        if (e.target.name === "birthday") {
            const date = e.target.value.split("/")[2] + '-' + e.target.value.split("/")[1] + "-" + e.target.value.split("/")[0];
            const eightYearsAgo = moment().subtract("years", 18);
            let birthday = moment(date);

            if (!birthday.isValid()) {
                // INVALID DATE
                this.birthdayErrorValidate = true;
                this.birthdayErrorAge = false;
            } else if (!eightYearsAgo.isAfter(birthday)) {
                this.birthdayErrorValidate = false;
                this.birthdayErrorAge = true;
            } else {
                this.birthdayErrorValidate = false;
                this.birthdayErrorAge = false;
            }
        }
        this[e.target.name] = e.target.value;
        this.checkFields(e.target.name, e.target.value);
    }

    @action
    handleCreditCardCvv(e) {
        this.checkFields(e.target.name, e.target.value);
        this.validaCvv();
        this.creditCardCvv = e.target.value;
    }

    @action
    handleRequest() {

        if (!PaymentStore.loading) {

            if (this.cpf === "") {
                this.cpfError = true;
            }
            if (this.birthday === "") {
                this.birthdayError = true;
            }
            if (this.creditCardName === "") {
                this.creditCardNameError = true;
            }
            if (this.creditCardNumber === "") {
                this.creditCardNumberError = true;
            }
            if (this.creditCardCvv === "") {
                this.cvvError = true;
            }
            if (this.creditCardValidity === "") {
                this.validityError = true;
            }

            var cardData = {
                cardNumber: this.creditCardNumber.replace(/\D+/gi, ""),
                holderName: this.creditCardName,
                securityCode: this.creditCardCvv,
                expirationMonth: this.creditCardValidity.split("/")[0],
                expirationYear: moment(this.creditCardValidity.split("/")[1], "YY").format("YYYY")
            };

            return new Promise((resolve, reject) => {
                return this.checkout(cardData).then(res => resolve(res));
            })

            /* const checkout = new DirectCheckout(getJunoToken(), true);
            if (checkout.isValidCardNumber(this.creditCardNumber.replace(/\D+/gi, ""))) {
                var cardData = {
                    cardNumber: this.creditCardNumber.replace(/\D+/gi, ""),
                    holderName: this.creditCardName,
                    securityCode: this.creditCardCvv,
                    expirationMonth: this.creditCardValidity.split("/")[0],
                    expirationYear: moment(this.creditCardValidity.split("/")[1], "YY").format("YYYY")
                };

                return new Promise((resolve,reject) => {
                   return checkout.getCardHash(cardData, (cardHash) => {
                        this.formError = {
                            status: false,
                            message: ""
                        };
                        // GTM.dispatch('confirmacao-pagamento');
                        return this.checkout(cardHash).then(res => resolve(res));
                    }, function(error) {
                        this.formError = {
                            status: true,
                            message: error
                        }
                        return reject()
                    });
                })
                
            } else {
                this.formError = {
                        status: true,
                        message: "Cartão de Crédito inválido."
                    }
            } */

        }
    }

    @action
    setPaymentId(payment_id) {
        this.payment_id = payment_id
    }

    @action
    setQRCodeImage(image64) {
        this.pix_image = image64
    }

    @action
    setPIXCode(pixCode) {
        this.pix_code = pixCode
    }

    @action
    setAdvencePixModalEnabled(enabled) {
        this.advencePixModalEnabled = enabled
    }

    @action
    toggleOpenPIXModal() {
        this.pixModalOpen = !this.pixModalOpen
    }

    @action
    setPixCompleteCallback(callback) {
        this.pixCompleteCallback = callback
    }

    @action
    enableAdvencePixModal() {
        this.advencePixModalEnabled = true
    }

    @action
    getRequestParams() {
        const items = JSON.parse(localStorage.getItem('requestParams'));

        return {
            pk_cart: items.pk_cart !== undefined ? items.pk_cart : this.pk_cart,
            name: this.creditCardName ? this.creditCardName : AuthStore.authUser.name,
            cpf: this.cpf,
            phone: this.phone ? this.phone : this.billingAttributes.phone,
            birthday: this.birthday,
            url: window.location.href.split("/checkout")[0],
            reserves: RequestStore.reserves,
            paymentMethod: this.paymentMethod,
            isRequest: items.isRequest !== undefined ? !!items.isRequest : !!RequestStore.isPaymentForRequest,
            payment_id: items.payment_id !== undefined ? items.payment_id : this.payment_id,
            token: items.token !== undefined ? items.token : RequestStore.token,
            date: items.date !== undefined ? moment(items.date).format("YYYY-MM-DD") : moment(RequestStore.date).format("YYYY-MM-DD"),
            hour: items.hour !== undefined ? items.hour : RequestStore.hour,
            voucherSelected: items.voucherSelected !== undefined ? items.voucherSelected : RequestStore.voucherSelected,
            type: items.activeButton !== undefined ? items.activeButton : RequestStore.activeButton,
            fkAdventure: items.fkAdventure !== undefined ? items.fkAdventure : RequestStore.adventure && RequestStore.adventure.pkAdventure,
            pkRuleTime: items.pkRuleTime !== undefined ? items.pkRuleTime : RequestStore.pkRuleTime,
            widget: items.widget !== undefined ? items.widget : RequestStore.widget,
            package: items.packages !== undefined ? items.packages.filter((p) => p.quantity !== undefined && p.quantity > 0) : RequestStore.avaiablePackages.filter((p) => p.quantity !== undefined && p.quantity > 0),
            pkDiscount: items.discount !== undefined ? items.discount.pk_discount : PaymentStore.discount && PaymentStore.discount.pk_discount,
            interest: items.interest !== undefined ? items.interest : PaymentStore.getSelectedInterest(),
            installment: items.installment !== undefined ? items.installment : PaymentStore.getSelectedInstallment(),
            team: items.team !== undefined ? items.team : RequestStore.selectedTeam,
        }
    }

    @action
    registerCheckoutLeads() {
        if (this.didRegisterCheckoutLeads)
            return;

        const payload = {
            reserves: RequestStore.reserves,
            date: moment(RequestStore.date).format("YYYY-MM-DD") + "T" + RequestStore.hour.start + ":00",
            pkAdventure: RequestStore.adventure.pkAdventure,
            fkCompany: RequestStore.adventure.fkCompany
        }

        return Api.call('/lead/register-checkout-lead', 'post', payload, false, true)
            .then(({ data }) => {
                this.didRegisterCheckoutLead = true
                setTimeout(() => {
                    if (!this.didCheckout && !isNaN(data))
                        Api.call('/lead/register-checkout-idle', 'post', { pkLead: data }, false, true)
                }, 600000);
            })
    }

    @action
    goToSuccessCheckout(res) {
        // GTM.dispatch('checkoutSucesso',
        //   {
        //     'ecommerce': {
        //       'detail': {
        //         'event': 'checkoutSucesso',
        //         'products': [{
        //           'preco': (PaymentStore.discount) ? PaymentStore.getPaymentWithDiscount() : PaymentStore.price,
        //           'id': RequestStore.adventure.pkAdventure,
        //           'tipo': RequestStore.adventure.typeName,
        //           'quantidade': RequestStore.getSelectedPackage.quantity
        //         }],
        //       }
        //     }
        //   }
        // );
        const adventure = RequestStore.adventure
        if (RequestStore.voucherSelected) {
            localStorage.setItem('vouchers', JSON.stringify(res.data))
            ApplicationStore.history.push(`/site/${adventure.company_url}/${adventure.urlName}/voucher/sucesso`)
        } else if (ApplicationStore.minimalTemplate) {
            ApplicationStore.history.push(`/site/${adventure.company_url}/${adventure.urlName}/pedido/${res.data.pk_payment}/${res.data.pk_request}`)
        } else {
            ApplicationStore.history.push("/pedido/" + res.data.pk_payment + "/" + res.data.pk_request + "/detalhes/reserva/sucesso");
        }
    }

    payWithMercadoPago() {

        const mpParams = {
            company: RequestStore.adventure.company.pk_company,
            items: [
                {
                    title: RequestStore.adventure.title,
                    quantity: 1,
                    unit_price: !!PaymentStore.discount
                        ? PaymentStore.getPaymentWithDiscount()
                        : PaymentStore.price
                }
            ]
        };

        return Api.call('/payment-gateway/mercado-pago-create-items', 'post', mpParams, false, true)
            .then(({ data }) => {
                const { payment_id, id } = data.data
                const MercadoPago = window.MercadoPago

                this.handleMpCheckoutToken(id);
                this.setPaymentId(payment_id)
                this.checkout(null)

                const mp = new MercadoPago(RequestStore.adventure.company.mercado_pago_public_key, { locale: 'pt-BR' })
                mp.checkout({ preference: { id }, autoOpen: true })

                const handleModalMessages = (message) => {
                    if (!message.origin.includes("mercadopago")) return

                    let { data } = message
                    if (typeof data === "string") data = JSON.parse(data)

                    if (data.type === "close") {
                        PaymentStore.cancelPayment(payment_id)
                    } else if (data.type === "submit") {
                        ApplicationStore.history.push(`/other-payment/checkout?mp=1&external_reference=${encodeURIComponent(data.value[1].value)}`);
                    } else {
                        return
                    }

                    window.removeEventListener('message', handleModalMessages)
                }

                window.addEventListener('message', handleModalMessages)
            });
    }

    payWithPagSeguro() {
        // const psParams = JSON.parse(localStorage.getItem('pagseguro'));

        const psParams = {
            company: RequestStore.adventure.company.pk_company,
            itemAmount: !!PaymentStore.discount
                ? PaymentStore.getPaymentWithDiscount()
                : PaymentStore.price,
            itemDescription: RequestStore.adventure.title
        };

        // console.log('PSPARAMS: ', psParams);
        return Api.call('/payment-gateway/pag-seguro-create-items', 'post', psParams, false, true).then(({ data }) => {
            this.setPaymentId(data.data.payment_id)
            this.checkout(null).then(() => {
                const didPagseguroOpen = window.PagSeguroLightbox(data.data.code[0], {
                    success: () => {
                        ApplicationStore.history.push(`/other-payment/checkout?ps=1&payment_id=${encodeURIComponent(data.data.payment_id)}`);
                    },
                    abort: () => {
                        PaymentStore.cancelPayment(data.data.payment_id);
                    }
                });
                if (!didPagseguroOpen || Utils.mobileCheck()) {
                    window.location.href = `https://pagseguro.uol.com.br/v2/checkout/payment.html?code=${data.data.code[0]}`
                }
            })


        });
    }

    payWithGetNet() {
        this.checkout(null)
    }


    payWithPix(callbackCheckout) {
        return this.checkout(null).then(res => {
            if (!res.data) return false
            let payment_id, qrcode_img, pix_code

            if (Array.isArray(res.data)) {
                payment_id = res.data[0].payment.payment_id
                qrcode_img = res.data[0].payment.qrcode_img
                pix_code = res.data[0].payment.pix_code
            } else {
                payment_id = res.data.payment.payment_id
                qrcode_img = res.data.payment.qrcode_img
                pix_code = res.data.payment.pix_code
            }

            this.setPaymentId(payment_id)
            this.setQRCodeImage(`data:image/png;base64,${qrcode_img}`)
            this.setPIXCode(pix_code)
            this.toggleOpenPIXModal()
            this.setPixCompleteCallback(() => {
                clearInterval(statusInterval)
                clearTimeout(advenceTimeout)
                callbackCheckout(res)
            })

            this.cancelPixPayment = () => {
                clearInterval(statusInterval)
                clearTimeout(advenceTimeout)
                this.toggleOpenPIXModal()
                PaymentStore.cancelPayment(this.payment_id)
            }

            const advenceTimeout = setTimeout(() => {
                this.enableAdvencePixModal()
            }, 300000)

            const statusInterval = setInterval(() => {
                Api.call('/payment/check-status-by-id', 'get', { payment_id: this.payment_id })
                    .then(({ data }) => {
                        if (data.data.status === "CONFIRMED") {
                            clearInterval(statusInterval)
                            clearTimeout(advenceTimeout)
                            callbackCheckout(res)
                        }
                    })
            }, 30000)
        })
    }


    @action
    completeCheckout() {
        // console.log('MAKING REQUEST: ' + this.makingRequest);
        if (this.makingRequest) return

        this.makingRequest = true
        const callbackCheckout = (res) => {
            if (res) {
                // console.log(res);
                Metrics.triggerReservationCompleted({ fk_company: RequestStore.adventure.fkCompany, fk_adventure: RequestStore.adventure.pkAdventure })
                this.goToSuccessCheckout(res);
            }
            this.makingRequest = false
        }
        // console.log('PAYMENT STORE:', PaymentStore);
        // console.log('PAYMENT STORE FINAL PRICE:', PaymentStore.finalPrice);
        if (PaymentStore.finalPrice > 0) {
            //else if (this.paymentMethod === BOLETO) {
            // this.checkout(null).then(callbackCheckout);
            if (this.paymentMethod === CREDIT_CARD) {
                this.handleRequest().then(callbackCheckout);
            } else if (this.paymentMethod === PAGSEGURO) {
                this.payWithPagSeguro().then(callbackCheckout);
            } else if (this.paymentMethod === MERCADO_PAGO) {
                this.payWithMercadoPago().then(callbackCheckout)
            } else if (this.paymentMethod === PIX) {
                this.payWithPix(callbackCheckout)
            } else {
                this.checkout().then(callbackCheckout);
            }
        } else {
            this.registerCheckoutLeads()
            this.checkout().then(callbackCheckout);
        }
    }

    @action
    registerCheckoutData(data) {
        this.registeringCart = true
        return Api.call('/cart/register-cart', 'post', data, false, true, 'v2')
            .then((res) => {
                this.registeringCart = false
                this.pk_cart = res.data.data.pk_cart
            })
            .catch((err) => {
                console.error(err)
                this.registeringCart = false
            })
    }

    @action
    getCheckoutCart(pk_cart, attempt = 0) {
        this.loadingCart = true
        return Api.call('/cart/get-cart', 'get', { pkCart: pk_cart || this.pk_cart }, false, false, 'v2')
            .then((res) => {
                this.loadCartData(res.data.data)
                this.loadingCart = false
            })
            .catch((err) => {
                console.error(err)
                this.loadingCart = false
                if (attempt < 5) { // já tentou 0-4, pode tentar mais uma vez
                    // Inserir um timeout de 3 segundos e chamar novamente o getCheckoutCart(pk_cart), incrementando a tentativa
                    setTimeout(() => {
                        this.getCheckoutCart(pk_cart, attempt + 1);
                    }, 3000);
                } else {
                    console.error('getCheckoutCart falhou após 6 tentativas');
                }
            })
    }

    @action
    loadCartData(cart) {
        const data = {}
        cart.metas.forEach(meta => data[meta.meta_name] = meta.meta_value === "" ? false : meta.meta_value)
        Object.keys(cart).map(key => {
            if (key !== "metas")
                data[key] = cart[key]
        })
        this.cart = data
        localStorage.setItem("requestParams", JSON.stringify(data))
    }

    @action
    checkout(cardData) {                                                                                            // O pix entra nesta função?
        this.didCheckout = true
        let params = { ...this.billingAttributes };

        const items = this.getRequestParams()

        params = { ...params, ...items }

        const createOrderAndHandleResponse = params => {
            return PaymentStore.createOrder(params)
                .then(response => {
                    this.makingRequest = false;
                    if (response.data.success === true) {
                        GTM.dispatch(GTM.EVENT_END_CHECKOUT, {
                            adventure: RequestStore.adventure.title,
                            packages: items.package.map(p => ({
                                name: p.name,
                                quantity: p.quantity,
                                price: p.priceWithDiscount || p.price
                            }))
                        });
                        return response.data;
                    } else {
                        let title, message;
                        try {
                            const json = JSON.parse(response.data.data.message);
                            title = json.title;
                            message = json.message;
                        } catch (error) {
                            title = "Erro";
                            message = response.data.data.message;
                        } finally {
                            this.formError = {
                                status: true,
                                title: title,
                                message: message
                            };
                            return false;
                        }
                    }
                })
                .catch(err => {
                    let title, message;
                    this.makingRequest = false;
                    try {
                        const json = JSON.parse(err.data.message);
                        title = json.title;
                        message = json.message;
                    } catch (error) {
                        title = "Erro";
                        message = err.data.message;
                    } finally {
                        this.formError = {
                            status: true,
                            title: title,
                            message: message
                        };
                        return false;
                    }
                });
        };

        if (params.paymentMethod == CREDIT_CARD || params.paymentMethod == PIX) {
            if (params.paymentMethod == CREDIT_CARD) {
                const appId = RequestStore.adventure.company.pagarme_settings.pagarme_public_key;
                return PaymentStore.getCardToken(cardData, appId).then(res => {
                    console.log(res);
                    const token = { card_token: res.id }
                    params = { ...params, ...token }
                    return createOrderAndHandleResponse(params)
                })
            }
            return createOrderAndHandleResponse(params)
        } else {
            return PaymentStore.payByHash(params)
                .then((response) => {
                    this.makingRequest = false
                    if (response.data.success === true) {
                        GTM.dispatch(GTM.EVENT_END_CHECKOUT, {
                            adventure: RequestStore.adventure.title,
                            packages: items.package.map(p => ({ name: p.name, quantity: p.quantity, price: p.priceWithDiscount || p.price }))
                        })
                        return response.data;
                    } else {
                        let title, message;
                        try {
                            const json = JSON.parse(response.data.data.message)
                            title = json.title;
                            message = json.message;
                        } catch (error) {
                            title = "Erro"
                            message = response.data.data.message
                        } finally {
                            this.formError = {
                                status: true,
                                title: title,
                                message: message
                            };
                            return false;
                        }
                    }
                })
                .catch(err => {
                    let title, message;
                    this.makingRequest = false
                    try {
                        const json = JSON.parse(err.data.message)
                        title = json.title;
                        message = json.message;
                    } catch (error) {
                        title = "Erro"
                        message = err.data.message
                    } finally {
                        this.formError = {
                            status: true,
                            title: title,
                            message: message
                        };
                        return false;
                    }
                });
        }

    }


    @action
    setBillingAttributes(params) {
        this.billingAttributes = params;
    }

    @action
    closeError() {
        this.formError = {
            status: false
        };
    }


    @action
    handleRequestWithBoleto() {
        return this.checkout(null);
    }

    @action
    checkCanBeBoleto() {
        return Math.ceil(moment(RequestStore.date).diff(moment(), 'days', true)) > 6
    }

    @computed
    get paymentDisabled() {
        // VERIFICA DADOS DOS METODOS DE PAGAMENTO
        if (this.paymentMethod === BOLETO || this.paymentMethod === PICPAY) {
            if (!(this.cpf &&
                this.birthday))
                return true;
        } else if (this.paymentMethod === PIX) {
            // console.log('CheckoutStore')
            // console.log('cpf', this.cpf)
            // console.log('creditCardName', this.creditCardName)
            if (!(this.cpf &&
                this.creditCardName))
                return true;
        } else if (this.paymentMethod === CREDIT_CARD) {
            if (!(this.creditCardName &&
                this.creditCardNumber &&
                this.creditCardCvv &&
                this.creditCardValidity &&
                this.cpf &&
                this.birthday))
                return true;
        } else if (this.paymentMethod === LOCAL) {
            return false;
            //if (!(this.billingAttributes && this.billingAttributes.phone && this.billingAttributes.phone.length === 12))
            //    return true;
        } else if (this.paymentMethod === PAGSEGURO) {
            return false;
        } else if (this.paymentMethod === MERCADO_PAGO) {
            return false;
        } else if (this.paymentMethod === GETNET) {
            if (!(this.cpf && this.getnetFirstName && this.getnetLastName))
                return true;
        } else if (this.paymentMethod === PAGSEGURO) {
            return false;
        } else {
            return true;
        }

        // VERIFICA DADOS DO ENDEREÇO
        if (!this.checkBillingAttributes && this.paymentMethod !== PIX && this.paymentMethod !== LOCAL) {
            return true;
        }

        // VERIFICA DADOS SOBRE PACOTE
        if (!RequestStore.avaiablePackages.filter((p) => p.quantity !== undefined && p.quantity > 0).length > 0) {
            return true;
        }

        // Se esta tudo preenchido, pode pagar
        return false;
    }
}

export default new CheckoutStore();
