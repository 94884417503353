import { observable, action } from 'mobx';
import moment from 'moment';
import Api from 'utils/Api';

class FeedbackStore {
    @observable feedback = {};
    @observable comment = {};
    @observable adventure = {};
    @observable loading = false;

    @action
    createComment(params) {
        this.loading = true;

        return Api.call('/comment/create', 'post', params).then(() => {
            this.loading = false;
            return true;
        });
    }

    @action
    getAdventureForFeedback(params) {
        return Api.call('/adventure/get-adventure-for-feedback', 'get', params).then((response) => {
            this.adventure = response.data.data;
            return response.data;
        });
    }

    @action
    getComment(params) {
        return Api.call('/comment/get-comment-by-reserve-token', 'get', params).then((response) => {
            this.comment = response.data.data;
            return response.data;
        });
    }
 }

export default new FeedbackStore();