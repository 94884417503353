/**
 * Created by matheusmorett on 29/08/18.
 */
import React, {Component} from 'react';
import FacebookLogin from 'react-facebook-login';
import { inject, observer } from 'mobx-react';
import Utils from 'utils/Utils';
import Alert from 'components/Alert';
import Button from 'components/Button';

import "./ForgotPassword.scss";

@inject("AdventuresStore", "AuthStore" , "ApplicationStore")
@observer
class ForgotPassword extends Component {

    static defaultProps = {
        openModal: () => {
        },
        closeModal: () => {
        },
        goToPage: () => {
        }
    };

    constructor(props) {
        super(props);

        this.state = {
            menuOpen: false,
            email: '',
            password: '',
            alert: false,
            emailRight: false,
            formError: {
                status: false,
                message: ""
            }
        };

    }


    componentWillReceiveProps(props) {
        this.setState({...props});
    }

    closeModal() {
        this.props.closeModal();
    }

    handleInputChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        if(name === "email"){
            if(Utils.validateEmail(value) === false){
                this.setState({
                    emailRight: false
                });
            }else{
                this.setState({
                    emailRight: true
                });
            }
        }

        this.setState({
            [name]: value
        });
        this.checkFields(name);

    }

    checkFields(name) {
        if(this.state[name] === "" || this.state[name] === false || this.state[name] === undefined){
            this.setState({
                [name  + "Error"]: true
            });
            return false;
        }else{
            this.setState({
                [name  + "Error"]: false
            });
            return true;
        }
    }

    handleInputBlur(e) {
        const name = e.target.name;
        this.checkFields(name);
    }

    handleSubmitForm(e) {
        if(e) {
            e.preventDefault();
        }
        if(this.checkFields("email")) {
            const { AuthStore } = this.props;

            AuthStore.forgotPassword({
                email: this.state.email
            });

            this.setState({
                formError: {
                    status: false,
                    message: "Foi enviado um email com a sua nova senha!"
                }
            });
        }
    }

    responseFacebook(response) {

        const { AuthStore, AdventuresStore, goToPage, ApplicationStore } = this.props;
        const params = {
            name: response.name,
            email: response.email,
            token: response.accessToken,
            photo: response.picture.data.url
        };

        AuthStore.loginSocial(params).then((response) => {

            if(response.success) {
                this.setState({
                    formError: {
                        status: false,
                        message: "Logado com sucesso"
                    }
                });
                ApplicationStore.closeModal();
                AdventuresStore.page = 1;
                if(AuthStore.reloadPageAfterLogin) {
                    window.location.reload();
                } else if(AuthStore.pageAfterLogin !== "") {
                    goToPage(AuthStore.pageAfterLogin);
                }
            } else {
                this.setState({
                    formError: {
                        status: true,
                        message: "Falha ao tentar acessar via Facebook."
                    }
                });
            }

        });

    }

    handleForgotPassword() {

        if(this.checkFields("email")) {

            const { AuthStore } = this.props;

            AuthStore.forgotPassword({
                email: this.state.email
            });

            this.setState({
                formError: {
                    status: false,
                    message: "Foi enviado um email com a sua nova senha!"
                }
            });

        }

    }

    handleGoToRegister() {
        const { ApplicationStore } = this.props;
        ApplicationStore.openModalRegister();
    }

    handleGoToLogin() {
        const { ApplicationStore } = this.props;
        ApplicationStore.openModalLogin();
    }

    closeAlert() {
        this.setState({
            formError: {}
        });
    }

    render() {

        const { AuthStore } = this.props;

        return (
            <div className="ForgotPassword">
                <div className="modal-content">
                    <div className="facebook-login">
                        <FacebookLogin
                            appId="342755846164051"
                            autoLoad={false}
                            fields="name,email,picture.width(500).height(500)"
                            callback={this.responseFacebook.bind(this)}
                            cssClass="btn btn-social btn-facebook btn-inner-card"
                            icon="fa-facebook"
                            isMobile={Utils.mobileCheck()}
                            disableMobileRedirect={true}
                            textButton="LOGAR COM FACEBOOK"
                        />
                    </div>
                    <div className="Grid Grid-cell separator-or">
                        <div className="Grid-cell">
                            <hr />
                        </div>
                        <div className="Grid-cell text-quicksand-muted text-center">
                            ou
                        </div>
                        <div className="Grid-cell">
                            <hr />
                        </div>
                    </div>
                    <form onSubmit={this.handleSubmitForm.bind(this)}>
                        <div className="input-cell">
                            <div className="full-width group-input">
                                <input className="full-width"
                                       type="text"
                                       required
                                       value={this.state.email}
                                       onChange={this.handleInputChange.bind(this)}
                                       onBlur={this.handleInputBlur.bind(this)}
                                       name="email"
                                       autoComplete="off"
                                />
                                <span className="bar full-width"></span>
                                <span className="help-hasError">{(this.state.emailRight === false && this.state.emailError === false? ' "Email" inválido.' : '')}</span>
                                <span className="help-hasError">{(this.state.emailError === true ? ' "Email" não pode ficar em branco.' : '')}</span>
                                <label>Email</label>
                            </div>
                        </div>
                        <div>
                            <Alert formError={this.state.formError} closeAlert={this.closeAlert.bind(this)}/>
                        </div>
                        <divs>
                            <Button loading={AuthStore.loading} type="primary" size="full">
                                {(AuthStore.loading ? <div><i className='fa fa-spinner fa-spin'></i></div> : <div>Enviar</div>)}
                            </Button>
                        </divs>
                        <div className="other-links">
                            <div onClick={this.handleGoToLogin.bind(this)}>
                                Entrar
                            </div>
                            <div onClick={this.handleGoToRegister.bind(this)}>
                                Cadastre-se
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}



export default ForgotPassword;