import { observable, action, computed } from "mobx";

//utils
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format';
import Utils from 'utils/Utils';

export class Package {
  @observable pkPackage;
  @observable price;
  @observable name;
  @observable description;
  @observable priceWithDiscount;
  @observable adrenalyze_value;
  @observable max;
  @observable fkPackageCategory;
  @observable hasTeam;
  @observable packageCategory;
  @observable default;
  @observable reserves;
  @observable fkAdventure;
  @observable items;
  @observable quantity;

  constructor(item) {
    this.pkPackage = item.pk_package;
    this.name = item.name || '';
    this.description = item.description || '';
    this.price = item.price || '';
    this.max = item.max || '';
    this.reserves = item.reserves || [];
    this.priceWithDiscount = item.priceWithDiscount || '';
    this.adrenalyze_value = item.adrenalyze_value || '';
    this.packageCategory = item.packageCategory;
    this.fkPackageCategory = item.fk_package_category;
    this.fkAdventure = item.fk_adventure;
    this.hasTeam = item.has_team;
    this.default = item.default || false;
    this.items = item.items || [];
    this.quantity = 0;
  }
}

export class ScheduleRule {
  constructor(json = {}) {
    if (!Array.isArray(json.weekdays)) {
      json.weekdays = [];
    }
    this.pkScheduleRule = json.pk_schedule_rule
    this.isDeleted = json.is_deleted
    this.startDate = json.start_date
    this.endDate = json.end_date ? json.end_date : ''
    this.weekdays = json.weekdays.map(day => day.day)
    this.name = json.name
    this.hour = json.hour
    this.tag = json.tag
  }
}

export class Adventure {

  @observable pkAdventure;
  @observable image;
  @observable banner;
  @observable video;
  @observable title;
  @observable adrenalevel;
  @observable averageRate;
  @observable description;
  @observable package;
  @observable packagesCategory;
  @observable packages;
  @observable includes;
  @observable operatings;
  @observable preclusions;
  @observable requests;
  @observable duration;
  @observable block_request_hours_before;
  @observable block_participation_hours_before;
  @observable longDescription;
  @observable min;
  @observable max;
  @observable urlName;
  @observable lat;
  @observable lng;
  @observable number;
  @observable likes;
  @observable price;
  @observable priceWithDiscount;
  @observable only_public;
  @observable neighborhood;
  @observable city;
  @observable state;
  @observable fkCompany;
  @observable fkType;
  @observable createdAt;
  @observable updatedAt;
  @observable isDeleted;
  @observable typeName;
  @observable favorite = null;
  @observable topSelled = false;
  @observable top3Selled = false;
  @observable haveSeen;
  @observable comments;
  @observable stars;
  @observable region_url;
  @observable categoryTitle;
  @observable categoryName;
  @observable categoryUrl;
  @observable company_address;
  @observable company_url;
  @observable company_logo;
  @observable company_phone;
  @observable company_whatsapp;
  @observable company_mail;
  @observable local_payment_enabled;
  @observable credit_card_enabled;
  @observable boleto_enabled;
  @observable nextDate;
  @observable cancel_rules;
  @observable fillAllReserves;
  @observable request_success_message;
  @observable payment_on_request;
  @observable request_payment_min;
  @observable request_payment_max;
  @observable packageForRequest;
  @observable useScheduleRulePackages;
  @observable reserve_observation_enabled;
  @observable request_observation_enabled;

  constructor(item = {}) {
    item.scheduleRules = Array.isArray(item.scheduleRules) ? item.scheduleRules : [];
    this.pkAdventure = item.pk_adventure;
    this.banner = item.banner || {};
    this.video = item.video;
    this.title = item.title;
    this.adrenalevel = item.adrenalevel;
    this.averageRate = item.average_rate;
    this.description = item.description;
    this.package = item.package;
    this.duration = item.duration;
    this.block_participation_hours_before = item.block_participation_hours_before;
    this.block_request_hours_before = item.block_request_hours_before;
    this.longDescription = (item.long_description) ? item.long_description : item.description;
    this.min = item.min;
    this.max = item.max;
    this.urlName = item.url_name;
    this.lat = item.lat;
    this.lng = item.lng;
    this.image = item.image || {};
    this.images = (!!item.images) ? item.images : [];
    this.packages = (!!item.packages) ? item.packages.map((item) => {
      return new Package(item);
    }) : [];
    this.formatPackagesCategory();
    this.script = (!!item.script) ? item.script : [];
    this.requests = (!!item.requests) ? item.requests : [];
    this.user_like = (!!item.user_like) ? item.user_like : [];
    this.includes = (!!item.includes) ? item.includes : [];
    this.preclusions = (!!item.preclusions) ? item.preclusions : [];
    this.operatings = (!!item.operatings) ? item.operatings : [];
    this.scheduleRules = item.scheduleRules.length > 0 ? item.scheduleRules.map(rule => new ScheduleRule(rule)) : item.scheduleRules;
    this.company = item.company;
    this.company_logo = item.company_logo;
    this.company_phone = item.company_phone;
    this.company_whatsapp = item.company_whatsapp;
    this.company_mail = item.company_mail;
    this.company_address = item.company_address;
    this.company_tag_code = item.company_tag_code;
    this.only_public = item.only_public;
    this.likes = item.likes;
    this.price = item.price;
    this.number = item.number;
    this.priceWithDiscount = item.priceWithDiscount;
    this.street = item.street;
    this.neighborhood = item.neighborhood;
    this.city = item.city;
    this.online_scheduling = item.online_scheduling;
    this.voucher_enabled = item.voucher_enabled;
    this.scripts = item.scripts;
    this.state = item.state;
    this.fkCompany = item.fk_company;
    this.fkCategory = item.fk_category;
    //this.type = item.type;
    this.eventDates = item.eventDates;
    //this.fkType = item.fk_type;
    this.createdAt = item.created_at;
    this.updatedAt = item.updated_at;
    this.isDeleted = item.is_deleted;
    //this.typeName = item.type_name;
    this.categoryName = item.category_name;
    this.categoryUrl = item.category_url
    this.region_url = item.region_url;
    this.region_name = item.region_name;
    this.company_url = item.company_url;

    if (item.region) {
      this.categoryTitle = Utils.injectCidade(`${item.region.name_append} ${item.region.formatted_name}`, item.category_title);
    }

    this.categoryTitle = item.category_title

    this.topSelled = item.topSelled;
    this.top3Selled = item.top3Selled;
    if (!!item.user_favorite) {
      this.favorite = (item.user_favorite.length > 0) ? true : false;
    }
    this.haveSeen = parseInt(item.haveSeen);
    this.comments = orderBy(item.comments, ['pk_comment'], ['desc']);
    this.stars = item.stars || 0;
    this.about_the_operator = item.about_the_operator;
    this.why_live_this_experience = item.why_live_this_experience;
    this.whats_nearby = item.whats_nearby;
    this.other_rules = item.other_rules;
    this.whatDoesNotInclude = item.what_does_not_include || []
    this.whatToTake = item.what_to_take || []
    this.whatToWear = item.what_to_wear || []
    this.whatToKnow = item.what_to_know || []
    this.requirements = item.requirements || []
    this.important = item.important || []
    this.frequentlyQuestion = item.frequentlyQuestion || []

    this.same_locale_and_meeting_point = item.same_locale_and_meeting_point;
    this.meeting_point_lat = item.meeting_point_lat;
    this.meeting_point_lng = item.meeting_point_lng;

    this.url = item.url
    this.urn = item.urn

    this.peopleWhoDidTheAdventure = item.peopleWhoDidTheAdventure || 0

    this.tags = item.tags;

    //terms
    this.terms = item.terms || ''
    this.privacyTerm = item.privacyTerm || ''
    this.cancel_rules = item.cancel_rules || ''
    this.reserve_observation_enabled = item.reserve_observation_enabled || false
    this.request_observation_enabled = item.request_observation_enabled || false
    this.local_payment_enabled = item.local_payment_enabled || false
    this.local_payment_title = item.local_payment_title || ''
    this.local_payment_description = item.local_payment_description || ''
    this.local_payment_block_days = item.local_payment_block_days || 0
    this.local_payment_block_message = item.local_payment_block_message || ''
    this.credit_card_enabled = item.credit_card_enabled || false
    this.credit_card_block_days = item.credit_card_block_days || 0
    this.credit_card_block_message = item.credit_card_block_message || ''
    this.reserve_roca_enabled = item.reserve_roca_enabled || false
    this.boleto_enabled = item.boleto_enabled || false
    this.pix_enabled = item.pix_enabled || false
    this.boleto_block_days = item.boleto_block_days || 0
    this.boleto_block_message = item.boleto_block_message || ''
    this.picpay_enabled = item.picpay_enabled || false
    this.picpay_block_days = item.picpay_block_days || 0
    this.picpay_block_message = item.picpay_block_message || ''
    this.pagseguro_enabled = item.pagseguro_enabled || false
    this.pagseguro_block_days = item.pagseguro_block_days || 0
    this.pagseguro_block_message = item.pagseguro_block_message || ''
    this.getnet_enabled = item.getnet_enabled || false
    this.getnet_block_days = item.getnet_block_days || 0
    this.getnet_block_message = item.getnet_block_message || ''
    this.mercado_pago_enabled = item.mercado_pago_enabled || false
    this.mercado_pago_block_days = item.mercado_pago_block_days || 0
    this.mercado_pago_block_message = item.mercado_pago_block_message || ''
    this.fill_all_reserves = item.fill_all_reserves || false
    this.nextDate = (item.nextDate && item.nextDate.date) ? item.nextDate.date : undefined
    this.request_success_message = item.request_success_message || ''
    this.terms_on_request = item.terms_on_request || false
    this.terms_on_reserve = item.terms_on_reserve || false
    this.payment_on_request = item.payment_on_request || false
    this.request_payment_min = Number(item.request_payment_min) || 0
    this.request_payment_max = Number(item.request_payment_max) || 0
    this.packageForRequest = item.packageForRequest ? new Package(item.packageForRequest) : undefined
    this.useScheduleRulePackages = item.use_schedule_rule_packages || false
  }

  @action
  formatPackagesCategory() {
    let packagesCategory = groupBy(this.packages, (r) => {
      return r.fkPackageCategory;
    });

    packagesCategory = Object.keys(packagesCategory).map(function (_) { return packagesCategory[_]; });
    this.packagesCategory = packagesCategory;
  }

  @computed
  get durationFormatted() {
    const durationArr = this.duration.split(":")

    return moment.duration({
      hours: parseFloat(durationArr[0], 10),
      minutes: parseFloat(durationArr[1], 10)
    }).format("d[d] h[h] m[m] s[s]", { trim: "both" })
  }
}