/**
 * Created by matheusmorett on 17/05/18.
 */
import React from 'react';

import './Button.scss';

export default class Button extends React.Component {

  static defaultProps = {
    onClick: () => {
    },
    onClickDisabled: () => {
    }
  };

  handleClick(e) {
    if (!this.props.disabled && !this.props.loading) {
      this.props.onClick(e);
    } else {
      this.props.onClickDisabled(e);
    }
  }

  render() {
    const content = (this.props.value) ? this.props.value : this.props.children
    const className = "btn " + this.props.type + " " +
      (this.props.size && this.props.size) + " " +
      (this.props.width && this.props.width) + " " +
      (this.props.disabled && "disabled") + " " + this.props.className

    if (this.props.loading || this.props.disabled) {
      return (
        <button onClick={this.handleClick.bind(this)} className={className} style={this.props.style} disabled>
          {(this.props.loading) ? <i className='fa fa-spinner fa-spin'></i> : content}
          {this.props.icon && <span className={(this.props.icon) + " button-icon"}/>}
        </button>);

    } else {
      return (
        <button onClick={this.handleClick.bind(this)} className={className} style={this.props.style}>
          {content}
          {this.props.icon && <span className={(this.props.icon) + " button-icon"}/>}
        </button>);
    }
  }
}
