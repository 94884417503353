/**
 * Created by matheusmorett on 16/08/17.
 */
import axios from 'axios';
import querystring from 'querystring';
import { API_URI } from './Env';
import ApplicationStore from 'stores/ApplicationStore';

class Api {

  call(url, method, params = {}, image = false, json = false, version = 'v1') {
    if (method === 'get') {
      const getParams = [];
      Object.keys(params).map((key) => getParams.push(key + '=' + params[key]));

      if (getParams.length) {
        url += '?' + getParams.join('&');
      }

      params = {};
    }

    let contentType = 'application/x-www-form-urlencoded';

    if (json === true) {
      contentType = 'application/json';
    }

    if (image) {
      contentType = 'multipart/form-data';
    }

    return axios({
      url: url,
      method: method,
      baseURL: API_URI + version,
      headers: {
        'accept': 'application/json',
        'Content-Type': contentType,
        'Access-Control-Allow-Origin': 'http://dev.back.adrenalyze.com.br/',
        'Authorization': this.getAuthorization()
      },
      data: (image === false && json === false) ? querystring.stringify(params) : params
    }).then(function (response) {
      return response
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) { // Se for erro de autorizacao, atualizar sessao
          localStorage.setItem("user", null);
          localStorage.setItem("isLogged", false);
          Location.reload();
          throw error.response.data
        }

        if (error.response.status === 301) { // Se for erro de autorizacao, atualizar sessao
          ApplicationStore.setRedirect({
            status: true,
            page: error.response.data.data.message
          })
          throw error.response.data
        }
      }

      throw error.response.data
    });
  }

  getAuthorization() {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user)
        return user.auth_key
    } catch (error) {
      // pass
    }

    return ""
  }

}

export default new Api();
