/**
 * Created by matheusmorett on 9/14/17.
 */
// References MoreAdventure
export const NORMAL_SEARCH = 1;
export const FOR_YOU = 2;
export const MORE_RECOMMENDED = 3;
export const SUGGESTED_ADVENTURES = 4;
export const BY_CATEGORY = 5;
export const BY_TYPE = 6;
export const BY_TAG = 7;
export const BY_REGION = 8;

// Order By on find by category
export const ORDER_BY_RELEVANCE = "title";
export const ORDER_BY_PRICE = "price";

// References Checkout
export const PUBLIC_BUTTON = 1;
export const PRIVATE_BUTTON = 2;

// Tabs on profile
export const PROFILE = 1;
export const MY_ADVENTURES = 2;
export const HISTORIC = 3;
export const FAVORITES = 4;
export const MESSAGES = 5;

// Faq consts
export const FAQ = "FAQ-DIV";
export const ABOUT = "ABOUT-DIV";
export const DOUBTS = "DOUBTS-DIV";
export const DISCOUNTS = "DISCOUNTS-DIV";

// Discount
export const NOMINAL = 0;
export const PERCENTAGE = 1;


// Login
export const OPEN_LOGIN = 1;

// Modal styles
export const MODAL_STYLES = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        background: 'none',
        boxShadow: 'none',
        zIndex: '999',
        backgroundColor: "rgba(255, 255, 255, 0.9);"
    }
};

// Like types
export const LIKE = 1;
export const DISLIKE = 0;

// Adventure types
export const ADVENTURE = 1;
export const EVENT = 2;

// Payment Method
export const CREDIT_CARD = "CREDIT_CARD";
export const BOLETO = "BOLETO";
export const LOCAL = "LOCAL";
export const MERCADO_PAGO = "MERCADO_PAGO";
export const PAGSEGURO = "PAGSEGURO";
export const PICPAY = "PICPAY";
export const GETNET = "GETNET";
export const PIX = "PIX";

export const METHODS_LABELS = {
    [CREDIT_CARD]: "Cartão de Crédito",
    [BOLETO]: "Boleto",
    [LOCAL]: "Pagamento Direto",
    [MERCADO_PAGO]: "Mercado Pago",
    [PAGSEGURO]: "PagSeguro",
    [PICPAY]: "PicPay",
    [GETNET]: "Getnet",
    [PIX]: "Pix"
}

export const COUNTRIES = [
"Brasil",
"Afeganistão",
"África do Sul",
"Akrotiri",
"Albânia",
"Alemanha",
"Andorra",
"Angola",
"Anguila",
"Antárctida",
"Antígua e Barbuda",
"Arábia Saudita",
"Arctic Ocean",
"Argélia",
"Argentina",
"Arménia",
"Aruba",
"Ashmore and Cartier Islands",
"Atlantic Ocean",
"Austrália",
"Áustria",
"Azerbaijão",
"Baamas",
"Bangladeche",
"Barbados",
"Barém",
"Bélgica",
"Belize",
"Benim",
"Bermudas",
"Bielorrússia",
"Birmânia",
"Bolívia",
"Bósnia e Herzegovina",
"Botsuana",
"Brunei",
"Bulgária",
"Burquina Faso",
"Burúndi",
"Butão",
"Cabo Verde",
"Camarões",
"Camboja",
"Canadá",
"Catar",
"Cazaquistão",
"Chade",
"Chile",
"China",
"Chipre",
"Clipperton Island",
"Colômbia",
"Comores",
"Congo-Brazzaville",
"Congo-Kinshasa",
"Coral Sea Islands",
"Coreia do Norte",
"Coreia do Sul",
"Costa do Marfim",
"Costa Rica",
"Croácia",
"Cuba",
"Curacao",
"Dhekelia",
"Dinamarca",
"Domínica",
"Egipto",
"Emiratos Árabes Unidos",
"Equador",
"Eritreia",
"Eslováquia",
"Eslovénia",
"Espanha",
"Estados Unidos",
"Estónia",
"Etiópia",
"Faroé",
"Fiji",
"Filipinas",
"Finlândia",
"França",
"Gabão",
"Gâmbia",
"Gana",
"Gaza Strip",
"Geórgia",
"Geórgia do Sul e Sandwich do Sul",
"Gibraltar",
"Granada",
"Grécia",
"Gronelândia",
"Guame",
"Guatemala",
"Guernsey",
"Guiana",
"Guiné",
"Guiné Equatorial",
"Guiné-Bissau",
"Haiti",
"Honduras",
"Hong Kong",
"Hungria",
"Iémen",
"Ilha Bouvet",
"Ilha do Natal",
"Ilha Norfolk",
"Ilhas Caimão",
"Ilhas Cook",
"Ilhas dos Cocos",
"Ilhas Falkland",
"Ilhas Heard e McDonald",
"Ilhas Marshall",
"Ilhas Salomão",
"Ilhas Turcas e Caicos",
"Ilhas Virgens Americanas",
"Ilhas Virgens Britânicas",
"Índia",
"Indian Ocean",
"Indonésia",
"Irão",
"Iraque",
"Irlanda",
"Islândia",
"Israel",
"Itália",
"Jamaica",
"Jan Mayen",
"Japão",
"Jersey",
"Jibuti",
"Jordânia",
"Kosovo",
"Kuwait",
"Laos",
"Lesoto",
"Letónia",
"Líbano",
"Libéria",
"Líbia",
"Listenstaine",
"Lituânia",
"Luxemburgo",
"Macau",
"Macedónia",
"Madagáscar",
"Malásia",
"Malávi",
"Maldivas",
"Mali",
"Malta",
"Man, Isle of",
"Marianas do Norte",
"Marrocos",
"Maurícia",
"Mauritânia",
"México",
"Micronésia",
"Moçambique",
"Moldávia",
"Mónaco",
"Mongólia",
"Monserrate",
"Montenegro",
"Mundo",
"Namíbia",
"Nauru",
"Navassa Island",
"Nepal",
"Nicarágua",
"Níger",
"Nigéria",
"Niue",
"Noruega",
"Nova Caledónia",
"Nova Zelândia",
"Omã",
"Pacific Ocean",
"Países Baixos",
"Palau",
"Panamá",
"Papua-Nova Guiné",
"Paquistão",
"Paracel Islands",
"Paraguai",
"Peru",
"Pitcairn",
"Polinésia Francesa",
"Polónia",
"Porto Rico",
"Portugal",
"Quénia",
"Quirguizistão",
"Quiribáti",
"Reino Unido",
"República Centro-Africana",
"República Dominicana",
"Roménia",
"Ruanda",
"Rússia",
"Salvador",
"Samoa",
"Samoa Americana",
"Santa Helena",
"Santa Lúcia",
"São Bartolomeu",
"São Cristóvão e Neves",
"São Marinho",
"São Martinho",
"São Pedro e Miquelon",
"São Tomé e Príncipe",
"São Vicente e Granadinas",
"Sara Ocidental",
"Seicheles",
"Senegal",
"Serra Leoa",
"Sérvia",
"Singapura",
"Sint Maarten",
"Síria",
"Somália",
"Southern Ocean",
"Spratly Islands",
"Sri Lanca",
"Suazilândia",
"Sudão",
"Sudão do Sul",
"Suécia",
"Suíça",
"Suriname",
"Svalbard e Jan Mayen",
"Tailândia",
"Taiwan",
"Tajiquistão",
"Tanzânia",
"Território Britânico do Oceano Índico",
"Territórios Austrais Franceses",
"Timor Leste",
"Togo",
"Tokelau",
"Tonga",
"Trindade e Tobago",
"Tunísia",
"Turquemenistão",
"Turquia",
"Tuvalu",
"Ucrânia",
"Uganda",
"União Europeia",
"Uruguai",
"Usbequistão",
"Vanuatu",
"Vaticano",
"Venezuela",
"Vietname",
"Wake Island",
"Wallis e Futuna",
"West Bank",
"Zâmbia",
"Zimbabué"
]
