/**
 * Created by matheusmorett on 16/03/18.
 */
function _env() {
    const location = window.location.href;

    if (location.includes('hero99.adrenalyze.com.br')) {
        const env = 'homolog';
        return env;
    }

    if (location.includes('adrena.me') || location.includes('adrenalyze.com.br')) {
        const env = 'prod';
        return env;
    }

    return process.env.NODE_ENV;
}

const apiUrl = () => {
    if (_env() === 'prod') {
        return "-----BEGIN PUBLIC KEY-----" +
            "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAiw0xgHh3+sLpzFr9dQ7n" +
        "qaZKNzQdZG0slro4qLuFsn87j28qqPnC052M7ffKFquGi8aRJ1dvteIF2iER8wPF" +
        "pKKObWXxF6BBEGqiXBZKkizk7tS/ZVUkqAbAQ9SyB2BdZiE3FUXbzbfBL01YU1Qm" +
        "MKjxGHhNhgO4iOEKrZYuDeFY+W/2iPM4ZAIVxMM7KAIWH5IxkQBNrOEZKOMt9bjZ" +
        "N/EZK6cemqbUVKWutnpNSZFVROSF5jxJoAVNGc0u1zgSM+HeYMMkByY2kAGdrSWT" +
        "MV8eCJP/Hk08AaRTD9v6+ePojCUIVqOSTut4TQB8UUFTgp5fIpwQuORcjVY12w8D" +
        "bQIDAQAB" +
        "-----END PUBLIC KEY-----";
    } else {
        return "-----BEGIN PUBLIC KEY-----" +
            "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA3yt41v+Bza72LinHTWOl" +
        "ztj1Ba1aSINGc16bY8pZYX6rug4pSO17iYbfWK+C7Yfkxvp3siailRxFtzNd8wvN" +
        "gsyIa8T7/JOiiJs9T8ByL1fNWGYKmgOia0UilfkvufzZkgcrNubHAalrfhKhCyly" +
        "99Af9xc3k2jtR9JUTeD89ixcahUZQBmQRSTm0NHeAtECeQBgAQgXmu6hXTsu7UwA" +
        "jdOZtr8435TiqVNLqeikKNTaF/SOLNsC3ee88F4EpSDEFQFjUk3jr6LdCCL51iJg" +
        "/oYnM0tqFPr4Sl/EJoYLFoMb1tHpSIbnb/JK4ZKK9AZw0y6fWFtOR0j67MA8p8AM" +
        "vwIDAQAB" +
        "-----END PUBLIC KEY-----";
    }

};

export const MOIP_TOKEN = apiUrl();

