/**
 * Created by matheusmorett on 05/09/18.
 */
import React, {Component} from 'react';

import "./PageLoader.scss";
import loadingGif from './assets/loading.gif';

export default class PageLoader extends Component {

    static defaultProps = {
        loading: true
    };

    render() {
        const { loading, children } = this.props;

        if(loading) {
            return (
                <div className="PageLoader" style={this.props.style}>
                    <div className="loader">
                        {/* <div className="loader-ball loader-ball-1"></div>
                        <div className="loader-ball loader-ball-2"></div>
                        <div className="loader-ball loader-ball-3"></div> */}
                        <img src={loadingGif} alt="Carregando"/>
                    </div>
                </div>
            );
        }

        return (<div style={this.props.styleLoaded}>{children}</div>);
    }

}
