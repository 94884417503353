/**
 * Created by matheusmorett on 8/29/17.
 */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import axios from 'axios'
import moment from 'moment';
import google from '@google/maps';
import './MainSearch.scss';
import 'react-dates/lib/css/_datepicker.css';
import Utils from '../../utils/Utils';
const maps = google.createClient({key: 'AIzaSyBHN0W3GT8wkiI3JO8XmD-ZaG-joTURXs0'})

@inject('SearchStore', 'AuthStore', 'LocationStore', 'ApplicationStore')
@observer
class MainSearch extends Component {

    static defaultProps = {
        onChangeLatLng: () => { },
        onChangeAttributes: () => { },
    };

    constructor(props) {
        super(props);

        this.state = {
            searchValue: (this.props.searchValue) ? this.props.searchValue : "",
            type: (this.props.type) ? this.props.type : "",
            items: [],
            focusFilter: "display-none",
            focusFilterCity: "display-none",
            city: (this.props.city) ? this.props.city : "",
            hasLocation: false,
            loadingCity: false,
            modalIsOpen: false,
            lat: "",
            lng: "",
            date: (this.props.date) ? this.props.date : null,
            focused: false
        };

        const { LocationStore } = this.props;
        LocationStore.setCity(this.props.city);
        this.closeModal = this.closeModal.bind(this)
        this.handleGoTo = this.handleGoTo.bind(this)
    }


    componentWillMount() {

        const { searchValue } = this.state;
        this.getItems(searchValue);
        // Populate first the categories
        if (navigator.geolocation && (this.state.city === "")) {
            navigator.geolocation.getCurrentPosition(this.setLocation.bind(this), this.setErrorLocation.bind(this));
        }

        document.body.addEventListener('click', this.focusFilter.bind(this), true);
    }


    handleRemoveDate() {
        this.setState({
            date: null
        })
    }

    handleFilter(e) {
        const value = e.target.value;
        this.getItems(value);
        this.setState({
            searchValue: value,
            type: ""
        });
    }

    getItems(value) {
        const { SearchStore } = this.props;

        if (value.length >= 3) {
            let params;

            if (this.state.hasLocation) {
                params = {
                    lat: SearchStore.coords.lat,
                    lng: SearchStore.coords.lng,
                    text: value
                };
            } else {
                params = {
                    text: value
                }
            }

            SearchStore.getItems(params);
        }
    }

    focusFilter(e) {
        if (e.target.id === "filter-input" || e.target.id === "filter-list") {
            this.setState({
                focusFilter: "",
                focusFilterCity: "display-none"
            });
        } else if (e.target.id === "filter-city") {
            this.setState({
                focusFilter: "display-none",
                focusFilterCity: "",
            });
        } else {
            this.setState({
                focusFilter: "display-none",
                focusFilterCity: "display-none"
            });
        }
    }

    handleChangeCity(e) {
        if (!this.state.loadingCity) {
            const params = {
                text: e.target.value
            };
            this.setState({
                city: params.text
            });
            this.props.SearchStore.getCity(params);
        }
    }

    selectCity(e) {
        this.setState({
            city: e.target.value
        });
        const { LocationStore } = this.props;
        LocationStore.setCity(e.target.value);
    }

    getCity(geocoder, params, page) {
        const { LocationStore } = this.props;

        geocoder.reverseGeocode({latlng: `${params.lat},${params.lng}`}, (err, res) => {
            if (!err && res.status === 200) {
                const results = res.json.results
                if (results[0].address_components[3].types[0] !== "country") {
                    if (results[1]) {
                        page.setState({
                            city: results[0].address_components[3].long_name,
                            loadingCity: false
                        });

                        // LocationStore.setCity(results[0].address_components[3].long_name);
                        LocationStore.getRegionByUrl(Utils.formatUrl(results[0].address_components[3].long_name))
                        .then(region => {
                            if (region) {
                                LocationStore.setRegion(region);
                            } else {
                                LocationStore.getRegionByLatLng({lat: params.lat, lng: params.lng})
                                .then(region => {
                                    if (region) {
                                        LocationStore.setRegion(region);
                                    }
                                })
                            }
                        })

                        const params = {
                            text: results[0].address_components[3].long_name
                        };
                        page.props.SearchStore.getCity(params);

                    } else {
                        window.alert('No results found');
                    }
                }
            } else {
                console.error('Geocoder failed');
                console.error(err);
            }
        });
    }

    setLocation(position) {
        const { SearchStore, LocationStore } = this.props;

        this.setState({
            loadingCity: true
        });

        let params;

        if (position.code === 1) {
            params = {
                text: ""
            };
        } else {
            params = {
                lat: parseFloat(position.coords.latitude),
                lng: parseFloat(position.coords.longitude),
                text: ""
            };

            this.setState({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
                hasLocation: true,
            });
        }

        localStorage.setItem("lat", parseFloat(position.coords.latitude));
        localStorage.setItem("lng", parseFloat(position.coords.longitude));
        LocationStore.setLatLng(position.coords.latitude);
        LocationStore.setLatLng(position.coords.longitude);

        const geocoder = maps;
        this.getCity(geocoder, params, this);

        SearchStore.getItems(params);
    }

    setErrorLocation(error) {
        axios.get('https://www.geoplugin.net/json.gp').then((res) => {
            this.setLocation({
                code: 200,
                coords: {
                    latitude: res.data.geoplugin_latitude,
                    longitude: res.data.geoplugin_longitude,
                }
            })
        })
    }

    handleSetDate(date) {
        this.setState({
            date: date.format("YYYY-MM-DD")
        })
    }

    _renderItems() {
        const { SearchStore, history } = this.props;

        // console.log(typeof SearchStore.SearchResult)

        if (SearchStore.loading === true) {
            return (
                <ul id="filter-list" className={"list-search " + this.state.focusFilter}>
                    <li>Carregando...</li>
                </ul>
            )
        } else if (this.state.searchValue === "" || this.state.searchValue === undefined || this.state.searchValue.length < 3) {
            return (
                <ul id="filter-list" className={"list-search " + this.state.focusFilter}>
                    <li>Procure por 3 letras ou mais.</li>
                </ul>
            )
        } else if (SearchStore.SearchResult.length > 0) {
            return (<ul id="filter-list" className={"list-search " + this.state.focusFilter}>
                {
                    SearchStore.SearchResult.map((item, i) => {
                        return (
                            <li key={i} onClick={() => {
                                this.setState({ modalIsOpen: false, focusFilter: "display-none", searchValue: "" })
                                history.push(item.url)
                            }}>
                                {item.title}
                            </li>
                        );
                    })
                }
            </ul>);
        } else {
            return (
                <ul id="filter-list" className={"list-search " + this.state.focusFilter}>
                    <li>Nenhum resultado encontrado.</li>
                </ul>
            )
        }
    }

    _renderCities() {
        const { SearchStore, LocationStore } = this.props;
        if (SearchStore.SearchResultCity.length > 0) {
            return (<ul id="filter-list-city" className={"list-search-city " + this.state.focusFilterCity}>
                {
                    this.props.SearchStore.SearchResultCity.map((item, i) => {
                        return (
                            <li key={i} onClick={() => {
                                this.props.SearchStore.getItems({
                                    text: this.state.searchValue,
                                    lat: item.lat,
                                    lng: item.lng
                                });
                                this.setState({
                                    modalIsOpen: false,
                                    city: item.city,
                                    lat: item.lat,
                                    lng: item.lng,
                                });
                                LocationStore.setCity(item.city);
                                localStorage.setItem("lat", item.lat);
                                localStorage.setItem("lng", item.lng);
                            }}>
                                {item.city} - {item.UF}
                            </li>
                        )
                    })
                }
            </ul>);
        } else if (SearchStore.loadingCity === true) {
            return (<ul id="filter-list-city" className={"list-search-city " + this.state.focusFilterCity}>
                <li>Carregando...</li>
            </ul>
            )
        } else {
            return (<ul id="filter-list-city" className={"list-search-city " + this.state.focusFilterCity}>
                <li>Nenhum resultado encontrado.</li>
            </ul>
            )
        }
    }

    handleGoTo(e) {
        const kCd = e.keyCode || e.which;
        if (kCd === 13) {
            this.setState({ modalIsOpen: false, focusFilter: "display-none" })
            let dateFormated = "";
            if (this.state.date !== null && this.state.date !== "") {
                dateFormated = moment(this.state.date).format("YYYY-MM-DD");
            }
            const { history } = this.props;
            const goTo = "/pesquisar?search=" + (this.state.searchValue !== "" ? this.state.searchValue : "") + "&type=" + (this.state.type !== "" ? this.state.type : "") + "&city=" + (this.state.city !== "" && this.state.city !== undefined ? this.state.city : "") + "&date=" + (this.state.date !== "" && this.state.date !== null ? dateFormated : "")
            history.push(goTo);
        }
    }

    closeModal = () => this.setState({ modalIsOpen: false })

    render() {
        const list = this._renderItems();
        // const listCity = this._renderCities();
        // let dateFormated = "";
        // let dateInMoment = "";
        // if (this.state.date !== null && this.state.date !== "") {
        //     dateFormated = moment(this.state.date).format("YYYY-MM-DD");
        //     dateInMoment = moment(this.state.date);
        // }
        const smallDevice = window.matchMedia('(max-width: 768px)').matches;

        if (smallDevice) {
            return (
                <div className="Grid Grid--center main-search-mobile">
                    <span className="fa fa-search" onClick={() => this.setState({ modalIsOpen: true })}></span>
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.closeModal}
                        contentLabel="Pesquisar"
                        style={{content: { width: 'auto', top: 0, left: 0, right: 0, bottom: 0}}}
                    >
                        <div className="box-modal">
                            <div className="Grid Grid--gutter Grid--center">
                                <div className="Grid-cell" style={{ flex: 3 }}>
                                    <div className="main-search search-field-mobile">
                                        <div className="Grid Grid-Cell Grid--center">
                                            <span className='fa fa-bullseye'></span>
                                            <input id="filter-input" className=""
                                                value={this.state.searchValue}
                                                placeholder="Escolha a sua próxima aventura…"
                                                onChange={this.handleFilter.bind(this)}
                                                onClick={this.focusFilter.bind(this)}
                                                onFocus={this.focusFilter.bind(this)}
                                                onBlur={this.focusFilter.bind(this)}
                                                onKeyPress={this.handleGoTo.bind(this)}
                                                autoComplete="off"
                                            />
                                        </div>
                                        {list}
                                    </div>
                                </div>
                                <div className="Grid-cell" style={{ flex: 1 }}>
                                    <div className="close-modal">
                                        <p className="float-right" onClick={this.closeModal}>
                                            Fechar X
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            )
        } else {
            return (
                <div className="MainSearchContainer" >
                    <div className="Grid Grid--center main-search-content">
                        <div className="main-search search-field">
                            <div className="Grid Grid-Cell Grid--center">
                                <span className='fa fa-bullseye'></span>
                                <input id="filter-input" className=""
                                    value={this.state.searchValue}
                                    placeholder="Escolha a sua próxima aventura…"
                                    onChange={this.handleFilter.bind(this)}
                                    onClick={this.focusFilter.bind(this)}
                                    onFocus={this.focusFilter.bind(this)}
                                    onBlur={this.focusFilter.bind(this)}
                                    onKeyPress={this.handleGoTo.bind(this)}
                                    autoComplete="off"
                                />
                            </div>
                            {list}
                        </div>
                        <Link className="search-btn" to={"/pesquisar?search=" + (this.state.searchValue !== "" ? this.state.searchValue : "")}>
                            <span className="fa fa-search"></span>
                        </Link>
                    </div>
                </div>
            )
        }

    }
}
export default MainSearch;