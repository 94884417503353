import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import axios from 'axios'
import Modal from 'react-modal';
import Button from './Button';

const boxStyles = {
    background: '#fefefe', 
    padding: "2%", 
    borderRadius: "2%",
    border: "1px solid #ccc",
}

@inject('ApplicationStore')
@observer
class CookieAcceptance extends Component {
    state = {
        shouldAskCookies: false
    }

    getIpAddress() {
        return axios.get('https://www.cloudflare.com/cdn-cgi/trace').then((res) => {
            const json = res.data.split("\n").reduce((json, item) => {
                const [ key, value ] = item.split("=")
                return { ...json, [key]: value }
            } , {})

            return json.ip
        })
    }

    async checkAcceptance() {
        const { ApplicationStore } = this.props;
        const ipAddress = await this.getIpAddress();
        const accepted = await ApplicationStore.checkCookieAcceptance(ipAddress)
        this.setState({ shouldAskCookies: !accepted })
    }

    async handleAccept() {
        const { ApplicationStore } = this.props;
        this.setState({ shouldAskCookies: false })
        const ipAddress = await this.getIpAddress();
        await ApplicationStore.acceptCookies(ipAddress)
    }

    componentDidMount() {
        this.checkAcceptance()
    }

    render() {
        return (
            <Modal isOpen={this.state.shouldAskCookies} className="modal-cookie" >
                <div className="container-modal-cookie">
                    <h2>Uso de cookies</h2>
                    <div className="Grid Grid--center container-content">
                        <div className="Grid-cell" style={{ marginRight: "2%" }}>
                            <p>O <span className="text-pink">Adrena.me</span> pode usar cookies para lembrar seus detalhes de login e melhorar o uso do site. Aceite os termos para prosseguir.</p>
                        </div>
                        <div className="">
                            <Button
                                size="sm" 
                                onClick={this.handleAccept.bind(this)}
                                value="Aceitar"
                                type="primary" />
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default CookieAcceptance
