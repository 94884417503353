/**
 * Created by matheusmorett on 11/04/18.
 */
import React, { Component } from 'react';

import PageLoader from "components/PageLoader";

export default function AsyncComponent(getComponent) {
    class AsyncComponent extends Component {
        constructor(props) {
            super(props);
            this.Component = null;
            this.state = { Component: this.Component, hotjarInitialized: false }
        }

        componentWillMount() {
            if (!this.state.Component) {
                getComponent().then(Component => {
                    AsyncComponent.Component = Component;
                    this.setState({ Component })
                })
            }
        }

        render() {
            const { Component } = this.state;

            return Component
                ? <Component {...this.props} />
                : <PageLoader />
        }
    }
    return AsyncComponent
}