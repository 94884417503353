/**
 * Created by matheusmorett on 23/08/18.
 */
import React, {Component} from 'react';

import './Card.scss';

export default class Card extends Component {
    render() {
        return (
            <div className="Card">
                {this.props.children}
            </div>
        );
    }

}
