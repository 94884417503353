import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory'
import { Provider } from 'mobx-react';
import baseName from './utils/Basename';

import SiteTemplate from './templates/SiteTemplate';
import MinimalTemplate from './templates/MinimalTemplate';
import BlankTemplate from './templates/BlankTemplate';

import AuthStore from './stores/AuthStore';
import ApplicationStore from './stores/ApplicationStore';
import CategoriesStore from './stores/CategoriesStore';
import AdventuresStore from './stores/AdventuresStore';
import SearchStore from './stores/SearchStore';
import LocationStore from './stores/LocationStore';
import PaymentStore from './stores/PaymentStore';
import RequestStore from './stores/RequestStore';
import HighlightStore from './stores/HighlightStore';
import CheckoutStore from './modules/Checkout/CheckoutStore';
import TypeStore from './stores/TypeStore';
import FeedbackStore from './stores/FeedbackStore';
import CompanysStore from './stores/CompanysStore';

import AsyncComponent from 'components/Hocs/AsyncComponent';

const CalendarWidget = AsyncComponent(() =>
    import
        ("modules/Adventure/containers/CalendarWidget").then(module => module.default)
);

const Checkout = AsyncComponent(() =>
    import
        ("modules/Checkout/containers/Checkout").then(module => module.default)
);

const history = createHistory({
    basename: baseName,
});

const stores = {
    AuthStore,
    AdventuresStore,
    CategoriesStore,
    SearchStore,
    PaymentStore,
    RequestStore,
    ApplicationStore,
    LocationStore,
    HighlightStore,
    CheckoutStore,
    TypeStore,
    FeedbackStore,
    CompanysStore
};

const App = () => {
    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            const { scrollHeight, offsetHeight } = entries[0].target
            window.top.postMessage({ type: 'resize', height: Math.max(scrollHeight, offsetHeight) + 80 }, '*')
        })

        observer.observe(document.querySelector("#root"))
        return () => {
            observer.disconnect()
        }
    }, [])

    return (
        <Provider {...stores}>
            <Router history={history} basename={baseName}>
                <Switch>
                    <Route path='/calendario' component={BlankTemplate} />
                    <Route path='/site' component={MinimalTemplate} />
                    <Route path='/:region/:category/:id/:token' component={MinimalTemplate} />
                    <Route component={SiteTemplate} />
                </Switch>
            </Router>
        </Provider>
    )
}

ReactDOM.render(( <App /> ), document.getElementById('root'));

