/**
 * Created by matheusmorett on 16/08/17.
 */
import {observable, action, computed} from 'mobx';
import Api from './../utils/Api';

class AuthStore {

  @observable loading = false;
  @observable authUser;
  @observable isLogged = false;
  @observable reloadPageAfterLogin = true;
  @observable pageAfterLogin = "/";

  constructor() {

    this.getUser();
    try { 
      if (localStorage.getItem("user") &&
        localStorage.getItem("isLogged")) {
        this.authUser = JSON.parse(localStorage.getItem("user"));
        this.isLogged = JSON.parse(localStorage.getItem("isLogged"));
      }
    } catch (error) {
      // pass
    }

  }

  @computed
  get isUserPersisted() {
    return !!this.authUser && !!JSON.parse(localStorage.getItem("user"))
  }

  @action
  getUser() {
    return Api.call('/user/get-user', 'get', {}).then((response) => {
      if (response && response.data !== "") {
        this.authUser = response.data.data;
        localStorage.setItem("user", JSON.stringify(this.authUser));
      }
    });
  }

  @action
  updateUser(params) {

    this.loading = true;

    return Api.call('/user/update-user', 'post', params).then((response) => {

      this.loading = false;

      if (response.data.success === true) {

        this.authUser = response.data.data;
        localStorage.setItem("user", JSON.stringify(this.authUser));

      }

      return response.data;

    })
  }

  @action
  updateUserImage(params) {

    this.loading = true;

    return Api.call('/user/update-user-image', 'post', params, true).then((response) => {

      this.loading = false;

      if (response.data.success === true) {

        this.authUser = response.data.data;
        localStorage.setItem("user", JSON.stringify(this.authUser));

      }

    });

  }

  @action
  register(params) {

    this.loading = true;

    return Api.call('/user/register', 'post', params).then((response) => {

      this.loading = false;
      return response;

    }).catch(err => {
      this.loading = false;
      throw err;
    });

  }

  @action
  login(params) {

    this.loading = true;

    return Api.call('/user/login', 'post', params).then((response) => {

      this.loading = false;

      if (response.data.success) {

        this.authUser = response.data.data;
        this.isLogged = true;

      } else {

        this.authUser = null;
        this.isLogged = false;

      }

      localStorage.setItem("user", JSON.stringify(this.authUser));
      localStorage.setItem("isLogged", this.isLogged);

      return response.data;

    }).catch(err => {
      this.loading = false;
      throw err;
    })

  }

  @action
  loginByPk(pkUser){
    this.loading = true;

    return Api.call('/user/login-by-pk', 'post', { pkUser }).then((response) => {

      this.loading = false;

      if (response.data.success) {

        this.authUser = response.data.data;
        this.isLogged = true;

      } else {

        this.authUser = null;
        this.isLogged = false;

      }

      localStorage.setItem("user", JSON.stringify(this.authUser));
      localStorage.setItem("isLogged", this.isLogged);

      return response.data;

    }).catch(err => {
      this.loading = false;
      throw err;
    })
  }

  logOut() {
    localStorage.setItem("user", null);
    localStorage.setItem("isLogged", false);
    this.isLogged = false;
    this.authUser = null;
    return true;
  }

  @action
  loginSocial(params) {

    this.loading = true;

    return Api.call('/user/social-login', 'post', params).then((response) => {

      this.loading = false;

      if (response.data.success) {
        this.authUser = response.data.data;
        this.isLogged = true;
      } else {
        this.authUser = null;
        this.isLogged = false;
      }

      localStorage.setItem("user", JSON.stringify(this.authUser));
      localStorage.setItem("isLogged", this.isLogged);

      return response.data;

    });

  }

  @action
  registerLead(params) {

    this.loading = true;

    return Api.call('/lead/register', 'post', params).then((response) => {
      this.loading = false;
      return response;
    });

  }


  @action
  forgotPassword(params) {

    return Api.call('/user/forgot-password', 'post', params).then((response) => {
      return response;
    });

  }

  @action
  setReloadPageAfterLogin() {
    this.reloadPageAfterLogin = true;
  }

  @action
  unsetReloadPageAfterLogin() {
    this.reloadPageAfterLogin = false;
  }

  @action
  setPageAfterLogin(page) {
    this.pageAfterLogin = page;
    this.unsetReloadPageAfterLogin();
  }
}

export default new AuthStore();
