import React from 'react'
import Icon from './assets/icon-whatsapp.svg';
import './WhatsApp.scss';

const WhatsApp = ({ phone = '41997788987' }) => {
    const handleClick = () => {
        const url = `https://api.whatsapp.com/send?phone=55${phone}&text=Olá%20galera%20Tudo%20blz%3F%20Estou%20olhando%20o%20site%20de%20vcs%20(na%20p%C3%A1gina%20${encodeURIComponent(window.location.href)})%20e%20queria%20tirar%20algumas%20d%C3%BAvidas!%20`
        window.open(url, '_blank');
    }

    return (
        <div className="adn-icon">
            <div onClick={handleClick}>
                <span className="adn-icon-notification" />
                <img src={Icon} alt="Fale com a gente no WhatsApp" />
            </div>
        </div>
    )
}

export default WhatsApp


// const WhatsApp = ({ history }) => {
//     console.log(history.location)
//     return (
//         <div class="adn-icon">
//             <a href={`https://api.whatsapp.com/send?phone=5541997898887&text=Dae%20galera%20do%20Adrena!%20Tudo%20blz%3F%20Estou%20olhando%20o%20site%20de%20vcs%20(na%20p%C3%A1gina%20${encodeURIComponent(history.location.pathname)})%20e%20queria%20tirar%20algumas%20d%C3%BAvidas!%20`} target="_blank">
//                 <span class="adn-icon-notification" />
//                 <img src={Icon} alt="Fale com a gente no WhatsApp" />
//             </a>
//         </div>
//     )
// }

// export default WhatsApp