import { observable, action } from 'mobx';
import Api from 'utils/Api';

class TypeStore {
    @observable loading = false;
    @observable Type;
    @observable Types = [];

    @action
    async getAll(){
        return Api.call('/type', 'get').then((response) => {
            this.Types = response.data.data
        })
    }

    @action
    getByUrl(params){
        this.loading = true
        return Api.call('/type/get-by-url', 'get', params).then((response) => {
            this.loading = false;
            this.Type = response.data.data
        })
    }
}

export default new TypeStore();