import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Helmet } from "react-helmet";
import Modal from 'react-modal';
import { MODAL_STYLES } from 'utils/Consts';
import Register from "components/Register";
import ForgotPassword from "components/ForgotPassword";
import Login from "components/Login";
import AsyncComponent from 'components/Hocs/AsyncComponent';
import Menu from "components/Menu";
import Footer from "components/Footer";
import 'normalize.css';
import "react-placeholder/lib/reactPlaceholder.css";
import 'assets/stylesheets/grid.scss';
import 'assets/stylesheets/Linearicons.scss';
import 'assets/stylesheets/fonts.scss';
import 'assets/stylesheets/layout.scss';
import { ToastContainer } from 'react-toastify';
import 'assets/stylesheets/site-theme.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import Script from 'react-load-script'
const Adventure = AsyncComponent(() =>
    import
        ("modules/Adventure/containers/Adventure").then(module => module.default)
);

const Checkout = AsyncComponent(() =>
    import
        ("modules/Checkout/containers/Checkout").then(module => module.default)
);

const Company = AsyncComponent(() =>
    import
        ("modules/Company/containers/Company").then(module => module.default)
);

const Invite = AsyncComponent(() =>
    import
        ("modules/Invite/containers/Invite").then(module => module.default)
);

const ReserveDetail = AsyncComponent(() =>
    import
        ("modules/Reserve/containers/ReserveDetail").then(module => module.default)
);

const VoucherSuccess = AsyncComponent(() =>
    import
        ("modules/Checkout/containers/VoucherSuccess").then(module => module.default)
);

const OtherPayment = AsyncComponent(() =>
    import
        ("modules/Checkout/containers/OtherPayment").then(module => module.default)
);

@inject("AuthStore", "ApplicationStore", "CompanysStore")
@observer
class MinimalTemplate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: false
        };
    }

    componentDidMount() {
        const { history, ApplicationStore } = this.props;
        ApplicationStore.setHistory(history);
        ApplicationStore.setMinimalTemplate(true);
    }

    componentDidUpdate(prevProps) {
        const { history } = this.props;
        window.top.postMessage(history.location.pathname + history.location.search, '*');
    }

    handleCloseModal() {
        const { ApplicationStore } = this.props;
        ApplicationStore.closeModal();
    }

    handleGoToPage(page) {
        const { history } = this.props;
        history.push(page);
    }

    render() {
        const { ApplicationStore, AuthStore, CompanysStore } = this.props;

        return (
            <div>
             <Helmet>
                    <title>Adrena.me | Saia da rotina. Viva a adrenalina!</title>
                    <meta property="fb:app_id" content="342755846164051" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Adrena.me | Saia da rotina. Viva a adrenalina!" />
                    <meta property="og:description" content="O Adrena.me traz os esportes de aventura e atividades radicais disponíveis na sua região de forma agradável e descomplicada." />
                    <meta name="description"
                        content="O Adrena.me traz os esportes de aventura e atividades radicais disponíveis na sua região de forma agradável e descomplicada. Agendamento e pagamento* online, rápido e prático. Aventure-se conosco" />
                    <meta property="og:image"
                        content="https://s3-sa-east-1.amazonaws.com/adrenalyze-app/adrenalyze/meta-tag-image-1200x630.jpg" />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <Modal
                    isOpen={ApplicationStore.modal}
                    onRequestClose={() => ApplicationStore.closeModal()}
                    style={MODAL_STYLES}
                    contentLabel="Cadastre-se"
                >
                    <div className="box-modal">
                        <div className="Grid Grid--gutter Grid-column">
                            <div className="Grid-cell">
                                <div className="close-modal">
                                    <p className="float-right" onClick={() => ApplicationStore.closeModal()}>
                                        {(ApplicationStore.modalNotClosable === false) && "Fechar X"}
                                    </p>
                                </div>
                            </div>
                            <div className="Grid-cell">
                                {(ApplicationStore.modalLogin && <Login closeModal={this.handleCloseModal.bind(this)}
                                                                        goToPage={(page) => this.handleGoToPage(page)} /> )}
                                {(ApplicationStore.modalRegister && <Register closeModal={this.handleCloseModal.bind(this)}
                                                                        goToPage={(page) => this.handleGoToPage(page)} /> )}
                                {(ApplicationStore.modalForgotPassword && <ForgotPassword closeModal={this.handleCloseModal.bind(this)}
                                                                        goToPage={(page) => this.handleGoToPage(page)} /> )}
                            </div>
                        </div>
                    </div>
                </Modal>
                <Menu goTo={(to) => this.handleGoToPage(to)} minimal={true} history={this.props.history} match={this.props.match} />
                {!!ApplicationStore.tag_code && 
                <Script url={`https://www.googletagmanager.com/gtm.js?id=${ApplicationStore.tag_code.trim()}`} />
                }
                <ToastContainer
                    position="top-right"
                    type="default"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    pauseOnHover
                />
                <Switch>
                    <Route
                        exact={true}
                        path="/site/:company/:id/checkout"
                        component={Checkout}
                    />
                    <Route
                        exact={true}
                        path="/site/:company/:id/checkout/:token"
                        component={Checkout}
                    />
                    <Route
                        exact={true}
                        path="/site/:company/:id/:token"
                        component={Invite}
                    />
                    <Route
                        exact={true}
                        path="/:region/:category/:id/checkout"
                        component={Checkout}
                    />
                    <Route
                        exact={true}
                        path="/:region/:category/:id/:token"
                        component={Invite}
                    />
                    <Route
                        exact={true}
                        path="/:region/:category/:id/checkout/:token"
                        component={Checkout}
                    />
                    <Route
                        exact={true}
                        path="/site/:company/:id"
                        component={Adventure}
                    />
                    {(AuthStore.isLogged) ?
                    <Route
                        exact={true}
                        path="/site/:company/:id/pedido/:pk_request/:pk_payment"
                        component={ReserveDetail}
                    />
                    : ""}
                    {(this.props.AuthStore.isLogged) ?
                        <Route
                            exact={true}
                            path="/site/:company/:id/voucher/sucesso"
                            component={VoucherSuccess}
                        />
                        : ""}
                    {(this.props.AuthStore.isLogged) ?
                    <Route
                        exact={true}
                        path="/pedido/:pk_request/:pk_payment/detalhes/reserva/sucesso"
                        component={ReserveDetail}
                    />
                    : ""}
                    <Route
                        path="/site/:company"
                        component={Company}
                    />
                    <Route
                        exact={true}
                        path="/site/checkout/otherpayment"
                        component={OtherPayment}
                    />
                </Switch>
                <Footer
                    withoutNewsletter={true}
                    minimal={true}
                />
            </div>
        )
    }
}

export default MinimalTemplate