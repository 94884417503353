/**
 * Created by matheusmorett on 28/03/18.
 */

const baseName = () => {
    if (window.location.href.includes('letsrock')) {
        return "clientes/adrenalyze-web/";
    } else if (window.location.href.includes('adrenalyze.com.br') || window.location.href.includes('adrena.me')) {
        return "";
    } else if (window.location.href.includes('localhost')) {
        return "";
    } else {
        return "clientes/adrenalyze-web/";
    }
};

export default baseName();

