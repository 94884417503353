/**
 * Created by matheusmorett on 28/06/18.
 */
class GTM {

    dispatch(name, vars = {}) {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: name,
                ...vars
            });
            console.log('dispatch', {
                event: name,
                ...vars
            })
        }
    }

    EVENT_SELECT_HOUR = 'OS_SELECT_HOUR'
    EVENT_SHARE_LINK = 'OS_SHARE_LINK'
    EVENT_SELECT_PACKAGES = 'OS_SELECT_PACKAGES'
    EVENT_CREATE_REQUEST = 'OS_CREATE_REQUEST'
    EVENT_END_CHECKOUT = 'OS_END_CHECKOUT'

}

export default new GTM();