import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {observer, inject} from 'mobx-react';

import './FooterEnd.scss';

import logo from './../../assets/img/adrenalyze/adrena-me.svg';
import ContactLinks from '../ContactLinks';

@inject("ApplicationStore", "LocationStore")
@observer
class FooterEnd extends Component {

    componentDidMount() {
        const { LocationStore } = this.props;
        LocationStore.getRegions();
    }

    openModalLogin() {
        const {ApplicationStore} = this.props;
        ApplicationStore.openModalLogin();
    }

    openModalRegister() {
        const {ApplicationStore} = this.props;
        ApplicationStore.openModalRegister();
    }

    handleCloseModal() {
        const {ApplicationStore} = this.props;
        ApplicationStore.closeModal();
    }

    render() {
        const { LocationStore } = this.props;

        return (
            <div className="Grid Grid--center">
                <div className="footer-end">
                    <div className="Grid footer-grid Grid--cols-4">
                        <div className="navbar-brand Grid-cell">
                            <div className="brand-wrapper">
                                <Link to="/">
                                    <img src={logo} alt="Adrena.me"/>
                                </Link>
                            </div>
                        </div>
                        <div className="Grid Grid-cell list">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li>
                                    <div onClick={this.openModalRegister.bind(this)}>Cadastre-se</div>
                                </li>
                                <li>
                                    <div onClick={this.openModalLogin.bind(this)}>Entrar</div>
                                </li>
                                <li>
                                    <a href="https://www.adrena.me/blog" target="_blank">Blog</a>
                                </li>
                            </ul>
                        </div>
                        <div className="Grid Grid-cell list-end">
                            <ul>
                                <li><Link to="/sobre">Sobre</Link></li>
                                <li><Link to="/faq">FAQ</Link></li>
                                <li><Link to="/contato">Contato</Link></li>
                                <li><a href="https://www.offstation.com.br/#demonstracao" target="_blank">Seja parceiro</a></li>
                            </ul>
                        </div>
                        {LocationStore.regions.length > 0 && 
                        <div className="Grid Grid-cell list-end">
                            <ul>
                                {LocationStore.regions.map(region => <li key={region.pk_region}><Link to={`/${region.url_name}`}>{region.name}</Link></li>)}
                            </ul>
                        </div>}
                        <div className="Grid Grid-cell">
                            <ContactLinks />
                        </div>
                    </div>

                </div>
            </div>
        )
    }

}
export default FooterEnd;