/**
 * Created by matheusmorett on 9/26/17.
 */
import { observable, action, computed } from 'mobx';
import Api from '../utils/Api';
import { CREDIT_CARD, PERCENTAGE, PIX } from './../utils/Consts';
import RequestStore from './RequestStore';
import CheckoutStore from '../modules/Checkout/CheckoutStore';

class PaymentStore {

    @observable loading = false;
    @observable loadingDiscount = false;
    @observable discount;
    @observable payment;
    @observable installments = [
        {
            name: "1x R$VA (sem juros)",
            value: 0,
            times: 1,
            selected: true
        },
        {
            name: "2x R$PA = R$VA",
            value: 2.5,
            times: 2,
            selected: false
        },
        {
            name: "3x R$PA = R$VA",
            value: 3.5,
            times: 3,
            selected: false
        },
        {
            name: "4x R$PA = R$VA",
            value: 4.5,
            times: 4,
            selected: false
        },
        {
            name: "5x R$PA = R$VA",
            value: 5.5,
            times: 5,
            selected: false,
        },
        {
            name: "6x R$PA = R$VA",
            value: 6.5,
            times: 6,
            selected: false,
        },
        {
            name: "7x R$PA = R$VA",
            value: 7.5,
            times: 7,
            selected: false,
        },
        {
            name: "8x R$PA = R$VA",
            value: 8.5,
            times: 8,
            selected: false,
        },
        {
            name: "9x R$PA = R$VA",
            value: 9.5,
            times: 9,
            selected: false,
        },
        {
            name: "10x R$PA = R$VA",
            value: 10.5,
            times: 10,
            selected: false,
        },
        {
            name: "11x R$PA = R$VA",
            value: 11.5,
            times: 11,
            selected: false,
        },
        {
            name: "12x R$PA = R$VA",
            value: 12.5,
            times: 12,
            selected: false
        }
    ];

    /* User Payments */
    @observable loadingFuture = false;
    @observable loadingHistoric = false;
    @observable loadingSendParticipants = false;
    @observable historicAdventures = [];
    @observable futureAdventures = [];
    @observable participants = [];

    @action
    payByHash(params) {
        this.loading = true;
        return Api.call('/payment/pay-by-hash', 'post', params, false, true).then((response) => {
            this.loading = false;
            this.payment = response.data.data;

            return response;
        }).catch(err => {
            this.loading = false;
            throw err
        });
    }

    @action
    getCardToken(cardData, appId) {
        this.loading = true;
        const options = {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                card: {
                    number: cardData.cardNumber,
                    holder_name: cardData.holderName,
                    exp_month: cardData.expirationMonth,
                    exp_year: cardData.expirationYear,
                    cvv: cardData.securityCode
                },
                type: 'card'
            })
        };
        return fetch(`https://api.pagar.me/core/v5/tokens?appId=${appId}`, options)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                this.loading = false;
                return response.json();
            }).then(responseData => {
                return responseData;
            }).catch(error => {
                this.loading = false;
                throw error
            });
    }

    @action
    createOrder(params) {
        this.loading = true;
        return Api.call('/payment/create-order', 'post', params, false, true).then((response) => {
            this.loading = false;
            this.payment = response.data.data;

            return response;
        }).catch(err => {
            this.loading = false;
            throw err
        });
    }

    @action
    cancelPayment(payment_id) {
        return Api.call('/payment/cancel-by-id', 'post', { payment_id }, false, true)
    }

    @action
    getPaymentById(paymentId) {
        return Api.call('/payment/get-payment-and-request', 'get', { paymentId }).then(res => res.data)
    }

    @action
    getInfos(params) {
        this.loading = true;
        return Api.call('/request/get-infos', 'get', params).then((response) => {
            this.loading = false;

            this.payment = response.data.data;
            this.participants = response.data.data.reserves;

            return response;
        });
    }

    @action
    changeParticipantValues(value, fieldInfos) {
        const reserve = this.participants.find((r) => r.pk_reserve === fieldInfos.pkReserve);
        const reserveIndex = this.participants.findIndex((r) => r.pk_reserve === fieldInfos.pkReserve);
        if (fieldInfos.field === "name") {
            reserve.name = value;
        }
        if (fieldInfos.field === "document") {
            reserve.document = value;
        }
        this.participants.splice(reserveIndex, 1, reserve);
    }

    @action
    confirmParticipants() {
        this.loadingSendParticipants = true;

        return Api.call('/reserve/confirm-participants', 'post', {
            reserves: this.participants
        }, false, true).then((response) => {
            this.payment.reserves = this.participants;
            this.loadingSendParticipants = false;
            return response;
        });
    }

    @action
    applyDiscount(params) {
        this.loadingDiscount = true;
        return Api.call('/discount/verify-discount', 'post', params).then((response) => {
            this.loadingDiscount = false;
            this.discount = response.data.data;
            this.reBuildInstallment();
            return response;
        });
    }

    @action
    getMaxInstallments(fkAdventure) {
        return Api.call('/payment/get-installments', 'get', { fkAdventure }).then((response) => {
            this.installments = response.data.data ?? this.installments;
            return response;
        })
    }

    getPaymentWithDiscount() {
        if (this.discount) {
            let price = RequestStore.getPackageQuantity();
            if (this.discount.value_type === PERCENTAGE) {
                price = (price - (parseFloat((price * this.discount.value / 100)).toFixed(2))) + parseFloat(this.getInterest());
            } else {
                price = parseFloat(price) - parseFloat(this.discount.value) + parseFloat(this.getInterest());
                if (price <= 0) {
                    price = 0;
                }
            }
            return parseFloat(price).toFixed(2);
        }

        return false;
    }

    @computed
    get price() {
        let price = parseFloat(RequestStore.sumedPrice);
        price += parseFloat(this.getInterest());
        return price.toFixed(2);
    }

    @computed
    get finalPrice() {
        if (this.discount) {
            return this.getPaymentWithDiscount();;
        } else {
            return this.price;
        }
    }

    @action
    removeDiscount() {
        this.discount = undefined;
        this.reBuildInstallment();
    }

    @action
    selectInstallment(selectedIndex) {
        let price = RequestStore.getPackageQuantity();
        if (this.discount) {
            price = (price - (price * this.discount.value / 100)).toFixed(2);
        }
        if (RequestStore.adventure.credit_card_enabled) {
            this.installments = this.installments.map( (i, idx) => {
                i.selected = (idx == selectedIndex);
                if (i.value === 0 || i.value === '0') {
                    const priceWithinterest = parseFloat(price).toFixed(2);
                    const priceInInstallment = parseFloat(priceWithinterest / i.times).toFixed(2);
                    i.name = i.times + "x R$" + priceInInstallment + " = R$" + priceWithinterest + "(sem juros)";
                } else {
                    const jurus = parseFloat(i.value * price / 100);
                    const priceWithinterest = parseFloat(parseFloat(price) + parseFloat(jurus)).toFixed(2);
                    const priceInInstallment = parseFloat(priceWithinterest / i.times).toFixed(2);
                    i.name = i.times + "x R$" + priceInInstallment + " = R$" + priceWithinterest;
                }
                return i;
            });
        }
        return 0;
    }

    @action
    restartValue() {
        let price = RequestStore.getPackageQuantity();
        if (this.discount) {
            price = (price - (price * this.discount.value / 100)).toFixed(2);
        }
    }

    @action
    reBuildInstallment() {
        let price = RequestStore.getPackageQuantity();
        if (this.discount) {
            if (this.discount.value_type === PERCENTAGE) {
                price = (price - (price * this.discount.value / 100)).toFixed(2);
            } else {
                price = (price - this.discount.value).toFixed(2);
            }
        }

        if (RequestStore.adventure) {
            const adventure = RequestStore.adventure;
            if (RequestStore.adventure != undefined) {

                switch (CheckoutStore.paymentMethod) {
                    case CREDIT_CARD:
                        Api.call('/payment/get-installments', 'get', { fkAdventure: adventure.pkAdventure })
                            .then(response => {
                                this.installments = response.data.data.map((i) => {
                                    const jurus = parseFloat(i.value * price / 100);
                                    const priceWithinterest = parseFloat(parseFloat(price) + parseFloat(jurus)).toFixed(2);
                                    const priceInInstallment = parseFloat(priceWithinterest / i.times).toFixed(2);
                                
                                    i.name = i.times + "x R$ " + priceInInstallment + " = R$ " + priceWithinterest;
                                
                                    if (i.value === 0) {
                                        i.name = i.name + " (sem juros)";
                                    }
                                
                                    return i;
                                });
                            return 0;
                        })
                        break;
                    case PIX:
                        const pagarme_fee_pix = RequestStore.adventure.company.pagarme_settings.pagarme_fee.pagarme_fee_pix;

                        const jurus = parseFloat(pagarme_fee_pix * price / 100);
                        price = parseFloat(parseFloat(price) + parseFloat(jurus)).toFixed(2);
                    default:
                        break;
                } {
                    
                }
            } 
        }
    }

    getSelectedInstallment() {
        if (RequestStore.adventure != undefined && RequestStore.adventure.credit_card_enabled) {
            const installment = this.installments.find((i) => !!i.selected);
            return installment.times;
        }
        return 0;
    }

    getSelectedInterest() {
        if (RequestStore.adventure != undefined && RequestStore.adventure.credit_card_enabled) {
            const installment = this.installments.find((i) => !!i.selected);
            return installment.value
        }
        return 0;
    }

    getMaxInstallmentPrice(price) {
        const installment = parseFloat(12.5) * parseFloat(price / 100)
        price = parseFloat((parseFloat(price) + installment) / 12).toFixed(2);
        return parseFloat(price).toFixed(2);
    }

    getInstallment(price, installment) {
        if (RequestStore.adventure != undefined && RequestStore.adventure.credit_card_enabled) {
            installment = this.installments.find((i) => i.times === installment);

            if (installment.value === 0)
                return 0

            return price * installment.value / 100
        }
        return 0;
    }

    getInterest() {
        let price = RequestStore.getPackageQuantity();

        if (this.discount) {
            if (this.discount.value_type === PERCENTAGE) {
                price = (price - (parseFloat((price * this.discount.value / 100)).toFixed(2)));
            } else {
                price = parseFloat(price) - parseFloat(this.discount.value);
                if (price <= 0) {
                    price = 0;
                }
            }
        }

        let interest = 0;
        if (RequestStore.adventure != undefined) {
            
            switch (CheckoutStore.paymentMethod) {
                case CREDIT_CARD:
                    const installment = this.installments.find((i) => i.selected == true);
                    if (installment == undefined || installment.value == undefined) {
                        interest = parseFloat(price);                        
                    } else {
                        if (installment.value > 0) {
                            interest = parseFloat(installment.value) * parseFloat(price / 100);
                        }
                    }
                    break;
                case PIX:
                    const pagarme_fee_pix = RequestStore.adventure.company.pagarme_settings.pagarme_fee.pagarme_fee_pix;
                    interest = parseFloat(pagarme_fee_pix * price / 100);
                default:
                    break;
            }
                    
                    
            return parseFloat(interest).toFixed(2);
        }
        return 0;
    }

    @action
    getUserHistoricAdventures(params) {
        this.loadingHistoric = true;

        return Api.call('/payment/get-user-historic', 'get', params).then((res) => {
            if (res && res.data) {
                this.historicAdventures = res.data.data.filter((d) => !!d.adventure && d.request && d.company);
            }
            this.loadingHistoric = false;
            return res;
        });
    }

    @action
    getUserFutureAdventures(params) {
        this.loadingFuture = true;

        return Api.call('/payment/get-user-future', 'get', params).then((res) => {
            if (res && res.data) {
                this.futureAdventures = res.data.data.filter((d) => !!d.adventure && d.request && d.company);
            }
            this.loadingFuture = false;
            return res;
        });
    }

}

export default new PaymentStore();
