/**
 * Created by matheusmorett on 23/08/17.
 */
import {observable, action} from 'mobx';
import Api from 'utils/Api';
import Utils from 'utils/Utils';

class CategoriesStore {

  @observable loading = false;
  @observable Categories = [];
  @observable HighlightedCategory = [];
  @observable Category;

  @action
  async getAll(params) {
    this.loading = true;
    this.Categories = [];
    return Api.call('/category/get-categories', 'get', params).then((response) => {
      this.loading = false;
      this.Categories = response ? response.data.data : null;
    });
  }

  @action
  getHighlighted() {
    return Api.call('/category/get-highlighted', 'get', {}).then((response) => {
      this.HighlightedCategory = response ? response.data.data : null;
    });
  }

  @action
  getByUrl(params, region = null) {
    return Api.call('/category/get-by-url', 'get', params).then((response) => {
      if(!response)
        return;

      const region_name = region ? `em ${region.name} e Região` : ""
      const category = response.data.data
      if (category.page_title.includes('{CIDADE}')) {
        category.page_title = Utils.injectCidade(region_name, category.page_title)
      }

      if (category.description.includes('{CIDADE}')) {
        category.description = Utils.injectCidade(region_name, category.description)
      }

      if (category.additional_description.includes('{CIDADE}')) {
        category.additional_description = Utils.injectCidade(region_name, category.additional_description)
      }

      this.Category = category

    })
  }

}

export default new CategoriesStore();